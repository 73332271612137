<p>REGULAMENTO <br>
    JUNTOS NO GÁS <br>
    PADRONIZAÇÃO PREMIADA<br>
    CAMPANHA DE INCENTIVO PARA REVENDAS</p>

<h2>1	PROMOTORA</h2>
<p>  COPA ENERGIA DISTRIBUIDORA DE GÁS S.A. <br>
    Endereço: Av. das Nações Unidas, 14.171, Torre C, 29º andar <br>
    São Paulo - SP <br>
    CEP: 04583-110 <br>
    CNPJ: 03.237.583/0001-67</p>
<h2>  2	MODALIDADE DA CAMPANHA</h2>
<p> MODALIDADE DA CAMPANHA <br>
    CAMPANHA DE INCENTIVO <br>
    PROMESSA DE RECOMPENSA A QUEM ATENDER À OFERTA, NA FORMA DESTE REGULAMENTO</p>
<h2>  3	ÁREA DE ABRANGÊNCIA </h2>
<p>  Território Nacional</p>
<h2>  4	PERÍODO DA CAMPANHA</h2>
<p>  27/03/2023 a 15/11/2023</p>
<h2>  5	CRITÉRIOS DE PARTICIPAÇÃO</h2>
<p>  5.1	Podem participar desta Campanha de Incentivo, todos os Revendedores Liquigás ou Copagaz - REVENDAS, em todo o Território Nacional desde que se enquadrem nos critérios aqui especificados.</p>
<p>  5.1.1	Esta Campanha de Incentivo visa realizar o DEBRANDING (retirada da antiga identidade visual da marca Liquigás), retirando todo e qualquer item que remeta à antiga marca Liquigás como: BR Petrobras, elemento Petrobras (listras), Sol, versão em caixa alta, cores ou qualquer outro que não esteja de acordo com o novo manual de padronização da Liquigás e padronizar NOVAS REVENDAS Liquigás e Copagaz com a identidade visual das marcas, em suas fachadas e em suas frotas de veículos de entrega de gás, sendo que suas diretrizes e orientações serão estabelecidas através deste Regulamento.</p>
<p>  5.1.1	Esta Campanha de Incentivo visa realizar o DEBRANDING (retirada da antiga identidade visual da marca Liquigás), retirando todo e qualquer item que remeta à antiga marca Liquigás como: BR Petrobras, elemento Petrobras (listras), Sol, versão em caixa alta, cores ou qualquer outro que não esteja de acordo com o novo manual de padronização da Liquigás e padronizar NOVAS REVENDAS Liquigás e Copagaz com a identidade visual das marcas, em suas fachadas e em suas frotas de veículos de entrega de gás, sendo que suas diretrizes e orientações serão estabelecidas através deste Regulamento.</p>
<p>  5.1.2	A seguir, seguem imagens exemplificativas de marcas e elementos antigos que não devem mais ser utilizados, referidos no item acima: </p>
<p><img src="assets/images/regulation-liquigas.png" alt="liquigas" /></p>
<p>  5.1.3	Para efeito desta Campanha, poderão participar as REVENDAS que foram aprovadas na última Campanha de Incentivo de padronização realizada pela PROMOTORA, denominada “Padroniza e Vem pra Copa”, realizada em 2022, desde que ainda possuam a sua frota, nos termos deste Regulamento.</p>
<p>  5.1.4	Caso a Revenda não possua frota e já tenha sido aprovada na última Campanha de Incentivo realizada pela PROMOTORA, denominada “Padroniza e vem pra Copa”, esta não poderá participar da presente Campanha.</p>
<p>  5.1.5	Poderão participar todas as seguinte REVENDAS:</p>
<p> -	DEBRANDING: Revendas ativas que compram diretamente da Copa Energia ou sub-rede de uma revenda ativa que foram cadastradas como vinculadas a à marca Liquigás até o dia 31/11/2022.   </p>
<p> -	NOVA REVENDAS: Revendas que se vincularam à marca Liquigás ou Copagaz a partir do dia 01/12/2022.</p>
<p>  5.1.6   As REVENDAS DEBRANDING e NOVAS REVENDAS participantes desta Campanha serão divididas por classes, no total de 08 (oito), conforme classe que consta na ANP (Agência Nacional do Petróleo, Gás Natural e Biocombustíveis), sendo considerada a de maior capacidade caso tenha mais de uma área cadastrada em um único CNPJ. Ou seja, cada revenda participa por CNPJ em uma das seguintes categorias: Classe I, Classe II, Classe III, Classe IV, Classe V, Classe VI, Classe VII e Classe Especial.: </p>
<h2>  6       DA PARTICIPAÇÃO DAS REVENDAS NA CAMPANHA</h2>
<p>  6.1      As REVENDAS DEBRANDING interessadas em participar deverão no período compreendido entre a 00h00m do dia 24.03.2023 até as 23h59m do dia 31.07.2023, horário de Brasília, inscrever-se nesta Campanha de Incentivo e aceitar o regulamento, através do site www.juntosnogas.com.br, informando o CNPJ e Senha que será enviada através das comunicações oficiais da campanha e realizar a comprovação até esta data para participar das premiações.</p>
<p>  6.2      Para as REVENDAS NOVOS NEGÓCIOS, interessadas em participar, o período de inscrição na será  no período entre a 00h00m do dia 27.03.2023 até as 23h59m do dia 15.11.2023, horário de Brasília, inscrever-se nesta Campanha de Incentivo e aceitar o regulamento, através do site www.juntosnogas.com.br, informando o CNPJ e Senha que será enviada através das comunicações oficiais da campanha.</p>
<p>  6.3      Após preencher o CNPJ e senha, o sistema trará automaticamente os dados de razão social e endereço da revenda. A revenda deve conferir e atualizar suas informações de cadastro como também informar o nome do responsável, número do telefone, celular, e-mail e cargo. Para concluir o cadastro, a revenda participante deverá aceitar os termos do regulamento e da política de privacidade, caso não aceite 1 (um) ou os 2 (dois) termos, não será possível concluir o cadastro.</p>
<p>  6.4      Qualquer REVENDA que solicite o cancelamento de seu cadastro, no decorrer da Campanha, será desta excluída.</p>
<p>  6.4.1    Demais REVENDAS poderão ser designadas como participantes pela PROMOTORA, a seu critério.</p>
<p>  6.4.2   O revendedor participante que deixar de ser uma  REVENDA  (<strong>virar a bandeira</strong>) ou se  tornar independente, na vigência da Campanha, será automaticamente excluído.</p>
<p>  6.4.3  REVENDAS poderão ser excluídas a critério da PROMOTORA. </p>
<p>6.5      Fica  desde já estabelecido que a PROMOTORA e seus fornecedores envolvidos tem o  compromisso de proteger os dados informados pelos participantes, mantendo a  confidencialidade sobre tais informações, garantindo que, excetuados os casos  previstos em lei e ao fiel cumprimento da execução desta Campanha, não serão  compartilhados com terceiros que não estejam envolvidos no projeto, sendo os  dados tratados na forma que prescreve a Lei 13.709/2018 – Lei Geral de Proteção  de Dados Pessoais.</p>
<p>  6.6      Os  participantes poderão solicitar a exclusão definitiva de seus dados, ficando  cientes de que, nesta hipótese, automaticamente deixam de participar da  presente Campanha.</p>
<p>  6.7      Cada  REVENDA poderá ser cadastrada uma única vez para participar da presente  Campanha.</p>
<p>  6.8      Na  hipótese da ocorrência de verificação e/ou comprovação de fraude e/ou tentativa  de burlar as disposições do presente Regulamento e suas regras, qualquer  REVENDA Participante perderá o direito de participação na Campanha, sem  prejuízo de ser responsabilizado civil e criminalmente conforme previsto em  legislação aplicável.</p>
<p>  6.9      REVENDAS  localizadas em Postos de Combustível e Supermercados, serão também padronizadas  retirando a marca antiga da Liquigás podendo fazer o upload de suas fotos no  site. Porém, aquelas que não possuem frota ou possuem Frota de veículos  descaracterizada, não participam da premiação referente a Frota.</p>
<p>  6.10    Ao se inscrever na presente Campanha, a REVENDA  autoriza a utilização de seus dados pessoais pela PROMOTORA, para fins de realização da presente Ação de  Incentivo. </p>
<h2> 7          DA  PARTICIPAÇÃO DAS REVENDAS DEBRANDING:</h2>
<p>  7.1      Na  parceria proposta nesta campanha, a Copa Energia realizará a padronização da  fachada por meio de um fornecedor terceiro contratado e a Revenda fará a  padronização da frota, tendo uma compensação em premiação para aquelas que comprovarem  sua realização, além de concorrerem a um sorteio final.</p>
<p>  7.2      As  REVENDAS deverão efetuar a comprovação da padronização de suas fachadas, na  forma que se segue:</p>
<p>  7.2.1   As  REVENDAS participantes, após se cadastrarem nesta Campanha conforme o item 6.1  deste Regulamento, deverão enviar até 04 (quatro) fotos da fachada da REVENDA,  sendo que o <em>upload</em> destas fotos deve  ser feito até o dia 01.05.2023 no site da campanha. As fotos deverão contemplar  todos os itens da fachada da revenda que contenham a antiga marca Liquigás, para  que possamos avaliar a necessidade de a Copa Energia realizar sua retirada. </p>
<p>  7.2.2   Feito  o <em>upload</em> das fotos das FACHADAS, nos termos dos itens acima, a PROMOTORA  fará a análise e moderação das fotos. Essa moderação analisará se nas fotos  enviadas aparecem todos os itens, como muro externo, portões, gradil, placa de  fachada, placas ANP, totens (se houver) e outros que contenham a marca antiga Liquigás  vinculada à Petrobras, caso esta verifique que a revenda necessita de  padronização, serão enviados prestadores de serviço ao estabelecimento,  contratados pela PROMOTORA, que realizarão o serviço de padronização, seguindo  o padrão do Manual de Padronização<strong>.</strong>   Estas imagens servirão para orientar o prestador de serviço dimensionar  o que será preciso realizar na nova padronização.</p>
<p>  7.2.3   O  serviço de padronização da FACHADA será previamente agendado entre o prestador  de serviço da PROMOTORA e a REVENDA Participante.   </p>
<p>  7.2.4   Os  materiais a serem aplicados na revenda serão os itens básicos do padrão do  manual, ou seja: </p>
<ul>
    <li>3  placas obrigatórias ANP</li>
    <li>Testeira  nos tamanhos padrões (em  PVC adesivado  nos tamanhos 1500 X 500 MM, 2100 X 700 MM, 2800 X 1000 MM e 3600 X 1100 MM) sem  personalização de telefone, aplicados conforme a melhor disposição da marca na  revenda.</li>
    <li>Pintura da fachada aparente da visão externa.</li>
</ul>
<p>7.2.5   Itens  específicos com a aplicação da marca antiga na fachada e não listado acima como  totens, frontlight, outdoor e outros serão tratados como exceções, sendo a  revenda notificada por e-mail do e-mail cadastrado em nosso banco de dados e  e-mail da campanha. Caso a revenda não responda em até 3 dias úteis, findo este  prazo, o projeto será considerado aprovado e não serão aceitas contestações.</p>
<p>  7.2.6   Esclarecemos  que a padronização de fachada será de responsabilidade da PROMOTORA (material e  mão de obra), sendo que a comprovação da padronização será realizada pela  PROMOTORA, sem custos para a revenda. Antes de iniciar a padronização, a  empresa contratada solicitará a assinatura de termo de autorização e ciência da  proibição do uso da marca antiga Liquigás pelo responsável da revenda, conforme  consta no cadastro da ANP (<a href="https://cpl.anp.gov.br/anp-cpl-web/public/simp/consulta-revendas/consulta.xhtml?dswid=2744">https://cpl.anp.gov.br/anp-cpl-web/public/simp/consulta-revendas/consulta.xhtml?dswid=2744</a>) ou  apresentando outro documento comprobatório. A revenda deverá permitir que a  empresa realize a padronização no prazo necessário, agendando uma das datas  dentro da janela proposta para a realização do serviço na região.</p>
<p>  7.2.7   Esclarecemos,  também, que itens da padronização, que necessitem de  manutenção (consertos na estrutura, nivelação,  rachaduras ou outros reparos),  não serão  realizados pela promotora, apenas a padronização de itens que ainda não o foram  conforme escopo descrito no item 7.2.5.</p>
<p>  7.3      Ressaltamos  que, enquanto a fachada será realizada pela Copa Energia, a Padronização da  Frota de Veículos da REVENDA será realizada pela REVENDA e será comprovada da  seguinte forma:</p>
<p>  7.3.1   Devem  ser anexadas, também,  todas as fotos da  frota de veículos da REVENDA participante, sendo que o <em>upload</em> destas  fotos deve ser feito até o dia 31.07.2023. </p>
<p>  7.3.2   Ao  término do serviço de padronização da Frota de Veículos, retirando a antiga  marca antiga da Liquigás de toda a frota, a REVENDA participante deverá  comprovar a padronização nos seguintes termos, enviando fotografias do veículo  considerando:</p>
<ul>
    <li>Motos e triciclos: duas laterais e placa do  veículo visível conforme o padrão do Manual de Padronização. </li>
    <li>Demais veículos leves (que atendem ao  consumidor final, como VUC, pick-ups e afins): frente e traseira com placa do  veículo visível e suas duas laterais.  </li>
    <li>Veículos pesados (caminhões e afins que fazem  transporte do produto entre revenda e distribuidora, sem atender ao consumidor  final): em acordo com o Manual de Padronização ou descaracterização / retirada  da antiga marca Liquigás.</li>
    <li>Veículos com marca própria da revenda só  serão aceitos se estiverem de acordo com o Manual de Padronização. </li>
</ul>
<p>7.3.3   A  REVENDA deverá, ainda, enviar o termo de ciência e responsabilidade e não uso  da marca antiga Liquigás (disponível no site da campanha) assinado por um  representante legal da empresa conforme consta no cadastro da ANP (<a href="https://cpl.anp.gov.br/anp-cpl-web/public/simp/consulta-revendas/consulta.xhtml?dswid=2744">https://cpl.anp.gov.br/anp-cpl-web/public/simp/consulta-revendas/consulta.xhtml?dswid=2744</a>) ou  apresentando outro documento comprobatório.</p>
<p>  7.3.4   Caso a padronização de 100% da frota seja comprovada até 30 de junho de 2023, a REVENDA Participante receberá ímãs e uniformes, conforme sua classe ANP, além de 06 (seis) cupons para participar do sorteio do item 8.1.5 deste Regulamento.</p>
<p>  7.3.5    Caso a  padronização de 100% da frota seja comprovada até 31 de julho de 2023, a  REVENDA  participante receberá ímãs e  uniformes, conforme sua classe ANP, além de 03 (três) cupons para participar do  sorteio do item 8.1.5 deste Regulamento.</p>
<p>  7.3.6   As revendas q          ue  comprovarem sua padronização de frota 100% na cor verde terão 50% a mais dos  prêmios (ímãs e uniformes) e o dobro de cupons para concorrer aos sorteios.</p>
<h2>  8          DAS RECOMPENSAS DAS REVENDAS DEBRADING</h2>
<p>  8.1      Todas as REVENDAS DEBRANDING  que comprovarem  100% de sua Frota de veículos, sem a antiga marca Liquigás e com a nova  padronização em acordo com o manual e termo assinado até 31.07.2023, receberão  de acordo com sua Classe ANP, as seguintes recompensas:</p>
<div class="table-responsive">
    <table border="0" cellspacing="0" cellpadding="0">
    <tr>
        <td></td>
        <td></td>
        <td></td>
        <td colspan="3"><p align="center">TAM UNIFORME</p></td>
    </tr>
    <tr>
        <td><p align="center">Classe ANP</p></td>
        <td><p align="center">Quant Ímãs</p></td>
        <td><p align="center">Quant Kit Uniformes</p></td>
        <td><p align="center">GG</p></td>
        <td><p align="center">G</p></td>
        <td><p align="center">M</p></td>
    </tr>
    <tr>
        <td><p align="center">Especial</p></td>
        <td><p align="center">12.000 </p></td>
        <td><p align="center">30 </p></td>
        <td><p align="center">4 </p></td>
        <td><p align="center">13 </p></td>
        <td><p align="center">13 </p></td>
    </tr>
    <tr>
        <td><p align="center">Classe VII</p></td>
        <td><p align="center">10.000 </p></td>
        <td><p align="center">20 </p></td>
        <td><p align="center">2 </p></td>
        <td><p align="center">9 </p></td>
        <td><p align="center">9 </p></td>
    </tr>
    <tr>
        <td><p align="center">Classe VI</p></td>
        <td><p align="center">10.000 </p></td>
        <td><p align="center">20 </p></td>
        <td><p align="center">2 </p></td>
        <td><p align="center">9 </p></td>
        <td><p align="center">9 </p></td>
    </tr>
    <tr>
        <td><p align="center">Classe V</p></td>
        <td><p align="center">8.000 </p></td>
        <td><p align="center">16 </p></td>
        <td><p align="center">2 </p></td>
        <td><p align="center">7 </p></td>
        <td><p align="center">7 </p></td>
    </tr>
    <tr>
        <td><p align="center">Classe IV</p></td>
        <td><p align="center">5.000 </p></td>
        <td><p align="center">10 </p></td>
        <td><p align="center">2 </p></td>
        <td><p align="center">4 </p></td>
        <td><p align="center">4 </p></td>
    </tr>
    <tr>
        <td><p align="center">Classe III</p></td>
        <td><p align="center">4.000 </p></td>
        <td><p align="center">6 </p></td>
        <td><p align="center">1 </p></td>
        <td><p align="center">3 </p></td>
        <td><p align="center">2 </p></td>
    </tr>
    <tr>
        <td><p align="center">Classe II</p></td>
        <td><p align="center">1.000 </p></td>
        <td><p align="center">2 </p></td>
        <td><p align="center">0 </p></td>
        <td><p align="center">1 </p></td>
        <td><p align="center">1 </p></td>
    </tr>
    <tr>
        <td><p align="center">Classe I</p></td>
        <td><p align="center">500 </p></td>
        <td><p align="center">2 </p></td>
        <td><p align="center">0 </p></td>
        <td><p align="center">1 </p></td>
        <td><p align="center">1 </p></td>
    </tr>
</table>
</div>
<p>  8.1.1   Com relação à personalização dos itens acima descritos, esclarecemos que o uniforme não conterá informações da revenda. Para os ímãs, o fornecedor contratado pela PROMOTORA entrará em contato com os ganhadores, para confirmação dos dados de telefone da revenda, e caso não tenha sucesso, os ímãs irão sem personalização, ou seja, somente com logo da Liquigás e telefone 0800 da Central Liquigás.</p>
<p>  8.1.2   Os kits de uniformes são compostos por boné, camiseta de manga curta e calça da marca.</p>
<p>  8.1.3   Com relação à comprovação de 100% de padronização da Frota de veículos das REVENDAS, serão realizadas dois tipos de avaliação de frota:</p>
<p>  I	Frota Leve que atende ao consumidor final (carro, pick-up, triciclo, moto e VUC): Deve estar totalmente padronizada conforme manual de padronização.</p>
<p>  II	Frota Pesada para a transferência de produto (Caminhão e carretas): Deve estar padronizada ou descaracterizada do logo e endosso antigos.</p>
<p>  8.1.4   As recompensas descritas acima serão entregues diretamente no endereço cadastrado no CNPJ da revenda por meio de operador logístico. Para logística das premiações  às sub-redes, serão considerados os endereços dos próprios CNPJs, ou seja não será enviado material da sub-rede na revenda que faz seu abastecimento.  </p>
<p>  8.1.5   As REVENDAS participantes que atenderam aos itens 7.3.4 e 7.3.5 deste Regulamento, além de receber a premiação acima, concorrerão aos prêmios abaixo, através de sorteio, correspondentes à Classe ANP para a qual foram designados, sendo certo que As revendas que comprovarem sua padronização de frota 100% na cor verde receberão o dobro de cupons para concorrer aos sorteios. </p>
<div class="table-responsive">
    <table border="0" cellspacing="0" cellpadding="0">
    <tr>
        <td><p align="center">CLASSE ANP</p></td>
        <td><p align="center">Nº de Revendas Sorteadas</p></td>
        <td><p align="center">Valor (R$):</p></td>
    </tr>
    <tr>
        <td><p align="center">Classe Especial</p></td>
        <td><p align="center">5</p></td>
        <td><p align="right">24.000,00</p></td>
    </tr>
    <tr>
        <td><p align="center">Classe VII</p></td>
        <td><p align="center">5</p></td>
        <td><p align="right">18.000,00</p></td>
    </tr>
    <tr>
        <td><p align="center">Classe VI</p></td>
        <td><p align="center">5</p></td>
        <td><p align="right">18.000,00</p></td>
    </tr>
    <tr>
        <td><p align="center">Classe V</p></td>
        <td><p align="center">20</p></td>
        <td><p align="right">12.000,00</p></td>
    </tr>
    <tr>
        <td><p align="center">Classe IV</p></td>
        <td><p align="center">20</p></td>
        <td><p align="right">8.000,00</p></td>
    </tr>
    <tr>
        <td><p align="center">Classe III</p></td>
        <td><p align="center">80</p></td>
        <td><p align="right">4.000,00</p></td>
    </tr>
    <tr>
        <td><p align="center">Classe II</p></td>
        <td><p align="center">80</p></td>
        <td><p align="right">2.000,00</p></td>
    </tr>
    <tr>
        <td><p align="center">Classe I</p></td>
        <td><p align="center">80</p></td>
        <td><p align="right">2.000,00</p></td>
    </tr>
</table>
</div>
<p>  8.2     Os prêmios acima serão entregues por meio de Cartão Presente.</p>
<p>  8.3     O Sorteio a que se refere o item 8.1.5 será realizado em  agosto de 2023, em dia e horário previamente definido e comunicado pela promotora , através de sorteador online.</p>
<p>  8.4     Na realização do sorteio, primeiramente será  apurada 01 (uma) revenda premiada e 10 (dez) revendas suplentes. Caso à revenda sorteada não cumpra os requisitos, o prêmio passará para o próximo sorteado suplente e assim por diante. </p>
<p>  8.5     Os prêmios serão entregues no endereço da revenda informado no cadastro junto à PROMOTORA.</p>
<p>  8.7     Revenda que padronizou por conta, mesmo que sem frota, desde que não tenha participado da campanha Padroniza e Vem pra Copa, também faz jus à premiação do bateu levou. Porém, não participa do sorteio, exclusivo à revendas que comprovarem a frota.</p>
<div class="table-responsive">
    <table border="0" cellspacing="0" cellpadding="0" class="table">
        <tr>
            <td></td>
            <td>
                BATEU-LEVOU FROTA<br>
                Comprovou padronização da frota no site da campanha, ganhou ímãs e uniformes<br>
                Até 31.07.2023
            </td>
            <td colspan="2">
                SORTEIO (AGO/23)<br>
                100% da frota padronizada no site da campanha. Em caso de padronização na cor verde, cupons em dobro
                <p class="text-center">
                    Até 30.06.2023 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Até 31.07.2023
                </p>
            </td>
        </tr>
        <tr>
            <td>Padrão Liquigás</td>
            <td rowspan="2">Ganha Imã e Uniforme, conforme a classe ANP</td>
            <td>6 cupons (padronização da frota em verde – 12 cupons)</td>
            <td>3 cupons (padronização da frota em verde – 06 cupons)</td>
        </tr>
        <tr>
            <td>Revenda sem frota: padronizou por conta</td>
            <td colspan="2">Não participam</td>
        </tr>
        <tr>
            <td>Revenda sem frota: padronização e aprovada na campanha passada</td>
            <td>Não participam</td>
            <td colspan="2">Não participam</td>
        </tr>
        <tr>
            <td>Postos e Supermercados</td>
            <td>Não participam</td>
            <td colspan="2">Não participam</td>
        </tr>
        <tr>
            <td>Frota descaracterizada</td>
            <td>Não participam</td>
            <td colspan="2">Não participam</td>
        </tr>
    </table>
</div>
<p>  8.7.1     REVENDAS que não possuem frota, mas realizaram a padronização por conta própria, ganham ímãs e uniformes.</p>
<p>  8.7.2     REVENDAS que não possuem frota e realizaram a padronização na campanha Padroniza e vem para a Copa, não ganham ímãs e uniformes e não concorrem a sorteios.</p>
<h2> 9          DA  PARTICIPAÇÃO DAS REVENDAS NOVOS NEGÓCIOS:</h2>
<p>  9.1     As REVENDAS deverão efetuar a comprovação da padronização de suas fachadas, na forma que se segue:</p>
<p>  9.1.1   As REVENDAS NOVOS NEGÓCIOS participantes, após se cadastrarem nesta Campanha conforme o item 6.2 deste Regulamento, deverão enviar até 04 (quatro) fotos da fachada da REVENDA,  sendo que o upload destas fotos deve ser feito até o dia 15.11.2023. no site da campanha. As fotos deverão contemplar todos os itens da fachada da revenda que contenham a antiga marca Liquigás, para que possamos avaliar a necessidade de a Copa Energia realizar sua retirada.  </p>
<p>  9.1.2   Feito  o <em>upload</em> das fotos das FACHADAS, nos termos dos itens acima, a PROMOTORA  fará a análise e moderação das fotos. Essa moderação analisará se nas fotos  enviadas aparecem todos os itens, como muro externo, portões, gradil, testeira de  fachada, placas ANP, totens (se houver), caso esta verifique que a revenda  necessita de padronização, serão enviados prestadores de serviço ao  estabelecimento, contratados pela PROMOTORA, que realizarão o serviço de  padronização, seguindo o padrão do Manual de Padronização<strong>.</strong>  Estas imagens servirão para orientar o  prestador de serviço dimensionar o que será preciso realizar a nova  padronização.</p>
<p>  9.1.3   O serviço de  padronização será previamente agendado entre a PROMOTORA e a REVENDA  Participante. </p>
<p>  9.1.4   Os  materiais a serem aplicados na revenda serão os itens básicos do padrão do  manual, ou seja: </p>
<ul>
    <li>3 placas obrigatórias ANP</li>
    <li>Testeira nos tamanhos padrões (em  PVC adesivado nos tamanhos 1500 X 500 MM,  2100 X 700 MM, 2800 X 1000 MM e 3600 X 1100 MM) sem personalização de telefone,  aplicados conforme a melhor disposição da marca na revenda.</li>
    <li>Pintura da fachada aparente da visão externa.</li>
</ul>
<p>9.1.5   Itens  específicos com a aplicação da marca antiga na fachada e não listado acima como  totens, frontlight, outdoor e outros serão tratados como exceções, sendo a  revenda notificada por e-mail do e-mail cadastrado em nosso banco de dados e  e-mail da campanha. Caso a revenda não responda em até 3 dias úteis, findo este  prazo, o projeto será considerado aprovado e não serão aceitas contestações.</p>
<p>  9.1.6   Esclarecemos  que a padronização de fachada será de responsabilidade da PROMOTORA (material e  mão de obra), sendo que a comprovação da padronização será realizada pela  PROMOTORA e não será necessária qualquer outra ação da REVENDA. A revenda  deverá de permitir que a empresa realize a padronização no prazo necessário, agendando  uma das datas dentro da janela proposta para a realização do serviço na região.</p>
<p>  9.1.7   Esclarecemos,  também, que itens da padronização, que necessitem de  manutenção (consertos na estrutura,  nivelação, rachaduras ou outros reparos),   não serão realizados pela promotora, apenas a padronização de itens que  ainda não o foram conforme escopo descrito no item 9.1.4.</p>
<p>  9.2      Ressaltamos que, enquanto  a fachada será realizada pela Copa Energia, a Padronização da Frota de Veículos  da REVENDA será realizada pela REVENDA e será comprovada da seguinte forma:</p>
<p>  9.2.1   Devem  ser anexadas, também,  todas as fotos da  frota de veículos da REVENDA participante, sendo que o <em>upload</em> destas  fotos deve ser feito até o dia 15.11.2023. </p>
<p>  9.2.2   Ao  término do serviço de padronização da Frota de Veículos, a REVENDA participante  deverá comprovar a padronização nos seguintes termos, enviando fotografias do  veículo considerando:</p>
<ul>
    <li>Motos e triciclos: duas laterais e placa do  veículo visível conforme o padrão do Manual de Padronização. </li>
    <li>Demais veículos leves (que atendem ao  consumidor final, como VUC, pick-ups e afins): frente e traseira com placa do  veículo visível e suas duas laterais.  </li>
    <li>Veículos pesados (caminhões e afins que fazem  transporte do produto entre revenda e distribuidora, sem atender ao consumidor  final): em acordo com o Manual de Padronização ou descaracterização / retirada  da antiga marca Liquigás.</li>
    <li>Veículos com marca própria da revenda só  serão aceitos se estiverem de acordo com o Manual de Padronização. </li>
</ul>
<p>  9.3      A  REVENDA deverá, ainda, enviar o termo de ciência e responsabilidade e não uso  da marca antiga Liquigás (disponível no site da campanha) assinado por um  representante legal da empresa conforme consta no cadastro da ANP (<a href="https://cpl.anp.gov.br/anp-cpl-web/public/simp/consulta-revendas/consulta.xhtml?dswid=2744">https://cpl.anp.gov.br/anp-cpl-web/public/simp/consulta-revendas/consulta.xhtml?dswid=2744</a>) ou  apresentando outro documento comprobatório.</p>
<p>  9.4      Devem  ser anexadas, também, as fotos da frota de veículos da REVENDA Participante,  sendo que o <em>upload</em> destas fotos deve ser feito até o dia 15.11.2023. </p>
<p>  9.4.1   Fica  desde já esclarecido, também,  que os  custos da Padronização da Frota de Veículos serão arcados pela REVENDA.</p>
<h2>  10       DAS RECOMPENSAS DAS REVENDAS NOVOS  NEGÓCIOS:</h2>
<p>  10.1    Todas as REVENDAS NOVOS NEGÓCIOS que aderiram as marcas  Liquigás ou Copagaz a partir do dia 01/12/2022 que comprovarem 100% de sua  Frota de veículos padronizadas com as marcas Liquigás ou Copagaz, até 15.11.2023  receberão, de acordo com sua Classe ANP, os seguintes brindes: </p>
<div class="table-responsive">
    <table border="1" cellspacing="0" cellpadding="0">
    <tr>
        <td><p align="center">CLASSE ANP</p></td>
        <td><p align="center">ÍMAS PERSONALIZADOS</p></td>
        <td><p align="center">KIT DE UNIFORMES</p></td>
    </tr>
    <tr>
        <td><p align="center">Classe Especial</p></td>
        <td><p align="center">5.000</p></td>
        <td><p align="center">12</p></td>
    </tr>
    <tr>
        <td><p align="center">Classe VII</p></td>
        <td><p align="center">5.000</p></td>
        <td><p align="center">12</p></td>
    </tr>
    <tr>
        <td><p align="center">Classe VI</p></td>
        <td><p align="center">5.000</p></td>
        <td><p align="center">10</p></td>
    </tr>
    <tr>
        <td><p align="center">Classe V</p></td>
        <td><p align="center">3.000</p></td>
        <td><p align="center">8</p></td>
    </tr>
    <tr>
        <td><p align="center">Classe IV</p></td>
        <td><p align="center">3.000</p></td>
        <td><p align="center">8</p></td>
    </tr>
    <tr>
        <td><p align="center">Classe III</p></td>
        <td><p align="center">3.000</p></td>
        <td><p align="center">6</p></td>
    </tr>
    <tr>
        <td><p align="center">Classe II</p></td>
        <td><p align="center">1.000</p></td>
        <td><p align="center">4</p></td>
    </tr>
    <tr>
        <td><p align="center">Classe I</p></td>
        <td><p align="center">1.000</p></td>
        <td><p align="center">2</p></td>
    </tr>
</table>
</div>
<p>10.1.1 Com relação à  comprovação de 100% de padronização da Frota de veículos das  REVENDAS, serão realizadas dois tipos de avaliação de frota:</p>
<p>  I    Frota Leve que atende ao consumidor final  (carro, pick-up, triciclo, moto e VUC): Deve estar totalmente padronizada  conforme manual de padronização.</p>
<p>  II   Frota Pesada para a transferência de produto  (Caminhão e carretas): Deve estar padronizada ou descaracterizada do logo e  endosso antigos.<br>
    10.1.2 A  REVENDA deverá, ainda, enviar o termo de ciência e responsabilidade e não uso  da marca antiga aplicada em sua frota (disponível no site da campanha) assinado  por um representante legal da empresa.</p>
<p>  10.1.3 Os kits de  uniformes são compostos por boné, camisa de manga curta e calça da marca, sem  nome fantasia da REVENDA.</p>
<p>  10.1.4 As  recompensas descritas acima serão entregues por meio de operador logístico. E com  relação à personalização dos itens acima descritos, esclarecemos que o uniforme  não conterá informações da revenda. Para os ímãs, o fornecedor contratado pela  PROMOTORA entrará em contato com os ganhadores, para confirmação dos dados de  telefone da revenda, e caso não tenha sucesso, os ímãs irão sem personalização,  ou seja, somente com logo da Liquigás e telefone 0800 da Central Liquigás.</p>
<h2>  11       CRITÉRIOS DE DESCLASSIFICAÇÃO</h2>
<p>  11.1    Estarão  imediatamente desclassificados as REVENDAS participantes que fornecerem, no  momento da inscrição nesta Campanha, dados falsos, inválidos, imprecisos ou  incorretos ou em caso de fraude comprovada através da obtenção de  benefício/vantagem de forma ilícita ou pelo não cumprimento de quaisquer das  condições deste Regulamento.</p>
<p>  11.2   Se constatada, ao final da campanha, após a apuração dos  vencedores, a não vinculação da REVENDA participante à marca na ANP, a REVENDA participante  será automaticamente excluída da Campanha e não poderá receber os prêmios.</p>
<h2>12       DISPOSIÇÕES GERAIS</h2>
<p>  12.1    Os  contemplados no sorteio do item 8.1. terão o prazo de até 180 (cento e oitenta)  dias corridos, após a divulgação do resultado, para requerer seu prêmio bem  como para reportar eventuais problemas no recebimento e/ou sua utilização. Após  esse prazo a PROMOTORA se isentará de qualquer responsabilidade pelo  recebimento e utilização prêmio.</p>
<p>  12.2    A  PROMOTORA e seus Parceiros prezam pela privacidade e proteção de dados das  Pessoas Naturais contratantes dos serviços ligados à presente Campanha– que são  titulares de dados maiores de 18 (dezoito) anos de idade, e utiliza os dados  pessoais coletados somente para fins relacionados à Campanha. Ao participar da  Campanha, e realizar o cadastro com login e senha, o participante expressamente  concorda com essa operação para os devidos fins.</p>
<p>  12.3    A  Campanha será divulgada por meio do hotsite <a href="http://www.juntosnogas.com.br">www.juntosnogas.com.br</a>, além de outras mídias itinerantes (redes  sociais, e-mail etc.). </p>
<p>  12.4    O  regulamento completo estará disponível no hotsite <a href="http://www.juntosnogas.com.br">www.juntosnogas.com.br</a>. </p>
<p>  12.5    As  dúvidas dos participantes sobre a presente Campanha poderão ser esclarecidas  através do e-mail <a href="mailto:sac@juntosnogas.com.br">sac@juntosnogas.com.br</a>, ou WhatsApp  (11) 91782-4900.</p>
<p>  12.6    As dúvidas e controvérsias oriundas de  reclamações dos Participantes desta Campanha deverão ser, primeiramente,  dirimidas pela PROMOTORA da Campanha.</p>
<p>  12.7    A  PROMOTORA não será responsável por problemas técnicos que impeçam, retardem ou  prejudiquem o envio ou recebimento das informações enviadas para o mencionado  endereço eletrônico. </p>
<p>  12.8    Em  caso de perda de conexão à rede de Internet, no momento da realização do cadastro  ou no envio das informações, não será devida qualquer indenização por parte da PROMOTORA,  tendo o participante que aceitar a implicação da eventual falha. Neste caso ele  poderá realizar novas tentativas até que a sua inscrição seja efetuada.</p>
<p>  12.9    Havendo  interrupção da Campanha ou na publicação do hotsite promocional por problemas  de acesso à rede de Internet, intervenção de hackers, vírus, manutenção, queda  de energia, falha de software ou hardware, bem como por caso fortuito ou força  maior, não será devida qualquer indenização, devendo a Empresa PROMOTORA,  entretanto, dar prosseguimento aos serviços tão logo haja a regularização do  sistema, nos moldes originalmente propostos, ou seja, sem prorrogação de datas. </p>
<p>  12.10  Os  contemplados no sorteio autorizam a empresa Liquigás a utilizar o seu nome,  imagem e som de voz, gratuitamente, em caráter irrevogável, irretratável e  universal, pelo prazo de 5 (cinco) anos, contados da data de encerramento da campanha,  para divulgação da presente ação, em todos os meios de comunicação, incluindo,  mas sem se limitar a: televisão, jornal, revista, mídia exterior, internet,  rede sociais, entre outros, não significando, implicando ou resultando em  qualquer obrigação de divulgação nem de pagamento, concordando ainda, inclusive,  em assinar eventuais recibos e instrumentos neste sentido e para tal efeito,  sempre que solicitado pela Realizadora. </p>
<p>  12.11  As  autorizações descritas acima não implicam em qualquer obrigação de divulgação  ou de pagamento de qualquer quantia por parte da PROMOTORA ao ganhador. </p>
<p>  12.12  A simples  participação na presente Campanha implicará no total e integral reconhecimento  das condições e aceitação irrestrita deste Regulamento, bem como, presumir-se-á  a condição de que o Participante não possui impedimentos fiscal, legal ou outro  que o impeça de receber e/ou usufruir o prêmio ganho. </p>
<p>  12.13  A PROMOTORA  poderá a qualquer momento, alterar as condições e cláusulas descritas neste  Regulamento, mediante prévia comunicação aos participantes.</p>
<p>  12.14  A PROMOTORA não  será responsável por problemas técnicos que impeçam, retardem ou prejudiquem o  envio ou recebimento das informações enviadas para participação na Campanha. </p>
<p>  12.15  Esta Campanha  é realizada como um Ato de Vontade da PROMOTORA, na forma que dispõe os Artigos  854 e seguintes do Código Civil em vigor, e objetiva recompensar com os prêmios  acima estabelecidos os Participantes que atenderem aos requisitos deste  Regulamento.<br>
    12.16  Para dirimir qualquer questão ou duvidas oriundas deste  Regulamento, fica eleita a Comarca da Capital de São Paulo.</p>
