import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {TranslateService} from "@ngx-translate/core";
import {
    ConsultantStandart, CreateAdhesionGQL, CreateStandartItemGQL, SearchConsultantStandartGQL,
    SearchConsultantStandartInput, SearchConsultantStandartQuery, User,
} from "../../../generated/graphql";
import {QueryRef} from "apollo-angular";
import {UserService} from "../../service/user.service";

declare var $: any;

@Component({
    selector: 'app-branches',
    templateUrl: './branches.component.html',
    styleUrls: ['./branches.component.scss']
})
export class BranchesComponent extends BaseComponent implements OnInit, OnDestroy {

    page = 1;

    user: User = new User();
    userResale: any = {id: '', type: '', isFleet: undefined};

    model: ConsultantStandart = new ConsultantStandart();

    cards: any[] = [
        {
            status: '',
            accession: false,
        },
        {
            status: 'review',
            accession: false,
        },
        {
            status: 'analysis',
            accession: false,
        },
        {
            status: 'pending',
            accession: true,
        },
        {
            status: 'approved',
            accession: false,
        },
    ];
    badge: any[] = [
        {
            name: 'GMR SSP',
            active: true,
        },
        {
            name: 'GMR SSP',
            active: false,
        },
        {
            name: 'GMR SSP',
            active: false,
        },
        {
            name: 'GMR SSP',
            active: false,
        },
        {
            name: 'GMR SSP',
            active: false,
        },
        {
            name: 'GMR SSP',
            active: false,
        },
        {
            name: 'GMR SSP',
            active: false,
        },
    ];
    actionSearch = false;
    modalFilter = false;

    consultList: ConsultantStandart[] = [];
    consultListQuery: QueryRef<SearchConsultantStandartQuery>;
    consultListInput: SearchConsultantStandartInput = new SearchConsultantStandartInput();

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public userService: UserService,
                private createAdhesionGQL: CreateAdhesionGQL,
                private searchConsultantStandartGQL: SearchConsultantStandartGQL
    ) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data?.id)) {
                    this.user = data as User;
                    const a = ['GVE', 'MA', 'EC'];
                    if (!a.includes(this.user.profile)) {
                        this.router.navigate(['/']);
                    }
                }
            }, error: err => super.onError(err)
        });
        this.consultListInput.keyword = '';
        this.consultListInput.status = [];
        this.consultListInput.step = [];
        this.consultListQuery = this.searchConsultantStandartGQL.watch({input: this.consultListInput});
        this.consultListQuery.valueChanges.subscribe(({data}) => {
            this.consultList = data?.searchConsultantStandart as ConsultantStandart[];
        });
    }

    ngOnDestroy(): void {
        const el = $('#createAdhesionResale');
        if (el[0]) {
            el.remove();
        }
    }

    onFilterChangeStep($event: any) {
        const a = $event.srcElement.value;
        if (this.consultListInput.step.includes(a)) {
            this.consultListInput.step = this.consultListInput.step.filter(x => x !== a)
        } else {
            this.consultListInput.step.push(a);
        }
        console.log(this.consultListInput.step);
    }

    onFilterChangeStatus($event: any) {
        const a = $event.srcElement.value;
        if (this.consultListInput.status.includes(a)) {
            this.consultListInput.status = this.consultListInput.status.filter(x => x !== a)
        } else {
            this.consultListInput.status.push(a);
        }
        console.log(this.consultListInput.status);
    }

    onFilter() {
        this.page = 1;
        this.closeModal('modalFilter');
        this.actionSearch = false;
        this.consultListQuery.refetch({input: this.consultListInput});
    }

    onConfirmCreateAdhesionConsult(x: ConsultantStandart) {
        this.model = x as ConsultantStandart;
        this.showModal('createAdhesionResale');
    }

    onCreateAdhesionConsult() {
        super.confirmMessage('Atenção', 'Tem certeza que deseja iniciar a adesão?', () => {
            this.toggleLoader(true);
            this.closeModal('createAdhesionResale');
            this.createAdhesion();
        });
    }

    createAdhesion() {
        this.createAdhesionGQL.mutate({input: this.model.id, inputType: this.userResale.type, inputFleet: this.userResale.isFleet}).subscribe(
            (data) => {
                this.router.navigate(['/cadastro/' + this.model.id]);
                setTimeout(() => {
                    this.toggleLoader(false);
                }, 2000);
            }, err => super.onError(err)
        );
    }

    isSearchFilter() {
        let x: boolean = false;
        const a = this.consultListInput.step;
        const b = this.consultListInput.status;
        if (!this.isNullOrUndefined(this.consultListInput.keyword) || a.length > 0 || b.length > 0) {
            x = true;
        }
        return x;
    }

    onSearchClean() {
        this.consultListInput.keyword = '';
        this.consultListInput.step = [];
        this.consultListInput.status = [];
        this.onFilter();
    }


    actionSaveResaleType(p: boolean): void {
        const arrValidateFields: any = [
            {value: this.userResale.type, text: `Tipo da Revenda <br>`}
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção',
                `Preencha os campos obrigatórios corretamente: <br><br> ${stringError}`);
            return;
        }
        this.userResale.isFleet = p;
        this.onCreateAdhesionConsult();
    }
}
