import { Component, OnInit } from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {UserService} from "../../service/user.service";
import {ClickBannerGQL, HomeBannersGQL, User} from "../../../generated/graphql";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent extends BaseComponent implements OnInit {

  user: User = new User();
  arrDebrand = [
    {
      number: '1',
      title: 'Como a revenda pode participar?',
      message: '<p>Após a revenda ter realizado o seu cadastro, basta acessar a página “Participe do Programa” e seguir com o envio das fotos. As imagens de fachada e frota serão analisadas por um auditor e se forem aprovadas com a nova identidade visual da marca, a revenda já concorre aos prêmios.</p>'
    },
    {
      number: '2',
      title: 'Qual o período da campanha?',
      message: '<p>A campanha será iniciada no dia 27 de março de 2023 e as revendas terão até o dia 31 de julho de 2023 para retirar todo e qualquer item que remeta à antiga marca Liquigás como: BR Petrobras, elemento Petrobras (listras), Sol, versão em caixa alta, cores ou qualquer outro que não esteja de acordo com o novo manual de padronização da Liquigás, exemplos do que deve ser retirado das fachadas e frotas abaixo:<br /><br /> <img src="assets/images/regulation-liquigas.png" alt="liquigas" /></p>'
    },
    {
      number: '3',
      title: 'Nas outras campanhas eu acessava só com a revenda matriz, nesta por que preciso cadastrar cada CNPJ?',
      message: '<p>Nesta campanha usaremos o CNPJ das matrizes e filiais.</p>'
    },
    {
      number: '4',
      title: 'Quem arca com os custos da padronização?',
      message: '<p>Na parceria proposta nesta campanha, a Copa Energia realizará a padronização da fachada até o fim do ano de 2023 por meio de um fornecedor terceiro contratado e a Revenda fará a comprovação da padronização da frota até o dia 31/07/2023, assim recebendo uma compensação em premiação e concorrendo ao sorteio final.</p>'
    },
    {
      number: '5',
      title: 'Como padronizar minha frota?',
      message: '<p>Estamos disponibilizando o manual de padronização da marca na página Downloads para que consulte todos os itens que são necessários para padronizar sua frota.</p>' +
          '<p><strong>Para padronização da frota:</strong> Enviar as fotos da sua frota (todos os veículos) com a identidade visual atual ou já padronizadas. As fotos serão analisadas e se for possível a visualização completa do veículo ele ainda não estiver padronizado, será liberado para a revenda enviar as fotos da frota completamente padronizada.</p>' +
          '<p><strong>Importante:</strong> As fotos com a frota padronizada devem ser tiradas da seguinte forma:</p>' +
          '<p><strong>Motos e triciclos:</strong> fotos das duas laterais e placa do veículo visível conforme o padrão do Manual de Padronização Liquigás. </p>' +
          '<p><strong>Veículos leves (que atendem ao consumidor final, como VUC, pick-ups e afins):</strong> Fotos da frente e traseira com placa do veículo visível e suas duas laterais, conforme Manual de Padronização Liquigás. </p>' +
          '<p><strong>Veículos pesados (caminhões e afins que fazem transporte do produto entre revenda e distribuidora, sem atender ao consumidor final):</strong> Fotos que mostrem a frota de acordo com o Manual de Padronização ou descaracterização / retirada da antiga marca Liquigás.</p>' +
          '<p>Veículos com marca própria da revenda só serão aceitos se estiverem de acordo com o Manual de Padronização.</p>'
    },
    {
      number: '6',
      title: 'Minha frota já está com a nova identidade visual da marca, como fica neste caso? ',
      message: '<p>Sem problemas, você deve enviar as fotos de toda a frota padronizada, a nossa auditoria vai avaliar, e neste caso existem duas possibilidades:</p>' +
          '<p><strong> 1º</strong>  Se houver necessidade de algum ajuste, você receberá a orientação para aplicar a correção do item incorreto. Daí é só corrigir e comprovar com as novas fotos.</p>' +
          '<p><strong> 2º</strong>  Se as fotos iniciais comprovarem que está tudo correto na aplicação da nova identidade visual, a auditoria fará a aprovação e você poderá concorrer aos prêmios.</p>'
    },
    {
      number: '7',
      title: 'Minha revenda não possui frota, não posso participar da campanha? ',
      message: '<p>Só participam da campanha e concorrem aos prêmios as revendas que não possuírem frota, mas que realizaram a padronização da fachada por conta própria, sendo premiada com os seguintes prêmios:</p>' +
          '<table border="0" cellspacing="0" cellpadding="0">\n' +
          '  <tr>\n' +
          '    <th><p align="center">Classe ANP</p></th>\n' +
          '    <th><p align="center">Ímãs    personalizados</p></th>\n' +
          '    <th><p align="center">Uniformes <br>\n' +
          '      (boné, camisa m/c, calça)</p></th>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Especial</p></td>\n' +
          '    <td><p align="center">12.000</p></td>\n' +
          '    <td><p align="center">30</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe VII</p></td>\n' +
          '    <td><p align="center">10.000</p></td>\n' +
          '    <td><p align="center">20</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe VI</p></td>\n' +
          '    <td><p align="center">10.000</p></td>\n' +
          '    <td><p align="center">20</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe V</p></td>\n' +
          '    <td><p align="center">8.000</p></td>\n' +
          '    <td><p align="center">16</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe IV</p></td>\n' +
          '    <td><p align="center">5.000</p></td>\n' +
          '    <td><p align="center">10</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe III</p></td>\n' +
          '    <td><p align="center">4.000</p></td>\n' +
          '    <td><p align="center">6</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe II</p></td>\n' +
          '    <td><p align="center">500</p></td>\n' +
          '    <td><p align="center">2</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe I</p></td>\n' +
          '    <td><p align="center">1.000</p></td>\n' +
          '    <td><p align="center">2</p></td>\n' +
          '  </tr>\n' +
          '</table>' +
          '<p>Porém, se a revenda não padronizou a fachada e ainda precise retirar a marca antiga da Liquigás vinculada à BR Petrobras, poderá realizar a adesão da campanha enviando as fotos de fachada como estão agora e a Copa Energia enviará uma equipe para realizar a padronização, mas essa revenda não concorrerá aos prêmios.</p>'
    },
    {
      number: '8',
      title: 'Como padronizar minha fachada? ',
      message: '<p>Basta inserir até 4 fotos de sua fachada com a identidade visual atual ou com a descaracterização da marca antiga da Liquigás vinculada a BR Petrobras, em “Participe do Programa”. As fotos serão analisadas e após a aprovação, você deve aguardar o agendamento para padronizar a fachada de sua revenda com a nova identidade visual e assinar o termo de compromisso no dia agendado. </p>'
    },
    {
      number: '9',
      title: 'Minha fachada já está com a nova identidade visual da marca, como fica neste caso? ',
      message: '<p>Sem problemas, você deve enviar as fotos da fachada padronizada, a nossa auditoria vai avaliar, e neste caso existem duas possibilidades:</p>' +
          '<p>1ª se houver necessidade de algum ajuste, você receberá a orientação para aplicar a correção do item incorreto. Daí é só corrigir e comprovar com as novas fotos.\n</p>' +
          '<p>2ª Se as fotos iniciais comprovarem que está tudo correto na aplicação da nova identidade visual, a auditoria fará a aprovação.</p>'
    },
    {
      number: '10',
      title: 'A fachada da minha revenda possui rachaduras, buracos e/ou itens danificados, esses itens serão concertados pela equipe de padronização? ',
      message: '<p>Não, a equipe enviada até a revenda será responsável apenas pela padronização de pintura e troca de placas da revenda, sendo que qualquer manutenção deve ser realizada pela própria revenda. Orientamos que faça a manutenção antes de receber a equipe para padronização.</p>'
    },
    {
      number: '11',
      title: 'Se tiver dúvidas de como utilizar os materiais da padronização, com quem posso falar? ',
      message: '<p>Deixamos disponível no site um manual de como padronizar sua frota. Se ainda restar dúvida, pode mandar falar com o seu consultor ou entrar em contato com o SAC através do e-mail sac@juntosnogas.com.br e whatsapp (11) 91782-4900.</p>'
    },
    {
      number: '12',
      title: 'Quais os prêmios que posso ganhar? ',
      message: '<p><strong>Padronizou, Ganhou!</strong></p>' +
          '<p>Todas as revendas que comprovarem a padronização de 100% da frota seguindo o manual da marca, ganham de acordo com sua classe, em caso de padronização de 100% da frota na cor verde, a revenda ganha 50% a mais desta premiação:\n</p>' +
          '<table border="0" cellspacing="0" cellpadding="0">\n' +
          '  <tr>\n' +
          '    <th><p align="center">Classe ANP</p></th>\n' +
          '    <th><p align="center">Ímãs    personalizados</p></th>\n' +
          '    <th><p align="center">Uniformes <br>\n' +
          '      (boné, camisa m/c, calça)</p></th>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Especial</p></td>\n' +
          '    <td><p align="center">12.000</p></td>\n' +
          '    <td><p align="center">30</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe VII</p></td>\n' +
          '    <td><p align="center">10.000</p></td>\n' +
          '    <td><p align="center">20</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe VI</p></td>\n' +
          '    <td><p align="center">10.000</p></td>\n' +
          '    <td><p align="center">20</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe V</p></td>\n' +
          '    <td><p align="center">8.000</p></td>\n' +
          '    <td><p align="center">16</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe IV</p></td>\n' +
          '    <td><p align="center">5.000</p></td>\n' +
          '    <td><p align="center">10</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe III</p></td>\n' +
          '    <td><p align="center">4.000</p></td>\n' +
          '    <td><p align="center">6</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe II</p></td>\n' +
          '    <td><p align="center">500</p></td>\n' +
          '    <td><p align="center">2</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe I</p></td>\n' +
          '    <td><p align="center">1.000</p></td>\n' +
          '    <td><p align="center">2</p></td>\n' +
          '  </tr>\n' +
          '</table>' +
          '<p><strong>Sorteio</strong></p>' +
          '<p>As revendas que comprovarem a padronização de 100% da frota até o dia 30/06/2023 ganham 6 cupons para concorrerem ao sorteio.<br />As revendas que comprovarem a padronização de 100% da frota entre 01/07/2023 e 31/07/2023 ganham 3 cupons para concorrerem ao sorteio.<br />As revendas que comprovarem a padronização de 100% da frota na cor verde, seguindo o manual da marca, ganham cupons em dobro de acordo com a data de comprovação. Os prêmios sorteados serão de acordo com a classe da revenda na ANP:</p>' +
          '<table border="0" cellspacing="0" cellpadding="0">\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe</p></td>\n' +
          '    <td><p align="center">Nº revendas sorteadas</p></td>\n' +
          '    <td><p align="center">Valor de Premiação (R$)</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Especial</p></td>\n' +
          '    <td><p align="center">5</p></td>\n' +
          '    <td><p align="center">24.000</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe VII</p></td>\n' +
          '    <td><p align="center">5</p></td>\n' +
          '    <td><p align="center">18.000</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe VI</p></td>\n' +
          '    <td><p align="center">5</p></td>\n' +
          '    <td><p align="center">18.000</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe V</p></td>\n' +
          '    <td><p align="center">20</p></td>\n' +
          '    <td><p align="center">12.000</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe IV</p></td>\n' +
          '    <td><p align="center">20</p></td>\n' +
          '    <td><p align="center">8.000</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe III</p></td>\n' +
          '    <td><p align="center">80</p></td>\n' +
          '    <td><p align="center">4.000</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe II</p></td>\n' +
          '    <td><p align="center">80</p></td>\n' +
          '    <td><p align="center">2.000</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe I</p></td>\n' +
          '    <td><p align="center">80</p></td>\n' +
          '    <td><p align="center">2.000</p></td>\n' +
          '  </tr>\n' +
          '</table>'
    },
    {
      number: '13',
      title: 'Todos que padronizarem a frota ganham ímãs e uniformes? ',
      message: '<p>Sim. A revenda que estiver padronizada com a nova identidade visual e aprovada na campanha, ganha! <strong>Importante:</strong> desde que esteja dentro do período da campanha e a padronização de acordo com o manual, aprovada pela auditoria.</p>'
    },
    {
      number: '14',
      title: 'Eu concorro a algum prêmio pela padronização da minha fachada? ',
      message: '<p>Concorrem a premiação apenas as revendas que não possuem frota e que comprovarem a padronização da fachada realizada por conta da própria revenda, premiação entregue de acordo com a classe ANP:</p>' +
          '<table border="0" cellspacing="0" cellpadding="0">\n' +
          '  <tr>\n' +
          '    <th><p align="center">Classe ANP</p></th>\n' +
          '    <th><p align="center">Ímãs    personalizados</p></th>\n' +
          '    <th><p align="center">Uniformes <br>\n' +
          '      (boné, camisa m/c, calça)</p></th>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Especial</p></td>\n' +
          '    <td><p align="center">12.000</p></td>\n' +
          '    <td><p align="center">30</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe VII</p></td>\n' +
          '    <td><p align="center">10.000</p></td>\n' +
          '    <td><p align="center">20</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe VI</p></td>\n' +
          '    <td><p align="center">10.000</p></td>\n' +
          '    <td><p align="center">20</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe V</p></td>\n' +
          '    <td><p align="center">8.000</p></td>\n' +
          '    <td><p align="center">16</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe IV</p></td>\n' +
          '    <td><p align="center">5.000</p></td>\n' +
          '    <td><p align="center">10</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe III</p></td>\n' +
          '    <td><p align="center">4.000</p></td>\n' +
          '    <td><p align="center">6</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe II</p></td>\n' +
          '    <td><p align="center">500</p></td>\n' +
          '    <td><p align="center">2</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe I</p></td>\n' +
          '    <td><p align="center">1.000</p></td>\n' +
          '    <td><p align="center">2</p></td>\n' +
          '  </tr>\n' +
          '</table>'
    },
    {
      number: '15',
      title: 'Como e quando vai acontecer a entrega dos prêmios?',
      message: '<p><strong>Bateu, levou</strong><br>' +
          '  Ímãs e Uniformes: serão entregues nos endereços  das revendas premiadas no prazo de até 180 dias após a divulgação dos premiados.  Para a personalização dos ímãs com o nome da revenda e telefone, entraremos em  contato para recolher esses dados.</p>' +
          '<p><strong>Sorteio</strong><br>' +
          '  Cartão-presente: serão entregues nos endereços  das revendas sorteadas no prazo de até 180 dias após a divulgação dos  premiados.</p>'
    }
  ];
  arrNewCop = [
    {
      number: '1',
      title: 'Como a revenda pode participar?',
      message: '<p>Após a revenda ter realizado o seu cadastro, basta acessar a página “Participe do Programa” e seguir com o envio das fotos. As imagens de fachada e frota serão analisadas por um auditor e, se forem aprovadas com a nova identidade visual da marca, a revenda já concorre aos prêmios.</p>'
    },
    {
      number: '2',
      title: 'Qual o período da campanha? ',
      message: '<p>A campanha será iniciada no dia 27 de março de 2023, e a revenda terá até o dia 15 de novembro de 2023 para padronizar sua frota com a nova identidade visual da marca Copagaz e enviar as fotos da fachada para que a Copa Energia solicite o agendamento de sua padronização. Para isso, a revenda receberá um layout de como a fachada ficará e deverá validar esse layout na página “Participe do Programa” no site da campanha.</p>'
    },
    {
      number: '3',
      title: 'Nas outras campanhas, eu acessava só com a revenda matriz, nesta por que preciso cadastrar cada CNPJ?',
      message: '<p>Nesta campanha usaremos o CNPJ das matrizes e filiais.</p>'
    },
    {
      number: '4',
      title: 'Quem arca com os custos da padronização?',
      message: '<p>Na parceria proposta nesta campanha, a Copa Energia realizará a padronização da fachada até o fim do ano de 2023 por meio de um fornecedor terceiro contratado, e a revenda fará a comprovação da padronização da frota até o dia 15/11/2023, assim recebendo uma compensação em premiação.</p>'
    },
    {
      number: '5',
      title: 'Como padronizar minha frota?',
      message: '<p>Estamos disponibilizando o manual de padronização da marca Copagaz na página Downloads para que você consulte todos os itens que são necessários para padronizar sua frota.</p>' +
          '<p><strong>Para padronização da frota:</strong> enviar as fotos da sua frota (todos os veículos) com a identidade visual atual ou já padronizada. As fotos serão analisadas e se for possível a visualização completa do veículo e se ele ainda não estiver padronizado, será liberado para a revenda enviar as fotos da frota completamente padronizada.</p>' +
          '<p><strong>Importante:</strong> As fotos com a frota padronizada devem ser tiradas da seguinte forma:</p>' +
          '<p><strong>Motos e triciclos:</strong> fotos das duas laterais e placa do veículo visível conforme o padrão do Manual de Padronização Copagaz.</p>' +
          '<p><strong>Veículos leves (que atendem ao consumidor final, como VUC, pick-ups e afins):</strong> Fotos da frente e traseira com placa do veículo visível e suas duas laterais, conforme Manual de Padronização Copagaz.  </p>' +
          '<p><strong>Veículos pesados (caminhões e afins que fazem transporte do produto entre revenda e distribuidora, sem atender ao consumidor final):</strong> Fotos que mostrem a frota de acordo com o Manual de Padronização Copagaz.</p>' +
          '<p>Veículos com marca própria da revenda só serão aceitos se estiverem de acordo com o Manual de Padronização.</p>'
    },
    {
      number: '6',
      title: 'Minha frota já está com a nova identidade visual da marca, como fica neste caso? ',
      message: '<p>Sem problemas, você deve enviar as fotos de toda a frota padronizada, a nossa auditoria vai avaliar, e neste caso existem duas possibilidades:</p>' +
          '<p><strong> 1º</strong>  Se houver necessidade de algum ajuste, você receberá a orientação para aplicar a correção do item incorreto. Daí é só corrigir e comprovar com as novas fotos.</p>' +
          '<p><strong> 2º</strong>  Se as fotos iniciais comprovarem que está tudo correto na aplicação da nova identidade visual, a auditoria fará a aprovação e você poderá concorrer aos prêmios.</p>'
    },
    {
      number: '7',
      title: 'Minha revenda não possui frota, não posso participar da campanha? ',
      message: '<p>Você não concorrerá aos prêmios, pois são voltados apenas para padronização de frota. Para a padronização da sua fachada, você poderá realizar a adesão da campanha enviando as fotos da fachada como está agora, e a Copa Energia enviará uma equipe para realizar a padronização.</p>'
    },
    {
      number: '8',
      title: 'Como padronizar minha fachada? ',
      message: '<p>Basta inserir até quatro fotos de sua fachada com a identidade visual atual em “Participe do Programa”. As fotos serão analisadas, após a aprovação, você deve aguardar o agendamento para padronizar a fachada de sua revenda com a nova identidade visual e assinar o termo de compromisso no dia agendado. </p>'
    },
    {
      number: '9',
      title: 'Minha fachada já está com a nova identidade visual da marca, como fica nesse caso?',
      message: '<p>Sem problemas, você deve enviar as fotos da fachada padronizada para a nossa auditoria avaliar. Nesse caso, existem duas possibilidades:</p>' +
          '<p><strong>1ª</strong> Se houver necessidade de algum ajuste, nossa equipe agendará com a revenda para realizar a correção da padronização sem custos para a revenda.</p>' +
          '<p><strong>2ª</strong> Se as fotos iniciais comprovarem que está tudo correto na aplicação da nova identidade visual, a auditoria fará a aprovação. </p>'
    },
    {
      number: '10',
      title: 'A fachada da minha revenda possui rachaduras, buracos e/ou itens danificados. Esses itens serão concertados pela equipe de padronização?',
      message: '<p>Não, a equipe enviada até a revenda será responsável apenas pela padronização de pintura e troca de placas da revenda, sendo que qualquer manutenção deverá ser realizada pela própria revenda. Orientamos que faça a manutenção antes de receber a equipe para padronização.</p>'
    },
    {
      number: '11',
      title: 'Se tiver dúvidas de como utilizar os materiais da padronização, com quem posso falar?',
      message: '<p>Deixamos disponível no site um manual de como padronizar sua frota. Se ainda restar dúvida, pode falar com o seu consultor ou entrar em contato com o SAC pelo e-mail sac@juntosnogas.com.br e WhatsApp (11) 91782-4900.</p>'
    },
    {
      number: '12',
      title: 'Quais os prêmios que posso ganhar? ',
      message: '<p><strong>Padronizou, Ganhou!</strong></p>' +
          '<p>Todas as revendas que comprovarem a padronização de 100% da frota seguindo o manual da marca ganham de acordo com sua classe. Em caso de padronização de 100% da frota na cor verde, a revenda ganha 50% a mais dessa premiação:</p>' +
          '<table border="0" cellspacing="0" cellpadding="0">\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe ANP</p></td>\n' +
          '    <td><p align="center">Ímãs    personalizados</p></td>\n' +
          '    <td><p align="center">Uniformes <br>\n' +
          '      (boné, camisa m/c, calça)</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Especial</p></td>\n' +
          '    <td><p align="center">5.000</p></td>\n' +
          '    <td><p align="center">12</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe VII</p></td>\n' +
          '    <td><p align="center">5.000</p></td>\n' +
          '    <td><p align="center">12</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe VI</p></td>\n' +
          '    <td><p align="center">5.000</p></td>\n' +
          '    <td><p align="center">10</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe V</p></td>\n' +
          '    <td><p align="center">3.000</p></td>\n' +
          '    <td><p align="center">8</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe IV</p></td>\n' +
          '    <td><p align="center">3.000</p></td>\n' +
          '    <td><p align="center">8</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe III</p></td>\n' +
          '    <td><p align="center">3.000</p></td>\n' +
          '    <td><p align="center">6</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe II</p></td>\n' +
          '    <td><p align="center">1.000</p></td>\n' +
          '    <td><p align="center">4</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe I</p></td>\n' +
          '    <td><p align="center">1.000</p></td>\n' +
          '    <td><p align="center">2</p></td>\n' +
          '  </tr>\n' +
          '</table>\n'
    },
    {
      number: '13',
      title: 'Todos que padronizarem a frota ganham ímãs e uniformes? ',
      message: '<p> Sim. A revenda que estiver padronizada com a nova identidade visual e for aprovada na campanha, ganha!<strong>Importante: </strong> desde que esteja dentro do período da campanha e a padronização de acordo com o manual Copagaz, aprovada pela auditoria.</p>'
    },
    {
      number: '14',
      title: 'Eu concorro a algum prêmio pela padronização da minha fachada? ',
      message: '<p>Não, só concorre aos prêmios as revendas que comprovarem 100% da padronização da frota.</p>'
    },
    {
      number: '15',
      title: 'Como e quando vai acontecer a entrega dos prêmios?',
      message: '<p><strong>Bateu, levou</strong><br>' +
          '  Ímãs e kits uniformes: serão entregues nos endereços das revendas vencedoras no prazo de até 180 dias após a divulgação dos premiados. Para a personalização dos ímãs com o nome e telefone da revenda, entraremos em contato para recolher esses dados.\n</p>'
    }
  ];
  arrNewLiq = [
    {
      number: '1',
      title: 'Como a revenda pode participar?',
      message: '<p>Após a revenda ter realizado o seu cadastro, basta acessar a página “Participe do Programa” e seguir com o envio das fotos. As imagens de fachada e frota serão analisadas por um auditor e, se forem aprovadas com a nova identidade visual da marca, a revenda já concorre aos prêmios.</p>'
    },
    {
      number: '2',
      title: 'Qual o período da campanha?',
      message: '<p>A campanha será iniciada no dia 27 de março de 2023, e a revenda terá até o dia 15 de novembro de 2023 para padronizar sua frota com a nova identidade visual da marca Copagaz e enviar as fotos da fachada para que a Copa Energia solicite o agendamento de sua padronização. Para isso, a revenda receberá um layout de como a fachada ficará e deverá validar esse layout na página “Participe do Programa” no site da campanha.</p>'
    },
    {
      number: '3',
      title: 'Nas outras campanhas, eu acessava só com a revenda matriz, nesta por que preciso cadastrar cada CNPJ?',
      message: '<p>Nesta campanha usaremos o CNPJ das matrizes e filiais.</p>'
    },
    {
      number: '4',
      title: 'Quem arca com os custos da padronização? ',
      message: '<p>Na parceria proposta nesta campanha, a Copa Energia realizará a padronização da fachada até o fim do ano de 2023 por meio de um fornecedor terceiro contratado, e a revenda fará a comprovação da padronização da frota até o dia 15/11/2023, assim recebendo uma compensação em premiação.</p>'
    },
    {
      number: '5',
      title: 'Como padronizar minha frota?',
      message: '<p>Estamos disponibilizando o manual de padronização da marca Copagaz na página Downloads para que você consulte todos os itens que são necessários para padronizar sua frota.</p>' +
          '<p><strong>Para padronização da frota:</strong> enviar as fotos da sua frota (todos os veículos) com a identidade visual atual ou já padronizada. As fotos serão analisadas e se for possível a visualização completa do veículo e se ele ainda não estiver padronizado, será liberado para a revenda enviar as fotos da frota completamente padronizada.</p>' +
          '<p><strong>Importante:</strong> as fotos com a frota padronizada devem ser tiradas da seguinte forma:</p>' +
          '<p><strong>Motos e triciclos:</strong>  fotos das duas laterais e placa do veículo visível conforme o padrão do Manual de Padronização Copagaz.</p>' +
          '<p><strong>Veículos leves (que atendem ao consumidor final, como VUC, pick-ups e afins):</strong> fotos da frente e traseira com placa do veículo visível e suas duas laterais, conforme Manual de Padronização Copagaz. </p>' +
          '<p><strong>Veículos pesados (caminhões e afins que fazem transporte do produto entre revenda e distribuidora, sem atender ao consumidor final): </strong>fotos que mostrem a frota de acordo com o Manual de Padronização Copagaz.</p>' +
          '<p>Veículos com marca própria da revenda só serão aceitos se estiverem de acordo com o Manual de Padronização. </p>'
    },
    {
      number: '6',
      title: 'Minha frota já está com a nova identidade visual da marca, como fica nesse caso? ',
      message: '<p>Sem problemas, você deve enviar as fotos de toda a frota padronizada. A nossa auditoria vai avaliar e, nesse caso, existem duas possibilidades:</p>' +
          '<p><strong> 1º</strong>  Se houver necessidade de algum ajuste, você receberá a orientação para aplicar a correção do item incorreto. Daí é só corrigir e comprovar com as novas fotos.</p>' +
          '<p><strong> 2º</strong>  Se as fotos iniciais comprovarem que está tudo correto na aplicação da nova identidade visual, a auditoria fará a aprovação, e você poderá concorrer aos prêmios.</p>'
    },
    {
      number: '7',
      title: 'Minha revenda não possui frota, não posso participar da campanha? ',
      message: '<p>Você não concorrerá aos prêmios, pois são voltados apenas para padronização de frota. Para a padronização da sua fachada, você poderá realizar a adesão da campanha enviando as fotos da fachada como está agora, e a Copa Energia enviará uma equipe para realizar a padronização.</p>'
    },
    {
      number: '8',
      title: 'Como padronizar minha fachada? ',
      message: '<p>Basta inserir até quatro fotos de sua fachada com a identidade visual atual em “Participe do Programa”. As fotos serão analisadas, após a aprovação, você deve aguardar o agendamento para padronizar a fachada de sua revenda com a nova identidade visual e assinar o termo de compromisso no dia agendado. </p>'
    },
    {
      number: '9',
      title: 'Minha fachada já está com a nova identidade visual da marca, como fica nesse caso?',
      message: '<p>Sem problemas, você deve enviar as fotos da fachada padronizada para a nossa auditoria avaliar. Nesse caso, existem duas possibilidades:</p>' +
          '<p><strong>1ª</strong> Se houver necessidade de algum ajuste, nossa equipe agendará com a revenda para realizar a correção da padronização sem custos para a revenda.</p>' +
          '<p><strong>2ª</strong> Se as fotos iniciais comprovarem que está tudo correto na aplicação da nova identidade visual, a auditoria fará a aprovação. </p>'
    },
    {
      number: '10',
      title: 'A fachada da minha revenda possui rachaduras, buracos e/ou itens danificados. Esses itens serão concertados pela equipe de padronização?',
      message: '<p>Não, a equipe enviada até a revenda será responsável apenas pela padronização de pintura e troca de placas da revenda, sendo que qualquer manutenção deverá ser realizada pela própria revenda. Orientamos que faça a manutenção antes de receber a equipe para padronização.</p>'
    },
    {
      number: '11',
      title: 'Se tiver dúvidas de como utilizar os materiais da padronização, com quem posso falar?',
      message: '<p>Deixamos disponível no site um manual de como padronizar sua frota. Se ainda restar dúvida, pode falar com o seu consultor ou entrar em contato com o SAC pelo e-mail sac@juntosnogas.com.br e WhatsApp (11) 91782-4900.</p>'
    },
    {
      number: '12',
      title: 'Quais os prêmios que posso ganhar? ',
      message: '<p><strong>Padronizou, Ganhou!</strong></p>' +
          '<p>Todas as revendas que comprovarem a padronização de 100% da frota seguindo o manual da marca ganham de acordo com sua classe. Em caso de padronização de 100% da frota na cor verde, a revenda ganha 50% a mais dessa premiação:</p>' +
          '<table border="0" cellspacing="0" cellpadding="0">\n' +
          '  <tr>\n' +
          '    <th><p align="center">Classe ANP</p></th>\n' +
          '    <th><p align="center">Ímãs    personalizados</p></th>\n' +
          '    <th><p align="center">Uniformes <br>\n' +
          '      (boné, camisa m/c, calça)</p></th>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Especial</p></td>\n' +
          '    <td><p align="center">5000</p></td>\n' +
          '    <td><p align="center">12</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe VII</p></td>\n' +
          '    <td><p align="center">5000</p></td>\n' +
          '    <td><p align="center">12</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe VI</p></td>\n' +
          '    <td><p align="center">5000</p></td>\n' +
          '    <td><p align="center">10</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe V</p></td>\n' +
          '    <td><p align="center">3000</p></td>\n' +
          '    <td><p align="center">8</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe IV</p></td>\n' +
          '    <td><p align="center">3.000</p></td>\n' +
          '    <td><p align="center">8</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe III</p></td>\n' +
          '    <td><p align="center">3.000</p></td>\n' +
          '    <td><p align="center">6</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe II</p></td>\n' +
          '    <td><p align="center">1000</p></td>\n' +
          '    <td><p align="center">4</p></td>\n' +
          '  </tr>\n' +
          '  <tr>\n' +
          '    <td><p align="center">Classe I</p></td>\n' +
          '    <td><p align="center">1.000</p></td>\n' +
          '    <td><p align="center">2</p></td>\n' +
          '  </tr>\n' +
          '</table>'
    },
    {
      number: '13',
      title: 'Todos que padronizarem a frota ganham ímãs e uniformes?',
      message: '<p>Sim. A revenda que estiver padronizada com a nova identidade visual e for aprovada na campanha, ganha!<strong>Importante:</strong> desde que esteja dentro do período da campanha e a padronização de acordo com o manual Copagaz, aprovada pela auditoria.</p>'
    },
    {
      number: '14',
      title: 'Eu concorro a algum prêmio pela padronização da minha fachada? ',
      message: '<p>Não, só concorre aos prêmios as revendas que comprovarem 100% da padronização da frota.</p>'
    },
    {
      number: '15',
      title: '    15. Como e quando vai acontecer a entrega dos prêmios?\n',
      message: '<p><strong>Bateu, levou</strong><br>' +
          '  Ímãs e kits uniformes: serão entregues nos endereços das revendas vencedoras no prazo de até 180 dias após a divulgação dos premiados. Para a personalização dos ímãs com o nome e telefone da revenda, entraremos em contato para recolher esses dados.</p>'
    }
  ];

  constructor(public router: Router,
              public loading: NgxSpinnerService,
              public userService: UserService,
              // private searchWinnerGQL: SearchWinnerGQL,
              public translate: TranslateService) {
    super(router, loading, translate);
  }


  ngOnInit(): void {
    this.userService.user.subscribe({
      next: data => {
        if (!this.isNullOrUndefined(data?.id)) {
          this.user = data as User;
        }
      }, error: err => super.onError(err)
    });
  }

}
