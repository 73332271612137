import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';
import {NgxSpinnerService} from 'ngx-spinner';
import moment from 'moment';

declare var $: any;

export class BaseComponent {

    labels: any = {
        previousLabel: '',
        nextLabel: '',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService) {
    }

    setToken(token: string) {
        localStorage.setItem('token', token);
    }

    getBaseURL() {
        return environment.base_url;
    }

    getTranslate(s: string) {
        if (!this.isNullOrUndefined(s)) {
            return this.translate.instant(s);
        }
    }

    handlerViewPasswordIcon(id = 'password') {
        // const input = document.querySelector('#password');
        const input = document.querySelector('#' + id);
        return input.getAttribute('type') === 'password';
    }

    handlerViewPassword(id = 'password') {
        const input = document.querySelector('#' + id);
        if (input.getAttribute('type') === 'password') {
            input.setAttribute('type', 'text');
        } else {
            input.setAttribute('type', 'password');
        }
    }

    switchLanguage(language: string) {
        if (language === 'pt') {
            language = 'pt';
        } else if (language === 'en') {
            language = 'en';
        } else if (language === 'es') {
            language = 'es';
        }
        this.translate.use(language);
    }

    handlerConfirm() {
        $('#modalNoteResume').modal('show');
    }

    showModal(idModal) {
        $('#' + idModal).modal('show');
    }

    closeModal(idModal) {
        $('#' + idModal).modal('hide');
    }

    showMessage(t: string, m: string, type: any = 'warning') {
        Swal.fire({
            title: t,
            html: m,
            icon: type,
            confirmButtonColor: '#032E58',
        });
    }

    confirmMessage(t: string, m: string, callback: any = null, callbackNo?: any) {
        Swal.fire({
            title: t,
            html: m,
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            confirmButtonColor: '#032E58',
            cancelButtonColor: '#dc3545'
        }).then((result) => {
            if (!this.isNullOrUndefined(result.value) && result.value === true) {
                if (callback != null) {
                    callback();
                }
            } else {
                if (callbackNo != null) {
                    callbackNo();
                }
            }
        });
    }

    onError(error) {
        this.loading.hide();
        if (error.status === 401) {
            this.router.navigate(['/login']);
            return;
        }

        // const e = JSON.parse(error['error']);

        if (error.status === 400) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), error.error.message, 'warning');
            return;
        }

        if (error.status === 500) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningMsgError'), 'warning');
            return;
        }
    }

    isNullOrUndefined(value) {
        return typeof value === 'undefined' || value == null || value.length === 0;
    }

    validateField(obj) {
        let strError = ``;
        obj.map((e) => {
            if (this.isNullOrUndefined(e.value)) {
                strError += `${e.text}`;
            }
        });
        return strError;
    }

    validateFieldZero(obj) {
        let strError = ``;
        obj.map((e) => {
            if (this.isNullOrUndefined(e.value) || e.value === 0) {
                strError += `${e.text}`;
            }
        });
        return strError;
    }

    handlerFlagImage(value) {
        switch (value) {
            case 'Chile':
                return 'chile.svg';
            case 'Peru':
                return 'peru.svg';
            case 'Bolívia':
                return 'bolivia.svg';
            case 'Venezuela':
                return 'venezuela.svg';
            case 'Argentina':
                return 'argentina.svg';
            case 'México':
                return 'mexico.svg';
            case 'Equador':
                return 'equador.svg';
            case 'Guatemala':
                return 'guatemala.svg';
            case 'Brazil':
                return 'brazil.svg';
            case 'Colômbia':
                return 'colombia.svg';
            case 'Uruguai':
                return 'uruguay.svg';
            case 'Paraguay':
                return 'paraguay.svg';
        }
    }

    handlerStepBar(value) {

        switch (value) {
            case 1:
                return 'width: 0%';
            case 2:
                return 'width: 25%';
            case 3:
                return 'width: 50%';
            case 4:
                return 'width: 75%';
            case 5:
                return 'width: 100%';
        }
    }

    handlerTitlesBoxVertical(): void {
        const el = $('.page-content__details__tab');
        if (!el[0]) {
            return;
        }
        el.each((i, x) => {
            $(x).css({width: $(x).parent()[0].getBoundingClientRect().height});
        });
    }

    handlerBoxSize(): void {
        const el = $('.js-set-border');
        if (!el[0]) {
            return;
        }
        el.each((i, x) => {
            const size = $(x).parent()[0].getBoundingClientRect().height;
            $(x)
                .css({
                    height: size
                })
                .find('span').css({
                width: size
            });
        });
    }

    setDatePicker(id: string, callback, date = 30) {
        $('.' + id).daterangepicker({
            startDate: moment().subtract(date, 'days'),
            cancelButtonClasses: 'btn-danger',
            ranges: {
                Hoje: [moment(), moment()],
                Ontem: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                '7 Dias': [moment().subtract(6, 'days'), moment()],
                '30 Dias': [moment().subtract(29, 'days'), moment()]
            },
            locale: {
                customRangeLabel: 'Personalizar',
                format: 'DD/MM/YYYY',
                separator: ' - ',
                applyLabel: 'Aplicar',
                cancelLabel: 'Cancelar',
                daysOfWeek: [
                    'Dom',
                    'Seg',
                    'Ter',
                    'Qua',
                    'Qui',
                    'Sex',
                    'Sab'
                ],
                monthNames: [
                    'Janeiro',
                    'Fevereiro',
                    'Março',
                    'Abril',
                    'Maio',
                    'Junho',
                    'Julho',
                    'Agosto',
                    'Setembro',
                    'Outubro',
                    'Novembro',
                    'Dezembro'
                ],
                firstDay: 1
            }
        }, callback);
    }

    toggleLoader(b: boolean) {
        if (!b) {
            $('#loaderBox').fadeOut();
        } else {
            $('#loaderBox').fadeIn();
        }
    }

    isEMailValid(strEmail) {
        const str = strEmail;
        const exp = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;
        const testResult = exp.test(str);
        if (!testResult) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningMsgEmail'), 'warning');
        }
        return testResult;
    }

    isCPFValid(strCPF) {
        let Soma;
        let Resto;
        Soma = 0;
        const strErrorTitle = 'Atenção';
        const strErrorDescription = 'Informe um CPF válido para prosseguir.';
        strCPF = strCPF.replace(/\D+/g, '');
        if (strCPF === '00000000000' || strCPF === '12112112155') {
            this.showMessage(strErrorTitle, strErrorDescription, 'warning');
            return false;
        }
        for (let i = 1; i <= 9; i++) {
            // tslint:disable-next-line:radix
            Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        }
        Resto = (Soma * 10) % 11;
        if ((Resto === 10) || (Resto === 11)) {
            Resto = 0;
        }
        // tslint:disable-next-line:radix
        if (Resto !== parseInt(strCPF.substring(9, 10))) {
            this.showMessage(strErrorTitle, strErrorDescription, 'warning');
            return false;
        }
        Soma = 0;
        for (let i = 1; i <= 10; i++) {
            // tslint:disable-next-line:radix
            Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        }
        Resto = (Soma * 10) % 11;
        if ((Resto === 10) || (Resto === 11)) {
            Resto = 0;
        }
        // tslint:disable-next-line:radix
        if (Resto !== parseInt(strCPF.substring(10, 11))) {
            this.showMessage(strErrorTitle, strErrorDescription, 'warning');
            return false;
        }
        return true;
    }

    isCNPJValid(cnpj) {
        let i;
        const strErrorTitle = 'Atenção';
        const strErrorDescription = 'Informe um CNPJ válido para prosseguir.';
        cnpj = cnpj.replace(/[^\d]+/g, '');

        /*if (cnpj === '') {
          this.showMessage(strErrorTitle, strErrorDescription, 'warning');
          return false;
        }*/

        if (cnpj.length !== 14) {
            this.showMessage(strErrorTitle, strErrorDescription, 'warning');
            return false;
        }

        // Elimina CNPJs invalidos conhecidos
        if (cnpj === '00000000000000' ||
            cnpj === '11111111111111' ||
            cnpj === '22222222222222' ||
            cnpj === '33333333333333' ||
            cnpj === '44444444444444' ||
            cnpj === '55555555555555' ||
            cnpj === '66666666666666' ||
            cnpj === '77777777777777' ||
            cnpj === '88888888888888' ||
            cnpj === '99999999999999') {
            this.showMessage(strErrorTitle, strErrorDescription, 'warning');
            return false;
        }

        // Valida DVs
        const tamanho = cnpj.length - 2;
        const numeros = cnpj.substring(0, tamanho);
        const digitos = cnpj.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;
        for (i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2) {
                pos = 9;
            }
        }
        const resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado !== Number(digitos.charAt(0))) {
            // console.log('1');
            this.showMessage(strErrorTitle, strErrorDescription, 'warning');
            return false;
        }

        return true;
    }

    onLogout() {
        localStorage.clear();
        this.closeModal('modalFirstAccess');
        window.location.href = '/login';
        /*this.confirmMessage('Atenção', 'Deseja realmente sair?', () => {
            const p = window.location.pathname.split('/');
            const virtualPath = p.length >= 3 ? '/' + p[1] : '';
            const redirectUrl = 'https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=' +
                window.location.origin + virtualPath + '/logout';
            window.location.href = '/logout'; // redirectUrl;
        });*/
    }

    routerNavigateHomePage(url: string) {
        this.router.navigate(['/']);
        $('body').removeClass('nav-open');
        $('.hamburger').removeClass('open');
        setTimeout(() => {
            if (!$('#' + url)[0]) {
                return false;
            }
            if (url == 'home') {
                return $('html, body').stop().animate({scrollTop: $('html, body').offset().top}, 'slow');
            }
            if (url === 'comoparticipar' || url === 'faq') {
                this.handlerAnimation(url, -100);
            } else if (url === 'ganhadores' || url === 'faq') {
                this.handlerAnimation(url, 100);
            } else {
                this.handlerAnimation(url, 0);
            }
        }, 500);
    }

    handlerAnimation(url: string, scroll: number) {
        $('html, body').stop().animate({scrollTop: $('#' + url).offset().top + scroll}, 'slow', () => {
            const elScrollTop = $('html, body').scrollTop();
            if (elScrollTop > 50) {
                $('header.wow').removeAttr('style');
                $('.idea.wow').removeAttr('style');
            }
        });
    }

    getItemNameByType(t: string) {
        switch (t) {
            case 'document':
                return 'Termo de Frota';
                break;
            case 'motorcycle':
                return 'Moto';
                break;
            case 'front-wall':
                return 'Fachada';
                break;
            case 'light-truck':
                return 'Caminhão Leve';
                break;
            case 'tricycle':
                return 'Triciclo';
                break;
            case 'truck':
                return 'Truck Transferência';
                break;
            case 'pickup':
                return 'Pickup';
                break;
            case 'transfer-truck':
                return 'Carreta Transferência';
                break;
            case 'vuc':
                return 'VUC';
                break;
        }
    }

    elementInViewport(el) {
        var top = el.offsetTop;
        var left = el.offsetLeft;
        var width = el.offsetWidth;
        var height = el.offsetHeight;
        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }
        return (
            top < (window.pageYOffset + (window.innerHeight + (window.innerHeight / 2))) &&
            left < (window.pageXOffset + window.innerWidth) &&
            (top + height) > window.pageYOffset &&
            (left + width) > window.pageXOffset
        );
    }

    handlerRoute(profile: string) {
        const a = ['EC', 'MA', 'GVE']
        if (a.includes(profile)) {
            return '/revendas';
        }
        return '/cadastro'
    }

    getResponsibleStandardization(r: string) {
        let x = 'Não enviado';
        switch (r) {
            case 'resale':
                x = 'Revenda';
                break;
            case 'provider':
                x = 'Copa Energia';
                break;
            case 'dealing':
                x = 'Em negociação';
                break;
        }
        return x;
    }

    getStatusBarColors(status) {
        let x = status;
        if (status === 'received') {
            x = 'analysis';
        }
        return x;
    }
}
