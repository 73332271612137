import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {
    ConsultantStandart, CreateAdhesionGQL,
    CreateStandartItemGQL,
    CreateStandartItemInput,
    CreateStandartItemsGQL,
    CurrentUserGQL,
    DeleteImageGQL, EvalDocumentUserSupplierGQL, RemoveDocumentGQL,
    RemoveStandartItemGQL,
    SearchDocumentsGQL, SearchDocumentsQuery,
    SearchResaleGQL,
    SearchResaleQuery,
    StandartAdhesionGQL,
    StandartAdhesionQuery,
    StandartItemsTypeGQL,
    StandartItemsTypeQuery,
    StandartItemTypeDto,
    StandartStandardizationGQL,
    StandartStandardizationQuery,
    StandartStandardizationQueryVariables,
    TransmitStandartGQL,
    UpdateResaleTypeGQL,
    UpdateResponsibleGQL,
    UpdateStandartAdhesionGQL,
    UpdateStandartInput,
    User, UserDocument,
    UserStandart,
    UserStandartComplement,
    UserStandartItemImage,
    UserStandartWall
} from "../../../generated/graphql";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "../../service/user.service";
import {QueryRef} from "apollo-angular";
import {map} from "rxjs/operators";
import {NgxSpinnerService} from "ngx-spinner";

declare var $;

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent extends BaseComponent implements OnInit, OnDestroy {

    paramId = null;
    tabCurrent = 'dimension';
    user: User = new User();
    userResale: any = {id: '', type: '', isFleet: undefined};
    userStandart: UserStandart = new UserStandart();
    userStandartQuery: QueryRef<StandartAdhesionQuery>;
    userStandartFleet: any = [];
    userStandartFleetQuery: QueryRef<StandartItemsTypeQuery>;
    userStandartFleetInput: CreateStandartItemInput = new CreateStandartItemInput();
    standartStandardizationQuery: QueryRef<StandartStandardizationQuery>;

    searchResaleQuery: QueryRef<SearchResaleQuery>;
    searchResaleResult: ConsultantStandart = new ConsultantStandart();

    flagFleet = false;
    countFleet = 0;

    modelResponsible = '';

    flagIsFirstViewType = false;
    flagViewType = 'adhesion';
    flagEdit = false;
    flagEditFleet = false;
    flagTransmit = false;
    flagVideo = false;
    frontSignActive = null;
    isProfileDebranding = false;

    //Modal bottom
    responseFleet = false;
    statusAdhesionIcon;
    statusStandardizationIcon;

    file: File = null;
    fileTerms: any[];
    thumpPath: string | ArrayBuffer;
    croppedImage: any = null;
    currentUpload = null;
    documents: UserDocument[] = [];
    documentsProject: UserDocument[] = [];
    documentsProjectInput: any = {justify: '', id: '', status: ''};
    searchDocumentsQuery: QueryRef<SearchDocumentsQuery>;
    searchDocumentsProjectQuery: QueryRef<SearchDocumentsQuery>;

    constructor(public router: Router,
                private router2: ActivatedRoute,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                private currentUserGQL: CurrentUserGQL,
                public userService: UserService,
                private standartAdhesionGQL: StandartAdhesionGQL,
                private searchResaleGQL: SearchResaleGQL,
                private standartItemsTypeGQL: StandartItemsTypeGQL,
                private standartStandardizationGQL: StandartStandardizationGQL,
                private updateResaleTypeGQL: UpdateResaleTypeGQL,
                private transmitStandartGQL: TransmitStandartGQL,
                private deleteImageGQL: DeleteImageGQL,
                private removeStandartItemGQL: RemoveStandartItemGQL,
                private updateStandartAdhesionGQL: UpdateStandartAdhesionGQL,
                private updateResponsibleGQL: UpdateResponsibleGQL,
                private createStandartItemGQL: CreateStandartItemGQL,
                private createStandartItemsGQL: CreateStandartItemsGQL,
                private searchDocumentsGQL: SearchDocumentsGQL,
                private createAdhesionGQL: CreateAdhesionGQL,
                private evalDocumentUserSupplierGQL: EvalDocumentUserSupplierGQL,
                private removeDocumentGQL: RemoveDocumentGQL,
          
    ) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.router2.params.subscribe(data => {
            if (!this.isNullOrUndefined(data?.id)) {
                this.paramId = data.id;
            }
            $('.header').removeClass('bg-gradient-primary-secondary');
            $('body').addClass('bg-gradient-primary-secondary');
            this.resetPage();
            this.userService.user.subscribe({
                next: data => {
                    if (!this.isNullOrUndefined(data?.id)) {
                        this.user = data as User;
                       
                        this.userStandartQuery = this.standartAdhesionGQL.watch({input: this.isNullOrUndefined(this.paramId) ? this.user?.id : this.paramId});
                        this.standartStandardizationQuery = this.standartStandardizationGQL.watch({input: this.isNullOrUndefined(this.paramId) ? this.user?.id : this.paramId});
                        this.searchResaleQuery = this.searchResaleGQL.watch({input: this.isNullOrUndefined(this.paramId) ? this.user?.id : this.paramId});
                        this.searchResale();
                        this.searchDocument();
                        this.searchDocumentProject();
                    }
                }, error: err => super.onError(err)
            });
            this.userService.chat.subscribe({
                next: data => {
                    this.flagViewType = data.name;
                    // console.log('chat message no register');
                    // console.log(data);
                }, error: err => super.onError(err)
            });
        });

    }

    ngOnDestroy(): void {
        const el = $('#modalRegisterType');
        const ela = $('#modalCadastroNumero');
        const elb = $('#modalAudit');
        const elc = $('#resaleType');
        const eld = $('#responseFleet');
        const ele = $('#addFleet');
        if (el[0]) {
            el.remove();
        }
        if (ela[0]) {
            ela.remove();
        }
        if (elb[0]) {
            elb.remove();
        }
        if (elc[0]) {
            elc.remove();
        }
        if (eld[0]) {
            eld.remove();
        }
        if (ele[0]) {
            ele.remove();
        }
    }

    searchResale() {
        this.searchResaleQuery.valueChanges.subscribe(({data}) => {
            this.searchResaleResult = data.searchResale as ConsultantStandart;
            this.statusAdhesionIcon = this.handlerIcon(this.searchResaleResult.statusAdhesion);
            this.statusStandardizationIcon = this.handlerIcon(this.searchResaleResult.statusStandardization);
            this.flagViewType = this.searchResaleResult.processStep;
            this.flagFleet = this.searchResaleResult.isFleet;
            this.userResale.type = this.searchResaleResult.type;
            this.userResale.isFleet = this.searchResaleResult.isFleet;

        

            this.isProfileDebranding = (this.searchResaleResult.resaleType == "Revenda" || this.searchResaleResult.resaleType == "Sub-Rede" || this.searchResaleResult.resaleType == null) && this.user.profile !== "MA";
            console.log('[DEBRANDING]');
            console.log(this.isProfileDebranding);


            if (this.isNullOrUndefined(this.searchResaleResult?.type)) {
                this.showModal('resaleType');
                // this.showModal('modalRegisterTypeFinish');
                return;
            }

            this.getResaleStandart();
        });
    }

    // RESET
    resetPage() {
        this.userStandart = new UserStandart();
        this.file = null;
        this.thumpPath = null;
        this.croppedImage = null;
        this.currentUpload = null;
    }

    // GET
    getResaleStandart() {
        // console.log('getResaleStandart 1');
        if (this.flagViewType === 'standardization') {
            this.standartStandardizationQuery.valueChanges.subscribe(({data}) => {
                this.userStandart = JSON.parse(JSON.stringify(data.standartStandardization)); // data.standartStandardization as UserStandart;
                this.userStandartFleetQuery = this.standartItemsTypeGQL.watch({input: this.userStandart?.id});
                if (this.searchResaleResult.isFleet) {
                    this.getFleet();
                }
                this.handlerStandartEdit();
                this.tabCurrent = 'standardization';
                this.userService.updateChat({id: this.userStandart.id, name: 'standardization'});
            }, error => super.onError(error));
        } else {
            this.userStandartQuery.valueChanges.subscribe(({data}) => {
                this.userStandart = JSON.parse(JSON.stringify(data.standartAdhesion));
                this.userStandartFleetQuery = this.standartItemsTypeGQL.watch({input: this.userStandart?.id});
                if (this.searchResaleResult.isFleet) {
                    this.getFleet();
                }
                /*if (!this.flagIsFirstViewType && this.userStandart.status === 'approved') {
                    this.flagIsFirstViewType = true;
                    this.onOpenRegister('standardization');
                    return;
                }*/
                this.userService.updateChat({id: this.userStandart.id, name: 'adhesion'});
                this.handlerStandartEdit();
                this.tabCurrent = 'adhesion';
                /*if (this.user?.type === 'gas' && ((!this.user?.hasCage && !this.user?.hasFrontSign) || (!this.user?.hasCage && this.user?.hasFrontSign))) {
                    this.tabCurrent = 'photo';
                }*/
                if (this.userStandart.type === 'adhesion' && this.userStandart.status === 'pending') {
                    this.showModal('modalRegisterTypeFinish');
                }
                /*else {
                    this.tabCurrent = 'dimension';
                }*/
            });
        }
    }

    getFleet() {
        this.userStandartFleetQuery.valueChanges.subscribe(({data}) => {
            this.userStandartFleet = data.standartItemsType.sort((a: any, b: any) => {
                return a.type.order - b.type.order;
            });
            this.countFleet = this.userStandartFleet.reduce((a, b) => +a + +b.count, 0);
        });
    }

    getStatusEditView(w: any) {
        return w.status === 'pending' || w.status === 'review';
    }

    isResaleHasFleetEnable() {
        if(this.isProfileDebranding) return true;
        return (!this.flagEditFleet && this.userStandart.type === 'adhesion') || (this.userStandart.type !== 'adhesion')
    }

    isEnableTerm(){
        if(this.isProfileDebranding) return false;
        return this.getStatusUserStandardFleet() !== 'received' && (this.documents.length === 0 || this.documents[this.documents.length - 1]?.status == 'pending' || this.documents[this.documents.length - 1]?.status == 'review');
    }

    getAbc(num: number) {
        let s = '', t;
        while (num > 0) {
            t = (num - 1) % 26;
            s = String.fromCharCode(65 + t) + s;
            num = (num - t) / 26 | 0;
        }
        return s || undefined;
    }

    getBadgeClassByState(status: string) {
        switch (status) {
            case 'received':
                return 'bg-warning text-primary';
            case 'approved':
                return 'bg-white text-primary';
            case 'reproved':
                return 'bg-danger text-white';
            case 'review':
                return 'bg-tertiary text-white';
            default :
                return 'bg-tertiary text-white';
        }
    }

    getCurrentUser(): void {
        this.currentUserGQL.watch().valueChanges.subscribe(({data}) => {
            const a: any = data;
            this.userService.updateUser(a.currentUser);
            if (this.userStandart.type === 'adhesion') {
                this.showModal('modalCadastroNumero');
            } else {
                this.showModal('modalAudit');
            }
            this.refetchAll();
        });
    }

    // HANDLER
    handlerStandartEdit(): void {
        this.flagEdit = (this.getStatusUserStandardFront() === 'pending' || this.getStatusUserStandardFront() === 'review');
        this.flagEditFleet = (this.getStatusUserStandardFleet() === 'pending' || this.getStatusUserStandardFleet() === 'review');
    }

    // CROP
    setCroppedImage(o: any) {
        this.croppedImage = o.thumb;
        this.handleUpload(o.file);
    }

    actionUploadImage(p: UserStandartItemImage, type: string) {
        if ((type === 'front' && !this.flagEdit) || (type === 'fleet' && !this.flagEditFleet)) {
            return;
        }
        this.currentUpload = p;
        this.showModal('modalCropPhoto');
    }

    handleUpload(event) {
        const files = event;
        if (files.length > 0) {
            if (files[0].size > 2000000) {
                super.showMessage(this.translate.instant('profile.edit.warningAttention'),
                    this.translate.instant('profile.edit.warningUpload'), 'info');
                return;
            }
            if (files[0].type.toLowerCase().indexOf('jpeg') === -1
                && files[0].type.toLowerCase().indexOf('jpg') === -1
                && files[0].type.toLowerCase().indexOf('png') === -1) {
                super.showMessage('Atenção', 'É permitido apenas enviar arquivos com extensões .jpg ou .png',
                    'warning');
                return;
            }
        }
        const reader = new FileReader();
        reader.onload = (_event) => {
            this.thumpPath = _event.target.result;
        };
        reader.readAsDataURL(event[0]);
        this.file = files[0];
        this.uploadImage();
    }

    uploadImage() {
        this.userService.uploadImage(this.file, this.userStandart.id, this.currentUpload.id).subscribe(
            data => {
                setTimeout(() => {
                    if (this.userStandart.type === 'standardization') {
                        this.standartStandardizationQuery.refetch();
                    } else this.userStandartQuery.refetch();

                }, 300);
            }, error => super.onError(error));
    }

    actionSend(t: string): void {
        if (t === 'fleet' && (this.flagFleet && (this.userStandart.type === 'adhesion' && this.userStandart!.item.length === 1))) {
            this.showMessage('Atenção', `Você não inseriu as fotos de sua frota. Caso não possua frota, desabilite a informação “Sua revenda possui frota?” antes de continuar.`);
            return
        } else {
            let a = 0;
            let f = 0;
            this.userStandart.item.map((x) => {
                const c = x.images.filter(img => this.isNullOrUndefined(img.url));
                if (c.length === x.images.length) {
                    if (t === 'front' && x.type === 'front-wall') {
                        f++;
                    } else if (t !== 'front' && x.type !== 'front-wall') {
                        a++;
                    }
                }
            });
            if (f > 0 && t === 'front') {
                this.showMessage('Atenção', `Você não inseriu a foto de fachada, é necessário inserir ao menos 1 foto de fachada para prosseguir.`);
                return
            }
            if (a > 0 && t === 'fleet') {
                this.showMessage('Atenção', `Para prosseguir é necessário inserir ao menos uma foto em cada veículo cadastrado.`);
                return
            }
        }

        if (t === 'fleet' && (this.flagFleet && this.documents.length === 0 && this.userStandart.type === 'standardization')) {
            this.showMessage('Atenção', `Você não enviou o "Termo de Frota", faça o download do termo, imprima e envie uma foto com as informações preenchidas.`);
            return;
        }

        const msg = t === 'front' ? 'Tem certeza que deseja transmitir as fotos da fachada para análise?' : 'Tem certeza que deseja transmitir as fotos da frota para análise?';

        super.confirmMessage('Atenção', msg, () => {
            this.loading.show();
            this.flagTransmit = true;
            this.onSend(t);
        });
    }

    actionSaveResaleType(p: boolean): void {
        const arrValidateFields: any = [
            {value: this.userResale.type, text: `Tipo da Revenda <br>`}
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção',
                `Preencha os campos obrigatórios corretamente: <br><br> ${stringError}`);
            return;
        }
        this.userResale.isFleet = p;
        this.onCreateAdhesionConsult();
    }


    onCreateAdhesionConsult() {
        super.confirmMessage('Atenção', 'Tem certeza que deseja iniciar a adesão?', () => {
            this.toggleLoader(true);
            this.closeModal('resaleType');
            this.createAdhesion();
        });
    }

    createAdhesion() {
        const idCurrent = this.user.profile === 'EC' || this.user.profile === 'GVE' || this.user.profile === 'MA' ? this.paramId : this.user.id;
        this.createAdhesionGQL.mutate({input: idCurrent, inputType: this.userResale.type, inputFleet: this.userResale.isFleet}).subscribe(
            (data) => {
                this.router.navigate(['/cadastro/' + idCurrent]);
                setTimeout(() => {
                    this.toggleLoader(false);
                }, 2000);
            }, err => super.onError(err)
        );
    }

    onSend(t: string): void {
        this.flagTransmit = false;
        this.loading.show();
        this.transmitStandartGQL.mutate({input: this.userStandart.id, type: t}).subscribe(
            (data) => {
                if (t === 'front') {
                    this.flagEdit = false;
                } else {
                    this.flagEditFleet = false;
                }
                setTimeout(() => {
                    this.loading.hide();
                    this.getCurrentUser();
                    // this.refetchAll();
                }, 1200);
            }, err => super.onError(err)
        );
    }


    actionRemovePhoto(p: UserStandartItemImage) {
        super.confirmMessage('Atenção', 'Deseja realmente remover a foto?', () => {
            this.onRemovePhoto(p.id);
        });
    }

    onRemovePhoto(id: string) {
        this.deleteImageGQL.mutate({input: id}).subscribe(
            (data) => {
                this.userStandartQuery.refetch();
            }, err => super.onError(err)
        );
    }

    onFleetToggle() {
        let flagForce = false;
        const msgActive = 'Tem certeza que deseja habilitar frota?';
        let msgInactive = 'Ao desabilitar a frota todos os veículos serão removidos. Tem certeza que deseja prosseguir?';
        if (!this.flagFleet && this.userStandart.type === 'adhesion' && this.userStandart.frontStatusAdhesion === 'approved') {
            msgInactive = 'Sua Fachada está aprovada, ao desabilitar a frota todos os veículos serão removidos e seguirá para o <strong>Passo 2 - Padronização</strong> sem frota. Tem certeza que deseja prosseguir?';
            flagForce = true;
        }
        super.confirmMessage('Atenção', this.flagFleet ? msgActive : msgInactive, () => {
            this.loading.show();
            const a: UpdateStandartInput = new UpdateStandartInput();
            a.id = this.userStandart?.id;
            a.isFleet = this.flagFleet;
            a.isForce = flagForce;
            this.updateStandartAdhesionGQL.mutate({input: a}).subscribe(
                (data) => {
                    this.userService.getMe();
                    if (!this.flagFleet) {
                        this.refetchAll();
                    }
                    this.loading.hide();
                }, err => super.onError(err)
            );
        }, () => {
            this.flagFleet = this.searchResaleResult.isFleet;
        });
    }

    actionRemoveFleetItem(x: string) {
        super.confirmMessage('Atenção', 'Deseja realmente remover o item?', () => {
            this.onRemoveFleetItem(x);
        });
    }

    onRemoveFleetItem(x: string) {
        this.removeStandartItemGQL.mutate({input: x}).subscribe(
            (data) => {
                this.refetchAll();
            }, err => super.onError(err)
        );
    }

    onChangeTab(p: string) {
        const s = this.searchResaleResult;
        if (p === 'standardization' && (s.processStep === 'adhesion' && this.isNullOrUndefined(s.statusStandardization))) {
            return;
        }
        this.flagViewType = p;
        this.getResaleStandart();
        this.tabCurrent = p;
    }

    actionUpdateResaleType() {
        super.confirmMessage('Atenção', 'Tem certeza que deseja alterar o tipo da revenda?', () => {
            this.setUpdateResaleType();
        });
    }

    setUpdateResaleType() {
        // this.onSaveResaleType();
        this.updateResaleTypeGQL.mutate({param: this.userResale.type, input: this.searchResaleResult?.id}).subscribe(
            (data) => {
                this.closeModal('resaleType');
                this.refetchAll();
            }, err => super.onError(err)
        );
    }

    onUpdateResponsible() {
        if (this.isNullOrUndefined(this.modelResponsible)) {
            this.showMessage('Atenção!', 'Selecione um responsável para prosseguir.', 'warning');
            return;
        }
        super.confirmMessage('Atenção', 'Tem certeza que deseja alterar o responsável pela padronização?', () => {
            this.setUpdateResponsible();
        });
    }

    setUpdateResponsible() {
        this.updateResponsibleGQL.mutate({
            respons: this.modelResponsible,
            input: this.searchResaleResult?.id
        }).subscribe(
            (data) => {
                this.closeModal('responseFleet');
                this.refetchAll();
            }, err => super.onError(err)
        );
    }

    refetchAll() {
        this.searchResaleQuery.refetch({input: this.isNullOrUndefined(this.paramId) ? this.user?.id : this.paramId});
        if (this.userStandart.type === 'adhesion') {
            this.userStandartQuery.refetch();
        } else {
            this.standartStandardizationQuery.refetch();
        }
        this.userStandartFleetQuery.refetch({input: this.userStandart?.id});
        this.searchDocumentsQuery.refetch({
            id: this.isNullOrUndefined(this.paramId) ? this.user?.id : this.paramId,
            type: 'certificate'
        }).then();
        this.searchDocumentsProjectQuery.refetch({
            id: this.isNullOrUndefined(this.paramId) ? this.user?.id : this.paramId,
            type: 'project'
        }).then();
    }

    handlerIcon(statusAdhesion: string) {
        switch (statusAdhesion) {
            case 'received':
                return 'assets/svg/hourglass-filter.svg';
            case 'pending':
                return 'assets/svg/pending-filter.svg';
            case 'review':
                return 'assets/svg/history-filter.svg';
            case 'approved':
                return 'assets/svg/check-filter.svg';
            default:
                return null;
        }
    }

    onChangeCount(x: any, b: boolean) {
        x.countAdd = this.isNullOrUndefined(x.countAdd) ? 1 : b ? x.countAdd >= 30 ? 30 : x.countAdd + 1 : x.countAdd === 0 ? 0 : x.countAdd - 1;
    }

    onSendFleet() {
        this.toggleLoader(true);
        this.closeModal('addFleet');
        const a = [];
        this.userStandartFleet.map((x) => {
            a.push({type: x.type?.type, qty: this.isNullOrUndefined(x.countAdd) ? 0 : x.countAdd});
        });
        this.createStandartItemsGQL.mutate({input: a, id: this.userStandart.id}).subscribe(
            (data) => {
                this.refetchAll();
                this.toggleLoader(false);
            }, err => super.onError(err)
        );
    }

    uploadTerms(event: any) {
        this.fileTerms = event.target.files;
        if (this.documents.length >= 1) {
            console.log(this.documents);
            this.documents.forEach((e) => {
                this.removeDocumentGQL.mutate({id: e.id}).subscribe();
            });
        }
        this.userService.uploadFile(this.fileTerms[0], 'user-document', `${this.isNullOrUndefined(this.paramId) ? this.user?.id : this.paramId}/certificate`).subscribe(({data}) => {
            this.fileTerms = [];
            this.refetchAll();
            this.showMessage('Sucesso!', 'Termo de frota enviado com sucesso!', 'success');
        }, error => super.onError(error))
    }

    searchDocument() {
        this.searchDocumentsQuery = this.searchDocumentsGQL.watch({
            id: this.isNullOrUndefined(this.paramId) ? this.user?.id : this.paramId,
            type: 'certificate'
        });
        this.searchDocumentsQuery.valueChanges.subscribe(({data}) => {
            this.documents = data.searchDocuments as UserDocument[];
        }, error => super.onError(error));
    }

    searchDocumentProject() {
        this.searchDocumentsProjectQuery = this.searchDocumentsGQL.watch({
            id: this.isNullOrUndefined(this.paramId) ? this.user?.id : this.paramId,
            type: 'project'
        });
        this.searchDocumentsProjectQuery.valueChanges.subscribe(({data}) => {
            this.documentsProject = data.searchDocuments as UserDocument[];
        }, error => super.onError(error));
    }

    getStatusUserStandardFront() {
        return this.userStandart?.type === 'adhesion' ? this.searchResaleResult.frontStatusAdhesion : this.searchResaleResult.frontStatusStandardization;
    }

    getStatusUserStandardFleet() {
        return this.userStandart?.type === 'adhesion' ? this.searchResaleResult.fleetStatusAdhesion : this.searchResaleResult.fleetStatusStandardization;
    }

    onEvalProject(status: string) {
        this.documentsProjectInput.id = this.documentsProject[this.documentsProject.length - 1]?.id;
        this.documentsProjectInput.status = status;
        if (status === 'reproved') {
            this.showModal('projectJustify');
        } else {
            super.confirmMessage('Atenção', 'Tem certeza que deseja aprovar o projeto?', () => {
                this.onEvalProjectSend();
            });
        }
    }

    onEvalProjectSend() {
        if (this.documentsProjectInput.status === 'reproved' && this.isNullOrUndefined(this.documentsProjectInput.justify)) {
            this.showMessage('Atenção', `Informe a justificativa para a recusa do projeto.`);
            return;
        }
        this.evalDocumentUserSupplierGQL.mutate(this.documentsProjectInput).subscribe(
            (data) => {
                if (this.documentsProjectInput.status === 'reproved') {
                    this.closeModal('projectJustify');
                }
                this.refetchAll();
                this.toggleLoader(false);
            }, err => this.onError(err)
        );
    }
}
