import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {base64ToFile, ImageCroppedEvent} from 'ngx-image-cropper';
import {NgxSpinnerService} from "ngx-spinner";

declare var $: any;

@Component({
  selector: 'app-crop-image',
  templateUrl: './crop-image.component.html',
  styleUrls: ['./crop-image.component.scss']
})
export class CropImageComponent extends BaseComponent implements OnInit, OnDestroy {

  @Output() croppedImageResult = new EventEmitter<any>();
  @Input() cropAspectRatio: any = 4 / 3;
  @Input() cropResizeToWidth: any = 400;
  @Input() cropResizeToHeight: any = 300;
  @Input() cropId: any = 'modalCrop';
  @Input() maintainAspectRatio = true;

  cropCanvasRotation = 0;
  croppedImage: any;
  imageChangedEvent: any;
  croppedFile: any;

  constructor(public router: Router,
              public loading: NgxSpinnerService,
              public translate: TranslateService) {
    super(router,loading, translate);
  }

  ngOnInit(): void {
    $(() => {
      const el = $('#' + this.cropId);
      el.on('shown.bs.modal', () => {
        el.find('.description__photo--file').click();
      });
    });
  }

  ngOnDestroy(): void {
    const elModalUser = $('#' + this.cropId);
    if (elModalUser[0]) {
      elModalUser.remove();
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    const file = new File([base64ToFile(event.base64)],
        this.imageChangedEvent.target.files[0].name,
        {
          lastModified: this.imageChangedEvent.target.files[0].lastModified,
          type: this.imageChangedEvent.target.files[0].type
        }
    );
    this.croppedFile = [file];
  }

  fileChangeEvent(event: any): void {
    const files = event.target.files;
    if (files.length > 0) {
      /*if (files[0].type.toLowerCase().indexOf('jpg') === -1
          && files[0].type.toLowerCase().indexOf('png') === -1
          && files[0].type.toLowerCase().indexOf('jpeg') === -1) {
        super.showMessage('Atenção', 'É possível enviar apenas imagens com extensões .png ou .jpg', 'info');
        return;
      }*/
      if (!['jpg', 'png', 'jpeg'].includes(files[0].type.toLowerCase().split('/').pop())) {
        super.showMessage('Atenção', 'É possível enviar apenas imagens com extensões .png ou .jpg', 'info');
        return;
      }
    }
    this.imageChangedEvent = event;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  onReset(): void {
    this.cropCanvasRotation = 0;
    this.croppedImage = null;
    this.imageChangedEvent = null;
    this.croppedFile = null;
  }

  onImageRotate(): void {
    this.cropCanvasRotation = this.cropCanvasRotation === 4 ? 0 : (this.cropCanvasRotation + 1);
  }

  onImageSave(): void {
    const o = {thumb: this.croppedImage, file: this.croppedFile};
    this.croppedImageResult.emit(o);
    setTimeout(() => {
      this.onReset();
      this.closeModal(this.cropId);
    }, 500);
  }
}

