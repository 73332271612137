<div class="coupon">
    <div class="padding_header">
        <div class="coupon_container mb-4 container">
            <h1 class="coupon_title">Cupons</h1>
        </div>
        <div class="bg-primary py-4 padro_row">
            <div class="coupon_container container">
                <div class="row align-items-start my-5 my-lg-3" style="row-gap: 30px">
                    <div class="col-xl-4">
                        <div class="d-flex align-items-center mb-3" style="gap: 23px">
                            <svg-icon src="assets/svg/icon-title.svg"></svg-icon>
                            <h2 class="coupon_title secondary">Padronização da Frota</h2>
                        </div>
                        <p class="coupon_text secondary">
                            Fique de olho na data para comprovar a padronização de sua frota e ganhe cupons para concorrer aos prêmios de acordo com sua classe:
                        </p>
                        <table class="prize-table mb-3">
                            <tr>
                                <th>CLASSE ANP</th>
                                <th class="text-center">Revendas Sorteas</th>
                                <th class="text-center">Valor em R$</th>
                            </tr>
                            <tr>
                                <td>Classe Especial</td>
                                <td class="text-center"> 5</td>
                                <td class="text-center"> 24.000</td>
                            </tr>
                            <tr>
                                <td>Classe VII</td>
                                <td class="text-center">5</td>
                                <td class="text-center"> 18.000</td>
                            </tr>
                            <tr>
                                <td>Classe VI</td>
                                <td class="text-center">5</td>
                                <td class="text-center"> 18.000</td>
                            </tr>
                            <tr>
                                <td>Classe V</td>
                                <td class="text-center">20</td>
                                <td class="text-center"> 12.000</td>
                            </tr>
                            <tr>
                                <td>Classe IV</td>
                                <td class="text-center">20</td>
                                <td class="text-center"> 8.000</td>
                            </tr>
                            <tr>
                                <td>Classe III</td>
                                <td class="text-center">80</td>
                                <td class="text-center"> 4.000</td>
                            </tr>
                            <tr>
                                <td>Classe II</td>
                                <td class="text-center">80</td>
                                <td class="text-center"> 2.000</td>
                            </tr>
                            <tr>
                                <td>Classe I</td>
                                <td class="text-center">80</td>
                                <td class="text-center"> 2.000</td>
                            </tr>
                        </table>
                        <span class="d-block bg-secondary text-white text-center mb-3" style="font-size: 15px">
                        <strong>30 de junho</strong> de 2023 ganha <strong>6 cupons</strong>
                    </span>
                        <span class="d-block bg-secondary text-white text-center" style="font-size: 15px">
                        <strong>31 de julho</strong> de 2023 ganha <strong>3 cupons</strong>
                    </span>
                    </div>
                    <div class="col-xl-8">
                        <div class="row mb-5">
                            <div class="col-12 col-md-6 coupon-chance">
                                <h3 class="coupon-chance-title">CHANCE EM DOBRO</h3>
                                <p class="coupon-chance-txt">Comprove a padronização de 100% da frota na cor verde e ganhe o dobro de cupons para concorrer ao sorteio.</p>
                            </div>
                            <div class="col-12 col-md-6 mb-5">
                                <img src="assets/images/{{user?.branding === 'Liquigás' ? 'img-coupon3' : 'img-coupon3-copagaz' }}.png" class="coupon-chance-prize" alt="cupon">
                            </div>
                        </div>
                        <div class="card coupon_card-secondary mt-4 p-3 bg-secondary text-white" style="line-height: 1.3;">
                            <div class="row">
                                <div class="col">
                                    <div class="row align-items-center h-100" style="row-gap: 30px">
                                        <div class="col-6 col-sm-4 text-center  d-flex flex-column justify-content-center height-100">
                                            <p class="coupon_card_percent text-primary mb-0">
                                                <img src="assets/images/ico-{{coupon.isStandardization ? 'check' : 'close'}}.png" alt="Padronizada" />
                                            </p>
                                            <p class="coupon_card_lbl mb-0 pt-1">{{!coupon.isStandardization ? 'Não' : ''}} padronizada</p>
                                        </div>
                                        <div class="col-6 col-sm-4 border-left border-right text-center d-flex flex-column justify-content-center height-100">
                                            <p class="coupon_card_lbl lbl-sm mb-0">Data da aprovação</p>
                                            <p class="coupon_card_lbl lbl-sm mb-0" *ngIf="isNullOrUndefined(coupon.dateApproved)">
                                                <small class="text-primary"><strong>Não aprovado</strong></small>
                                            </p>
                                            <p class="coupon_card_lbl lbl-sm mb-0" *ngIf="!isNullOrUndefined(coupon.dateApproved)">
                                                <span class="text-primary">{{coupon.dateApproved | date : 'MMMM'}}</span> de {{coupon.dateApproved | date : 'yyyy'}}
                                            </p>
                                        </div>
                                        <div class="col-6 col-sm-4 text-center border-left d-flex flex-column justify-content-center height-100">
                                            <p class="coupon_card_percent text-primary mb-0">
                                                <img src="assets/images/ico-{{this.typeGreen !== -1 ? 'check' : 'close'}}.png" alt="Padronizada" />
                                            </p>
                                            <p class="coupon_card_lbl mb-0 pt-1">{{this.typeGreen !== -1 ? 'Frota' : 'Frota não'}} padronizada
                                                <br> em verde</p>
                                        </div>
                                        <!--<div class="col-6 col-sm-2 d-flex flex-column align-items-center text-center  d-flex flex-column justify-content-center height-100">
                                            <p class="coupon_card_count_number mb-0">{{coupon.count}}</p>
                                            <p class="coupon_card_count_text mb-0">cupons</p>
                                        </div>-->
                                    </div>
                                </div>
                                <!--<div class="col-3 d-none d-xl-block">
                                    <img style="transform: scale(3.5);bottom: 76px;left: -112px;"
                                         class="position-absolute" src="assets/images/img-coupon2.png" alt="cupon">
                                </div>-->
                            </div>
                        </div>
                        <!--*ngIf="!isNullOrUndefined(coupon.coupons)"-->
                        <div class="row mx-n1 mt-5 align-items-center" *ngIf="false" style="row-gap: 20px">
                            <ng-container *ngFor="let c of coupon.coupons;let i = index">
                                <div class="px-1 col-6 col-sm-2">
                                    <div class="coupon_card blocked justify-content-around card">
                                        <div class="row no-gutters align-items-center w-100">
                                            <div class="col-auto">
                                                <img class="coupon_card_icon" src="assets/images/happy.png" alt="happy">
                                            </div>
                                            <div class="col border-left">
                                                <p class="coupon_card_name">{{c.coupon.slice(-4)}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
