<div class="login">
    <div class="login_col primary">
        <div class="d-flex justify-content-center">
            <!--<svg-icon svgClass="mb-4" src="assets/svg/logo.svg"></svg-icon>-->
            <img class="mb-4" src="assets/images/logo-copaenergia.png" alt="Copa Energia"/>
        </div>
        <div class="w-100">
            <p class="login_text secondary text-center">Login</p>
            <div class="input-material light">
                <input id="field1" class="form-control" name="name" type="text" [(ngModel)]="modelLogin.login"
                       required/>
                <label for="field1">CNPJ</label>
            </div>
            <div class="input-material light">
                <input id="password" class="form-control" name="name" type="password" [(ngModel)]="modelLogin.password"
                       (keyup.enter)="actionLogin()"
                       required/>
                <a href="javascript:void(0)" class="btn p-0 icon"
                   (click)="handlerViewPassword('password')"
                   style="position: absolute;top: 8px; right: 6px;">
                    <svg-icon
                            [src]="!handlerViewPasswordIcon('password') ? 'assets/svg/password-open.svg' : 'assets/svg/password-closed.svg'"></svg-icon>
                </a>
                <label for="password">Senha</label>
            </div>
            <p (click)="onForgotPassword()" class="login_text tertiary"> Esqueci
                minha senha</p>
            <a class="btn btn-block login_btn mb-4" href="javascript:void(0);"
               (click)="actionLogin()">Entrar</a>
        </div>
        <p class="login_text">
            Caso tenha dúvidas para se cadastrar ou acessar seu login, <a
                (click)="onModalContact()">clique
            aqui</a> ou envie uma mensagem para
            sac@juntosnogas.com.br ou
            whatsapp (11) 91782-4900.
        </p>
    </div>
    <div class="login_col secondary">
        <img class="login_img login-brand primary" src="assets/images/img-login-2.png" alt="img">
        <img class="login_img login-brand secondary" src="assets/images/img-login.png" alt="img">
        <img class="login_img login-brand quaternary" src="assets/images/logo.png" alt="img">
    </div>
</div>


<!--modal esqueci senha-->
<div class="modal-custom modal fade" id="modalPassword" data-keyboard="false"
     data-backdrop="static" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content modal_comment">
            <div class="row">

                <div class="col-12 col-lg-4">
                    <div class="modal_col-primary">
                        <a data-dismiss="modal" class="modal_close ml-auto d-lg-none" aria-label="Close">
                            <svg-icon [svgStyle]="{'fill':'#006F4D'}"
                                      src="assets/svg/close-circle.svg"></svg-icon>
                        </a>
                        <img src="assets/images/logocopagaz.png" style="width: 279px;" alt="logo">
                        <h2 class="modal_title text-white mb-3">Esqueci Minha Senha</h2>
                    </div>
                </div>

                <div class="col-12 col-lg-8">
                    <div class="d-flex flex-column justify-content-between h-100 p-4">
                        <a data-dismiss="modal" class="modal_close d-none d-lg-flex justify-content-end"
                           aria-label="Close">
                            <svg-icon src="assets/svg/close-circle.svg"></svg-icon>
                        </a>
                        <ng-container *ngIf="!successPassword">
                            <div class="px-lg-5">
                                <span class="text-white">Insira seu CNPJ para enviarmos o e-mail de recuperação de senha.</span>
                                <div class="input-material light">
                                    <input id="cnpjPassword" class="form-control" name="name" type="text"
                                           [(ngModel)]="forgotPassword"
                                           [mask]="'00.000.000/0000-00'"
                                           [dropSpecialCharacters]="false"
                                           required/>
                                    <label for="field1">CNPJ</label>
                                </div>
                            </div>
                            <div class="px-lg-5">
                                <a class="btn btn-block btn-white mb-4 " href="javascript:void(0);"
                                   (click)="actionFgtPassword()">Enviar</a>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="successPassword">
                            <div class="px-lg-5">
                                <p class="text-white">Enviamos sua senha para</p>
                                <span class="text-quaternary">{{fgtPasswordResponse}}</span>
                            </div>
                            <div class="px-lg-5">
                                <a class="btn btn-block btn-white mb-4" data-dismiss="modal" aria-label="Close"
                                   href="javascript:void(0);">
                                    Voltar para o login</a>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-contact [createContactInput]="createContactInput"></app-contact>
