import {AfterViewInit, Component, ViewChild, OnInit, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import {
    Banner,
    ClickBannerGQL,
    HomeBannersGQL, SearchWinnerInput,
    User,
    UserCouponGQL,
    UserCouponObject, WinnerPage
} from "../../../generated/graphql";
import {QueryRef} from "apollo-angular";
declare var $: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

    modelPaginator: WinnerPage = new WinnerPage();
    // modelQuery: QueryRef<SearchWinnerQuery>;
    filter: SearchWinnerInput = new SearchWinnerInput();
    user: User = new User();

    tableData: any[] = [];
    tableData2: any[] = [];
    banners: Banner[] = [];
    winnersWorld = [
        {
            cod: '273146',
            name: 'BORGES & DUARTE COM. E DISTRIBUIÇÃO',
            city: 'BELÉM/PA',
        },
        {
            cod: '9713',
            name: 'MARDEM FERREIRA SOUSA',
            city: 'DOM PEDRO/MA',
        },
        {
            cod: '269212',
            name: 'COMERCIO DE GAS PRM LTDA',
            city: 'MONTENEGRO/RS',
        },
        {
            cod: '44376',
            name: 'PAPAGAS ABC COMERCIO DE GAS LT ME',
            city: 'SÃO BERNARDO DO CAMPO/SP',
        },
        {
            cod: '280120',
            name: 'CENTRAL GAUCHA DE GAS COMERCIO DE G',
            city: 'CAXIAS DO SUL/RS',
        },
        {
            cod: '88242',
            name: 'KILOGAS LTDA',
            city: 'MANGA/MG',
        }
    ];

    slickPrimary = {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        infinite: true,
        // autoplay: true,
        autoplaySpeed: 8000,
    };

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public userService: UserService,
                private homeBannersGQL: HomeBannersGQL,
                private clickBannerGQL: ClickBannerGQL,
                public http: HttpClient,
                // private searchWinnerGQL: SearchWinnerGQL,
                public translate: TranslateService) {
        super(router, loading, translate);
    }

    ngOnInit() {
        this.http.get<any[]>('/assets/winners.json').subscribe((data) => {
            this.tableData = data;
        });
        this.http.get<any[]>('/assets/sorteio.json').subscribe((data) => {
            this.tableData2 = data;
        });
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data?.id)) {
                    this.user = data as User;
                }
                $('#sidebar                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        ').addClass('is-hide');
                window.addEventListener("scroll", () => {
                    const distance = window.scrollY;
                    $('.js-parallax').each((i, x) => {
                        if (super.elementInViewport(x)) {
                            const e = $(x);
                            e.css({transform: 'translateY(' + (distance * e.attr('data-speed')) * -1 + 'px)'});
                        }
                    });
                });
            }, error: err => super.onError(err)
        });

    }

    ngAfterViewInit() {
        // this.modelQuery = this.searchWinnerGQL.watch({input: this.filter});
        // this.modelQuery.valueChanges.subscribe(({data}) => {
        //     this.modelPaginator = data.searchWinner as WinnerPage;
        // });

        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data?.id)) {
                    // $('#modalPrazoEncerrado').modal('show');
                }
            }
        });

        // $('.slick-primary').slick(this.slickPrimary);

        this.homeBannersGQL.watch().valueChanges.subscribe(({data}) => {
            this.banners = data.homeBanners as Banner[];
            setTimeout(() => {
                $('.slick-primary').slick(this.slickPrimary);
            }, 500);
        });

    }

    ngOnDestroy(): void {
        $('#sidebar').removeClass('is-hide');
        const a = $('#modalPrazoEncerrado');
        if (a[0]) {
            a.remove();
        }
    }

    actionClickBanner(o: any): void {
        this.clickBannerGQL.mutate({input: o.id}).subscribe(({data}) => {
            if (o.isExternaLink) {
                window.open(o.buttonLink);
            } else {
                window.location.href = o.buttonLink;
            }
        }, error => super.onError(error));
    }

    actionSearch() {
        const e = $('.js-table-winners');
        const v = $('.js-filter-winners').val().toLowerCase();
        e.find('tr').each((i, x) => {
            const j = $(x);
            if (j.text().toLowerCase().indexOf(v) < 0 && !this.isNullOrUndefined(v)) {
                j.addClass('d-none');
            } else {
                j.removeClass('d-none');
            }
        });
        /*this.loading.show();
        this.modelQuery.refetch({input: this.filter}).finally(() => {
            this.loading.hide();
        });*/
    }

    actionSearch2() {
        const e = $('.js-table-sorteio');
        const v = $('.js-filter-sorteio').val().toLowerCase();
        e.find('tr').each((i, x) => {
            const j = $(x);
            if (j.text().toLowerCase().indexOf(v) < 0 && !this.isNullOrUndefined(v)) {
                j.addClass('d-none');
            } else {
                j.removeClass('d-none');
            }
        });
        /*this.loading.show();
        this.modelQuery.refetch({input: this.filter}).finally(() => {
            this.loading.hide();
        });*/
    }

    pageChanged(event) {
        this.filter.page = event;
        this.actionSearch();
    }
   

}



