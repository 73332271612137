import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {NavigationEnd, Router} from "@angular/router";
import {UserService} from "../../service/user.service";
import {TranslateService} from "@ngx-translate/core";
import {NgxSpinnerService} from "ngx-spinner";
import {
    CreateContactInput,
    ReplyStandartMessageGQL,
    ReplyStandartMessageInput,
    StandartAdhesionGQL,
    StandartAdhesionQuery,
    StandartAdhesionQueryVariables,
    StandartMessage,
    StandartMessagesGQL,
    StandartMessagesQuery,
    StandartMessagesQueryVariables,
    User,
    UserStandart
} from "../../../generated/graphql";
import {QueryRef} from "apollo-angular";

declare var $;

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends BaseComponent implements OnInit {
    @ViewChild('testDiv', {static: false}) private testDiv: ElementRef<HTMLDivElement>;
    isTestDivScrolledIntoView: boolean;
    user: User;
    userStandartQuery: QueryRef<StandartAdhesionQuery, StandartAdhesionQueryVariables>;
    standartMessage: StandartMessage[] = [];
    modalMessage: StandartMessage = new StandartMessage();
    textResponse: string;
    parmId: string;
    standartMessageQuery: QueryRef<StandartMessagesQuery, StandartMessagesQueryVariables>;

    modelChat: any = {};
    flagShowChat = false;

    @HostListener('window:scroll', ['$event'])
    isScrolledIntoView() {
        if (this.testDiv) {
            const rect = this.testDiv.nativeElement.getBoundingClientRect();
            const topShown = rect.top >= 0;
            const bottomShown = rect.bottom <= window.innerHeight;
            this.isTestDivScrolledIntoView = topShown && bottomShown;
            // console.log(this.isTestDivScrolledIntoView);
        }
    }

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                private standartAdhesionGQL: StandartAdhesionGQL,
                private standartMessagesGQL: StandartMessagesGQL,
                private replyStandartMessageGQL: ReplyStandartMessageGQL,
                public userService: UserService,
                public translate: TranslateService) {
        super(router, loading, translate);
        router.events.forEach((event) => {
            if(event instanceof NavigationEnd) {
                // console.log('trocou rota');
                // console.log(event.url.indexOf('/cadastro'));
                this.flagShowChat = event.url.indexOf('/cadastro') >= 0;
            }
        });
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data;
            }, error: err => super.onError(err)
        });
        this.userService.chat.subscribe({
            next: data => {
                this.modelChat = data;
                // console.log('chat message no footer');
                // console.log(this.modelChat);
                if (!this.isNullOrUndefined(this.modelChat.id)) {
                    this.getMessages();
                }
            }, error: err => super.onError(err)
        });
    }

    getMessages() {
        this.standartMessageQuery = this.standartMessagesGQL.watch({input: this.modelChat.id});
        this.standartMessageQuery.valueChanges.subscribe(({data}) => {
            this.standartMessage = data.standartMessages as StandartMessage[];
        });
    }

    positionComment(url: string) {
        setTimeout(() => {
            if (!$('#' + url)[0]) {
                return false;
            }

        }, 500);
    }

    handlerResponse(m: StandartMessage) {
        this.modalMessage = Object.assign({}, m);
        this.textResponse = undefined;
        super.showModal('modalComments');
    }

    actionResponse() {
        const arrValidateFields = [
            {value: this.textResponse, text: `Mensagem <br>`}
        ];
        const stringError = this.validateFieldZero(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção',
                `Preencha os campos obrigatórios corretamente:<br/><br/> ${stringError}`);
            return;
        }
        const input: ReplyStandartMessageInput = {
            id: this.modalMessage.id,
            reply: this.textResponse,
        }
        this.loading.show();
        this.replyStandartMessageGQL.mutate({input: input}).subscribe(({data}) => {
            super.closeModal('modalComments');
            this.standartMessageQuery.refetch({input: this.modelChat?.id});
            this.loading.hide();
            this.showMessage('Sucesso!', 'Resposta enviada com sucesso!', 'success');
        }, error => super.onError(error));
    }

    onModalContact() {
        this.showModal('modalContact');
    }

    onCloseComment() {
        $('.comment').toggleClass('is-closed');
    }
}
