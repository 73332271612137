import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {
    AuthInput,
    AuthType,
    CreateContactGQL,
    CreateContactInput, DefaultMessage,
    ForgotPasswordGQL,
    LoginGQL
} from "../../../generated/graphql";
import {UserService} from "../../service/user.service";
import {NgxSpinnerService} from "ngx-spinner";

declare var $: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnInit {

    modelLogin: AuthInput = new AuthInput();
    createContactInput: CreateContactInput = new CreateContactInput();
    forgotPassword: string;
    successPassword: boolean;
    fgtPasswordResponse: string;

    constructor(public router: Router,
                public translate: TranslateService,
                public loading: NgxSpinnerService,
                public service: UserService,
                private forgotPasswordGQL: ForgotPasswordGQL,
                private loginGQL: LoginGQL) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
    }

    actionLogin() {
        const arrValidateFields = [
            {value: this.modelLogin.login, text: `Login <br>`},
            {value: this.modelLogin.password, text: `Senha <br>`}
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção',
                `Preencha os campos obrigatórios corretamente: <br> ${stringError}`);
            return;
        }
        this.loginGQL.mutate({input: this.modelLogin}).subscribe(
            (data) => {
                const a: any = data;
                // console.log('onLogin');
                this.saveLocalUser(a.data.login);
            }, (err) => {
                // console.log('onLogin');
            }
        );
    }

    saveLocalUser(data) {
        const token = data as AuthType;
        this.setToken(token.token);
        this.service.updateUser(token.user);
        this.service.getStatusAuthenticated();
        this.router.navigate(['/']).then();
        $('html, body').animate({ scrollTop: 0 }, 500);
    }


    actionFgtPassword() {
        const arrValidateFields = [
            {value: this.forgotPassword, text: `CNPJ <br>`},
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha o campo: ${stringError} corretamente.`);
            return;
        }
        this.loading.show();
        this.forgotPasswordGQL.mutate({cnpj: this.forgotPassword}).subscribe(({data}) => {
            this.fgtPasswordResponse = data.forgotPassword.message;
            this.successPassword = true;
            this.loading.hide();
        }, error => super.onError(error));

    }

    onModalContact() {
        this.createContactInput = new CreateContactInput();
        this.showModal('modalContact');
        this.createContactInput.cnpj = this.modelLogin.login
    }

    onForgotPassword() {
        this.showModal('modalPassword');
        this.successPassword = false;
        this.forgotPassword = undefined
    }
}
