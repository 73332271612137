import {AfterViewInit, Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {BaseComponent} from '../../base/base.component';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import {
    ConsultantStandart,
    CurrentUserGQL, SearchResaleGQL, SearchResaleQuery,
    User,
} from '../../../generated/graphql';
import {QueryRef} from "apollo-angular";

declare var jlPlayer;
declare var $;
declare var totalJLPlayer;
declare var idElement;

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent extends BaseComponent implements OnInit, AfterViewInit {

    user: User = new User();
    searchResaleQuery: QueryRef<SearchResaleQuery>;
    searchResaleResult: ConsultantStandart = new ConsultantStandart();
    flagVideo = false;

    constructor(public router: Router,
                private router2: ActivatedRoute,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                private searchResaleGQL: SearchResaleGQL,
                private currentUserGQL: CurrentUserGQL,
                public userService: UserService) {
        super(router, loading, translate);
        router.events.forEach((event) => {
            if (event instanceof NavigationEnd) {
                this.initUserService(event);
            }
        });
    }

    ngOnInit(): void {
        moment.locale('pt-br');
        // console.log('aqui main');
        // console.log(this.userService.getStatusAuthenticated());
        if (this.userService.getStatusAuthenticated()) {
            this.getCurrentUser();
            $(() => {
                if (this.router.url === '/download') {
                    $('#modalDownload').modal('show');
                }
            })
        } else {
            localStorage.clear();
            this.userService.isAuthenticated = false;
            this.router.navigate(['/logout']);
        }
    }

    initUserService(event: NavigationEnd) {
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    this.user = data as User;
                    if (event.url === '/' || event.url === '/home') {
                        if (!this.isNullOrUndefined(this.user?.type) && !this.flagVideo) {
                            this.searchResaleQuery = this.searchResaleGQL.watch({input: this.user!.id});
                            this.searchResaleQuery.valueChanges.subscribe(({data}) => {
                                this.searchResaleResult = data.searchResale as ConsultantStandart;
                                /*if (this.searchResaleResult.processStep == 'adhesion' && this.searchResaleResult.statusAdhesion === 'pending') {
                                }*/
                                this.flagVideo = true;
                                $('#modalVideo').modal('show');
                            });
                        }
                    }
                }

            }, error: err => super.onError(err)
        });
    }

    ngAfterViewInit(): void {
        $(document).on('show.bs.modal', '.modal', function () {
            $(this).appendTo('body');
        });

        $('#modalVideo').on('shown.bs.modal', () => {
            setTimeout(() => {
                // console.log('criar video');
                this.createVideo();
            }, 1100);
        });

        $('#modalVideo').on('hidden.bs.modal', () => {
            // console.log('remover video');
            $('.js-media-video').html('');
        });
    }

    getCurrentUser(): void {
        this.currentUserGQL.watch().valueChanges.subscribe(({data}) => {
            const a: any = data;
            if (this.isNullOrUndefined(a)) {
                this.router.navigate(['/login']);
                return;
            }
            this.userService.updateUser(a.currentUser);
            if (!this.user.isActivated) {
                this.showModal('modalFirstAccess');
            } else if (this.isNullOrUndefined(this.user.type)) {
                this.showModal('modalRegisterType');
                // this.router.navigate(['/']);
            }
        });
    }

    createVideo(): void {
        console.log('createVideo');
        console.log(this.user.resaleType);
        const linkVideo = this.user.resaleType === 'Novo Negócio' ? 'Novos_Negocios_Juntos_no_Gas_alt.mp4' : 'Debranding_Juntos_no_Gas_alt.mp4';
        const strHtml = '<div class="video-container">\n' +
            '  <video id="video" preload="none" poster="/assets/images/campanha-video-thumb.png"\n' +
            '         class="jlplayer-video">\n' +
            '    <source src="https://juntosnogas.s3.sa-east-1.amazonaws.com/video/' + linkVideo + '" type="video/mp4"> \n' +
            '  </video>\n' +
            '</div>';
        $('.js-media-video').html(strHtml);
        setTimeout(() => {
            // tslint:disable-next-line:no-unused-expression
            totalJLPlayer = document.getElementsByClassName('jlplayer-video').length;
            if (totalJLPlayer > 0) {
                for (let i = 0; i < totalJLPlayer; i++) {
                    idElement = 'jlplayer-' + Math.floor(Math.random() * 100000000);
                    document.getElementsByClassName('jlplayer-video')[i].id = idElement;
                    // tslint:disable-next-line:no-unused-expression
                    new jlPlayer(idElement);
                    $('.video-container').find('video').on('ended', () => {
                        // console.log('video terminou');
                        // this.actionTrainingDone(this.listTraining[this.stepTraining].month);
                    });
                }
            }
        }, 500);
    }
}
