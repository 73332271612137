<div class="padding_header">
    <div class="page-inside register-page">
        <div class="register-page_container container mb-4">
            <div class="row">
                <div class="col-lg-12">
                    <div class="d-flex justify-content-between flex-column flex-md-row">
                        <span class="register-top-text">Insira as fotos de frota e fachada da sua revenda e acompanhe o status da auditoria, é muito importante ficar atento as solicitações de ajustes caso seja necessário.</span>
                        <div class="d-flex h-40"
                             *ngIf="(getStatusEditView(userStandart) && userStandart.type ==='adhesion') || (user.profile === 'MA' || user.profile === 'EC' || user.profile === 'GVE')">
                            <div class="d-flex align-items-center justify-content-center btn btn-tertiary text-white mx-3"
                                 (click)="searchResaleResult.processStep === 'adhesion' && !this.isProfileDebranding ? showModal('resaleType') : ''">
                                <small class="text-white mx-2">{{searchResaleResult.type === 'glp' ? 'Revenda com fachada' : 'Revenda apenas com gaiola'}}</small>
                                <span class="text-white mr-2"
                                      *ngIf="getStatusEditView(userStandart) && userStandart.type ==='adhesion' && !this.isProfileDebranding">|</span>
                                <a href="javascript:void('')"
                                   *ngIf="getStatusEditView(userStandart) && userStandart.type ==='adhesion' && !this.isProfileDebranding"
                                   class="text-white">Alterar</a>
                            </div>
                            <a href="javascript:void('');"
                               *ngIf="user.profile === 'MA' || user.profile === 'EC' || user.profile === 'GVE'"
                               class="btn btn-white" [routerLink]="'/revendas'">Voltar</a>
                        </div>
                    </div>

                    <!--<div class="register-page_top" *ngIf="user?.resaleType === 'new'">
                        Bem-vindo revenda nova! Exibir conteúdos diferenciados para novas revendas.
                    </div>-->
                    <div class="register-page_top"
                         *ngIf="user.profile === 'EC' || user.profile === 'GVE' || user.profile === 'MA'">
                        <div class="register-page_top-info">
                            <div class="d-flex">
                                <span class="info-lbl text-white mr-2">CNPJ:</span>
                                <strong class="text-white">{{searchResaleResult?.cnpj}}</strong>
                            </div>
                            <div class="d-flex">
                                <strong class="text-primary">{{searchResaleResult?.companyName}}</strong>
                            </div>
                            <div class="d-flex">
                                <div class="d-flex pr-5">
                                    <span class="info-lbl text-white mr-2">Marca:</span>
                                    <strong class="text-white">{{searchResaleResult?.branding}}</strong>
                                </div>
                                <div class="d-flex">
                                    <span class="info-lbl text-white mr-2">Perfil:</span>
                                    <strong class="text-white">{{searchResaleResult.resaleType}}</strong>
                                </div>
                            </div>
                            <div class="d-flex">
                                <span class="info-lbl text-white">Participou da Padroniza e Vem Pra Copa 2022:</span>
                                <strong class="text-white px-2">{{searchResaleResult.isPreviousCampaign ? 'Sim' : 'Não'}}</strong>
                            </div>
                        </div>
                        <div class="register-page_top-bar is-cursor" (click)="showModal('responseFleet')">
                            <svg-icon class="svgMain" src="assets/svg/paint.svg"></svg-icon>
                            <p class="bar-lbl text-quartenary m-0"> Indique aqui sem será o responsável pela
                                padronização da frota.</p>
                            <p class="bar-value text-white text-right m-0"> {{getResponsibleStandardization(searchResaleResult?.responsibleStandardization)}}</p>
                            <svg-icon src="assets/svg/arrowright.svg"></svg-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="section-tab">
            <div class="section-tab-controls">
                <div class="register-page_container container">
                    <div class="row">
                        <div class="col-lg-6">
                            <ul class="d-flex align-items-end" style="list-style-type:none">
                                <li class="register-page_tab" [class]="{'active': tabCurrent == 'adhesion'}">
                                    <a class="is-cursor flex-column align-items-center"
                                       (click)="onChangeTab('adhesion')" href="javascript:void('')">
                                        <!--<div class="d-flex">
                                            <svg-icon class="page_tab_icon"
                                                      *ngIf="!isNullOrUndefined(statusAdhesionIcon)"
                                                      [ngClass]="searchResaleResult?.statusAdhesion"
                                                      src="{{statusAdhesionIcon}}">
                                            </svg-icon>
                                        </div>-->
                                        <span>Passo 1 - Adesão</span>
                                        <!--<small class="page_tab_status">{{searchResaleResult?.statusAdhesion | translate}}</small>-->
                                    </a>
                                </li>
                                <li class="register-page_tab position-relative" [class]="{'active': tabCurrent == 'project'}"
                                    *ngIf="!isNullOrUndefined(documentsProject[documentsProject.length - 1]?.url) && (searchResaleResult.isProject || searchResaleResult.statusProject !== 'pending') && !this.isProfileDebranding">
                                    <span class="register-page_badge" *ngIf="searchResaleResult.statusProject === 'pending'"></span>
                                    <a class="is-cursor flex-column align-items-center"
                                       (click)="tabCurrent = 'project'" href="javascript:void('')">
                                        <span>Projeto</span>
                                    </a>
                                </li>
                                <li class="register-page_tab" [class]="{'active': tabCurrent == 'standardization'}">
                                    <a class="flex-column" [ngClass]="{'is-cursor': !isNullOrUndefined(searchResaleResult?.statusStandardization),
                                               'no-cursor': isNullOrUndefined(searchResaleResult?.statusStandardization)}"
                                       (click)="onChangeTab('standardization')" href="javascript:void('')">
                                        <!--<div class="d-flex">
                                            <svg-icon class="page_tab_icon"
                                                      *ngIf="!isNullOrUndefined(statusStandardizationIcon)"
                                                      [ngClass]="searchResaleResult?.statusStandardization"
                                                      src="{{statusStandardizationIcon}}">
                                            </svg-icon>
                                        </div>-->
                                        <span>Passo 2 - Padronização</span>
                                        <!--<small class="page_tab_status">{{searchResaleResult?.statusStandardization | translate}}</small>-->
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-tab-content bg-white" style="min-height: 430px;">

                <!--<div class="status-bar {{userStandart?.status}}">
                    <div class="register-page_container container">
                        <div class="d-flex align-items-center status-bar-bg">
                            <svg-icon
                                    src="assets/svg/branches-{{userStandart?.status === 'received' ? 'analysis' : userStandart?.status}}.svg"></svg-icon>
                            <span class="pl-2">{{userStandart?.status | translate}}</span>
                        </div>
                    </div>
                </div>-->

                <div class="register-page_container container" *ngIf="tabCurrent != 'project'">
                    <div class="row">
                        <div class="col-12 col-lg-7 col-xxl-9">
                            <div class="photo-list">
                                <div class="photo-item">
                                    <div class="d-flex align-items-center justify-content-start mb-2">
                                        <div class="d-flex align-items-center status-bar {{getStatusUserStandardFront()}}">
                                            <div class="status-bar-bg">
                                                <svg-icon
                                                        src="assets/svg/branches-{{(getStatusUserStandardFront()) === 'received' ? 'analysis' : getStatusUserStandardFront()}}.svg"></svg-icon>
                                                <span class="pl-1">{{(getStatusUserStandardFront()) | translate}}</span>
                                            </div>
                                        </div>
                                        <p class="photo-title m-0">Fachada</p>
                                    </div>
                                    <div class="row photo-row">
                                        <ng-container *ngFor="let x of userStandart.item">
                                            <ng-container *ngIf="x.type === 'front-wall'">
                                                <!--<ng-container
                                                        *ngIf="searchResaleResult.processStep === 'standardization' && searchResaleResult.statusStandardization === 'approved'">
                                                    <p class="fleet_text">Sua revenda está com a fachada em dia, parabéns!</p>
                                                </ng-container>-->
                                                <!--<ng-container *ngIf="searchResaleResult.processStep === 'adhesion'
                                                        && searchResaleResult.statusAdhesion === 'received'">
                                                    <p>Suas fotos foram enviadas ao fornecedor para aplicação da nova
                                                        padronização.</p>
                                                </ng-container>-->
                                                <ng-container>
                                                    <p class="fleet_text" *ngIf="user.resaleType === 'Novo Negócio'">
                                                        Insira fotos
                                                        que deem visão de toda a fachada da revenda - assim nosso
                                                        fornecedor poderá
                                                        ser acionado para realizar a padronização.</p>
                                                    <p class="fleet_text" *ngIf="user.resaleType !== 'Novo Negócio'">
                                                        Insira fotos
                                                        que deem visão de toda a fachada da revenda, especialmente se
                                                        houver itens
                                                        com a marca antiga Liquigás - assim nosso fornecedor poderá ser
                                                        acionado
                                                        para realizar a padronização.</p>
                                                </ng-container>

                                                <div class="col-6 col-xl-3" *ngFor="let p of x.images">
                                                    <div class="photo-item-thumb">
                                                        <div class="thumb_btn" *ngIf="p?.status == 'approved'">
                                                            <svg-icon src="assets/svg/check-circle.svg"></svg-icon>
                                                        </div>
                                                        <div class="thumb_btn" *ngIf="p?.status == 'reproved'">
                                                            <svg-icon src="assets/svg/close-circle.svg"></svg-icon>
                                                        </div>
                                                        <ng-container *ngIf="!isNullOrUndefined(p?.url)">
                                                            <img [src]="p?.url" [alt]="p?.id"/>
                                                            <div class="photo-item-thumb_name mb-0">
                                                                <p class="mb-0">{{p?.name}}</p>
                                                                <span class="d-flex align-items-center">
                                                                <a class="photo-item-thumb_edit"
                                                                   href="javascript:void(0)"
                                                                   *ngIf="getStatusEditView(p) && getStatusEditView({status: getStatusUserStandardFront()}) && flagEdit && !this.isProfileDebranding"
                                                                   (click)="actionUploadImage(p, 'front')">
                                                                    <svg-icon src="assets/svg/edit.svg"></svg-icon>
                                                                </a>
                                                                <a class="photo-item-thumb_edit ml-3"
                                                                   href="javascript:void(0)"
                                                                   *ngIf="getStatusEditView(p) && getStatusEditView({status: getStatusUserStandardFront()}) && flagEdit && !this.isProfileDebranding"
                                                                   (click)="actionRemovePhoto(p)">
                                                                    <svg-icon src="assets/svg/trash.svg"></svg-icon>
                                                                </a>
                                                            </span>
                                                            </div>
                                                        </ng-container>

                                                        <div class="photo-item-thumb_no-photo is-cursor"
                                                             (click)="this.isProfileDebranding ? '' : actionUploadImage(p, 'front')"
                                                             *ngIf="isNullOrUndefined(p?.url)">
                                                            <svg-icon src="assets/svg/plus-image.svg"></svg-icon>
                                                            <p class="photo-item-thumb_lbl mb-0">{{p?.name}}</p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="adesion mb-5">
                    <div class="register-page_container container" *ngIf="tabCurrent != 'project'">
                        <div class="row">
                            <div class="col-12 col-lg-7 col-xxl-9">
                                <div class="adesion-content">
                                    <a href="javascript:void('');" (click)="actionSend('front')"
                                       *ngIf="flagEdit && !this.isProfileDebranding"
                                       class="btn btn-white btn-block font-weight-bold" style="margin-right: 60px;">
                                        Enviar Fachada
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr>

                <div class="register-page_container container" *ngIf="tabCurrent != 'project'">
                    <div class="row">
                        <div class="col-12 col-lg-7 col-xxl-9">
                            <div class="photo-list">
                                <div class="register-page_fleet">
                                    <div class="container">
                                        <div>
                                            <div class="row">
                                                <div class="col">
                                                    <!--{{(searchResaleResult.isFleet) && (getStatusUserStandardFront() !== 'approved') && (getStatusUserStandardFleet() !== 'pending')}}-->
                                                    <div class="d-flex align-items-center justify-content-start">
                                                        <div class="d-flex align-items-center status-bar {{getStatusUserStandardFleet()}}"
                                                        *ngIf="searchResaleResult.isFleet">
                                                            <div class="status-bar-bg">
                                                                <svg-icon
                                                                        src="assets/svg/branches-{{getStatusUserStandardFleet() === 'received' ? 'analysis' : getStatusUserStandardFleet()}}.svg"></svg-icon>
                                                                <span class="pl-1">{{(getStatusUserStandardFleet()) | translate}}</span>
                                                            </div>
                                                        </div>
                                                        <p class="photo-title m-0"> Frota
                                                            <span *ngIf="searchResaleResult.isFleet">( {{countFleet}}
                                                                veículo{{countFleet > 1 ? 's' : ''}} )</span></p>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-auto d-flex justify-content-end">
                                                    <a href="javascript:void(0)" (click)="showModal('addFleet')" class="d-flex align-items-center"
                                                       *ngIf="searchResaleResult.isFleet && flagEditFleet && userStandart.type === 'adhesion' && !this.isProfileDebranding">
                                                        <span class="px-2 text-tertiary">Adicionar Veículos</span>
                                                        <span class="photo_plus">
                                                            <svg-icon src="assets/svg/plus.svg"></svg-icon>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col">
                                                    <p class="photo-subtitle text-center">Sua revenda possui frota?</p>
                                                    <div class="d-flex" style="gap: 30px">
                                                        <div class="w-100">
                                                            <input type="radio" class="custom-control-input"
                                                                   id="customRadio" name="example1"
                                                                   [(ngModel)]="flagFleet"
                                                                   [disabled]="isResaleHasFleetEnable()"
                                                                   (change)="onFleetToggle()"
                                                                   [checked]="!searchResaleResult.isFleet"
                                                                   [value]="false"/>
                                                            <label class="btn btn-block"
                                                                   for="customRadio">Não</label>
                                                        </div>
                                                        <div class="w-100">
                                                            <input type="radio" class="custom-control-input"
                                                                   id="customRadioB" name="example1"
                                                                   [(ngModel)]="flagFleet"
                                                                   [disabled]="isResaleHasFleetEnable()"
                                                                   (change)="onFleetToggle()"
                                                                   [checked]="searchResaleResult.isFleet"
                                                                   [value]="true"/>
                                                            <label class="btn btn-block"
                                                                   for="customRadioB">Sim</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 pt-2" *ngIf="searchResaleResult.isFleet">
                                                    <p *ngIf="searchResaleResult.resaleType === 'Novo Negócio'"
                                                       class="fleet_text">Para a aprovação final da frota e envio da
                                                        premiação, precisamos enxergar a placa do veículo, todos os
                                                        lados dele e do termo assinado.</p>
                                                    <p *ngIf="searchResaleResult.resaleType !== 'Novo Negócio'"
                                                       class="fleet_text">Você pode enviar a foto antes da padronização
                                                        ou dela já pronta. Veja que para a aprovação final da frota e
                                                        envio da premiação, precisamos enxergar a placa e todos os lados
                                                        do veículo, e receber o termo assinado.</p>
                                                </div>
                                            </div>
                                            <hr *ngIf="searchResaleResult.isFleet"/>
                                            <div class="photo-item mt-3" *ngIf="searchResaleResult.isFleet">
                                                <p class="photo-subtitle">Termo de Frota</p>
                                                <div class="row photo-row">
                                                    <div class="col-md-6">
                                                        <p class="fleet_text">
                                                            Faça o download do Termo de adesão disponibilizado no link
                                                            abaixo.
                                                        </p>
                                                        <a href="assets/files/Termo_Responsabilidade_Revenda_frotas_rev_jur.pdf" target="_blank"
                                                           class="btn btn-block btn-white text-primary mb-3">
                                                            Download do Termo</a>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <ng-container *ngIf="isEnableTerm()">
                                                            <p class="fleet_text">
                                                                Imprima, assine e tire uma foto do termo para envia-lo a
                                                                nossos auditores.
                                                            </p>
                                                            <label for="myfile"
                                                                   class="btn btn-block btn-primary text-white">
                                                                Adicionar termo assinado</label>
                                                            <div data-browse="Enviar" class="mb-4">
                                                                <input type="file" id="myfile" class="d-none"
                                                                       (change)="uploadTerms($event)">
                                                            </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="!isNullOrUndefined(documents)">
                                                            <div class="row">
                                                                <div class="col-12 col-md-7">
                                                                    <p class="fleet_text">
                                                                        Confira o termo de frota enviado para nossos auditores.
                                                                    </p>
                                                                </div>
                                                                <div class="col-12 col-md-5">
                                                                    <div class="d-flex justify-content-center">
                                                                        <div><!-- *ngFor="let x of documents"-->
                                                                            <div class="photo-item-thumb border-0 px-3 py-4">
                                                                                <div class="thumb_btn" *ngIf="documents[documents.length - 1].status == 'approved'"> <!--*ngIf="x?.status == 'approved'"-->
                                                                                    <svg-icon [svgStyle]="{'width.px': 20, 'height.px': 20}"
                                                                                            src="assets/svg/check-circle.svg"></svg-icon>
                                                                                </div>
                                                                                <div class="thumb_btn svg svg-danger" *ngIf="documents[documents.length - 1].status == 'review'">  <!--*ngIf="x?.status == 'reproved'"-->
                                                                                    <svg-icon [svgStyle]="{'width.px': 20, 'height.px': 20}"
                                                                                            src="assets/svg/close-circle.svg"></svg-icon>
                                                                                </div>
                                                                                <a href="{{documents[documents.length - 1].url}}" target="_blank" class="d-flex align-items-center justify-content-center flex-column">
                                                                                    <svg-icon src="assets/svg/file-double.svg" [stretch]="true" [svgStyle]="{'width.px': 40, 'height.px': 50}"></svg-icon>
                                                                                    <span class="text-primary">Visualizar</span>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>

                                            <hr>
                                            <p class="fleet_text" *ngIf="searchResaleResult.isFleet">
                                                Para a inclusão de sua frota vamos adicionar até 4 fotos por veículo.
                                            </p>
                                        </div>
                                        <ng-container *ngFor="let f of userStandart?.item; let i = index;">
                                            <div class="photo-item" *ngIf="f.type !== 'front-wall'">
                                                <div class="d-flex align-items-center flex-wrap mb-3" style="gap: 41px">
                                                    <h6 class="photo-item-text mb-0">{{getItemNameByType(f?.type)}}
                                                        <small class="size-sm">#{{f?.id.substring(0, 4)}}</small></h6>
                                                    <div class="d-flex align-items-center" style="gap: 10px"
                                                         *ngIf="getStatusEditView(f) && flagEditFleet && userStandart.type === 'adhesion' && !this.isProfileDebranding">
                                                        <div class="d-flex align-items-center is-cursor" (click)="actionRemoveFleetItem(f.id)">
                                                            <span class="photo-item-text-trash text-danger">Excluir</span>
                                                            <a href="javascript:void('')" class="ml-1 svg svg-danger">
                                                                <svg-icon [svgStyle]="{ 'width.px':18, 'height.px':18 }"
                                                                          src="assets/svg/trash.svg"></svg-icon>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row photo-row">
                                                    <ng-container *ngIf="f.type !== 'front-wall'">
                                                        <div class="col-6 col-xl-3" *ngFor="let p of f.images;">
                                                            <div class="photo-item-thumb">
                                                                <div class="thumb_btn" *ngIf="p?.status == 'approved'">
                                                                    <svg-icon
                                                                            src="assets/svg/check-circle.svg"></svg-icon>
                                                                </div>
                                                                <div class="thumb_btn" *ngIf="p?.status == 'reproved'">
                                                                    <svg-icon
                                                                            src="assets/svg/close-circle.svg"></svg-icon>
                                                                </div>
                                                                <ng-container *ngIf="!isNullOrUndefined(p?.url)">
                                                                    <img [src]="p?.url" [alt]="p?.id"/>
                                                                    <div class="photo-item-thumb_name mb-0">
                                                                        <p class="mb-0">{{p?.name}}</p>
                                                                        <span class="d-flex align-items-center">
                                                                    <a class="photo-item-thumb_edit"
                                                                       href="javascript:void(0)"
                                                                       *ngIf="getStatusEditView(p) && getStatusEditView({status: getStatusUserStandardFleet()}) && flagEditFleet && !this.isProfileDebranding"
                                                                       (click)="actionUploadImage(p, 'fleet')">
                                                                        <svg-icon src="assets/svg/edit.svg"></svg-icon>
                                                                    </a>
                                                                    <a class="photo-item-thumb_edit ml-3"
                                                                       href="javascript:void(0)"
                                                                       *ngIf="getStatusEditView(p) && getStatusEditView({status: getStatusUserStandardFleet()}) && flagEditFleet && searchResaleResult.processStep === 'adhesion' && !this.isProfileDebranding"
                                                                       (click)="actionRemovePhoto(p)">
                                                                        <svg-icon src="assets/svg/trash.svg"></svg-icon>
                                                                    </a>
                                                                </span>

                                                                    </div>
                                                                </ng-container>

                                                                <div class="photo-item-thumb_no-photo is-cursor"
                                                                     (click)="this.isProfileDebranding ? '' : actionUploadImage(p, 'fleet')"
                                                                     *ngIf="isNullOrUndefined(p?.url)">
                                                                    <svg-icon
                                                                            src="assets/svg/plus-image.svg"></svg-icon>
                                                                    <p class="photo-item-thumb_lbl mb-0">{{p?.name}}</p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <a href="javascript:void(0)" (click)="showModal('addFleet')"
                                           *ngIf="searchResaleResult.isFleet && flagEditFleet && searchResaleResult.processStep === 'adhesion' && !this.isProfileDebranding"
                                           class="btn btn-outline-secondary fleet_btn">
                                            <svg-icon class="stroke-primary" src="assets/svg/plus.svg"></svg-icon>
                                            <span>Adicionar Veículo</span>
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="register-page_container container" *ngIf="tabCurrent == 'project' && !this.isProfileDebranding">
                    <div class="row">
                        <div class="col-12 col-lg-7">
                            <div class="photo-list">
                                <div class="d-flex align-items-center justify-content-start mb-2">
                                    <div class="d-flex align-items-center status-bar {{searchResaleResult.statusProject}}">
                                        <div class="status-bar-bg">
                                            <svg-icon
                                                    src="assets/svg/branches-{{searchResaleResult.statusProject === 'received' ? 'analysis' : searchResaleResult.statusProject}}.svg"></svg-icon>
                                            <span class="pl-1">{{(searchResaleResult.statusProject) | translate}}</span>
                                        </div>
                                    </div>
                                    <p class="photo-title m-0">Projeto</p>
                                </div>
                                <p class="fleet_text">
                                    Identificamos que sua revenda possui um ou mais elementos que necessitam de
                                    padronização
                                    especial.
                                    Abaixo apresentamos um projeto
                                    para a renovação dos elementos envolvido
                                    com a nova identidade da marca Liquigás.
                                </p>
                                <div class="project_card card bg-tertiary">
                                    <div class="card-body">
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <h6 class="project_card_title">{{documentsProject[documentsProject.length - 1]?.createdAt | date: 'dd/MM/yyyy'}}</h6>
                                                <p class="project_card_subtitle mb-0">Data do Projeto</p>
                                            </div>
                                            <div class="col-auto d-flex flex-column align-items-center">
                                                <a href="{{documentsProject[documentsProject.length - 1]?.url}}" target="_blank" class="btn btn-outline-primary project_card_download">
                                                    <svg-icon class="fill-primary"
                                                              src="assets/svg/download.svg"></svg-icon>
                                                </a>
                                                <p class="project_card_subtitle mb-0">Download</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngIf="searchResaleResult.statusProject === 'pending'">
                                    <p class="fleet_text">
                                        Você possui 3 dias para sinalizar sua posição ao acordo do projeto, caso não
                                        responda
                                        dentro do
                                        prazo iremos seguir com a padronização dos elementos envolvidos no projeto.
                                    </p>
                                    <div class="row mb-3">
                                        <div class="col-6">
                                            <a class="btn btn-block btn-outline-primary" [ngClass]="{'btn-primary text-white': documentsProjectInput.status === 'reproved'}"
                                               (click)="onEvalProject('reproved');">Recursar</a>
                                        </div>
                                        <div class="col-6">
                                            <a class="btn btn-block btn-outline-primary" [ngClass]="{'btn-primary text-white': documentsProjectInput.status === 'approved'}"
                                               (click)="onEvalProject('approved');">Aceitar</a>
                                        </div>
                                    </div>
                                    <!--<div class="observation_bg bg-secondary" *ngIf="false">
                                        <div class="container">
                                            <p class="observation_title">Observações</p>
                                            <div class="position-relative">
                                                &lt;!&ndash;<div contenteditable="true" [(ngModel)]="documentsProjectInput.justify" class="message-text mb-4 edit"></div>&ndash;&gt;
                                                <div class="message-text mb-4 edit">
                                                    <textarea name="reprovedJustify" id="reprovedJustify" [(ngModel)]="documentsProjectInput.justify"
                                                              class="form-control text-primary border-0" rows="1"></textarea>
                                                </div>
                                                <svg-icon class="chatBtn is-cursor" (click)="onSendProject()" src="assets/svg/send.svg"></svg-icon>
                                            </div>
                                        </div>
                                    </div>-->
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="adesion" *ngIf="tabCurrent != 'project'">
                <div class="register-page_container container">
                    <div class="row">
                        <div class="col-12 col-lg-7 col-xxl-9">
                            <div class="adesion-content">
                                <a href="javascript:void('');" (click)="actionSend('fleet')"
                                   *ngIf="searchResaleResult.isFleet && flagEditFleet && (getStatusUserStandardFleet() === 'pending' || getStatusUserStandardFleet() === 'review') && !this.isProfileDebranding"
                                   class="btn btn-white btn-block font-weight-bold" style="margin-right: 60px;">
                                    Enviar Frota
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>


<div class="modal-custom modal fade" id="projectJustify" data-keyboard="false"
     data-backdrop="static" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-bottom_card">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <h6 class="modal-bottom_title">Reprovação do Projeto</h6>
                        </div>
                        <div class="col-auto">
                            <a href="javascript:void(0)" (click)="closeModal('projectJustify')">
                                <svg-icon class="modal-bottom_close" src="assets/svg/close.svg"></svg-icon>
                            </a>
                        </div>
                    </div>
                    <p class="modal-bottom_text">Informe a justificativa para a recusa do projeto.</p>
                    <div class="mb-4">
                        <div class="input-material">
                            <textarea name="projectJustifyIpt" id="projectJustifyIpt" [(ngModel)]="documentsProjectInput.justify"
                                      class="form-control" rows="4"></textarea>
                            <label class="disabled" for="projectJustifyIpt"></label>
                        </div>
                    </div>
                </div>
                <div class="bg-secondary modal-bottom_card_footer py-2">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-12">
                                <a class="btn btn-block btn-white" (click)="onEvalProjectSend()">Enviar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-custom modal fade" id="addFleet" data-keyboard="false"
     data-backdrop="static" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-bottom_card">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <h6 class="modal-bottom_title">Frota</h6>
                        </div>
                        <div class="col-auto">
                            <a href="javascript:void(0)" (click)="closeModal('addFleet')">
                                <svg-icon class="modal-bottom_close" src="assets/svg/close.svg"></svg-icon>
                            </a>
                        </div>
                    </div>
                    <p class="modal-bottom_text">Informe as quantidades de cada veículo da revenda.</p>
                    <div class="modal-bottom_item" *ngFor="let x of userStandartFleet">
                        <div class="row align-items-center">
                            <div class="col-auto">
                                <img src="assets/svg/{{x.type?.type}}.svg" alt=""/>
                                <!--<svg-icon [svgStyle]="{ 'width.px':57, 'height.px':21 }" src=""></svg-icon>-->
                            </div>
                            <div class="col">
                                <span class="modal-bottom_name">{{x.type?.name}}</span>
                            </div>
                            <!--<div class="col-auto"><span class="modal-bottom_count"></span></div>-->
                            <div class="col-auto">
                                <div class="options-custom">
                                    <svg-icon (click)="onChangeCount(x, false)" class="modal-bottom_arrow"
                                              src="assets/svg/minus.svg"></svg-icon>
                                    <span class="options-custom-lbl">{{isNullOrUndefined(x.countAdd) ? 0 : x.countAdd}}</span>
                                    <svg-icon (click)="onChangeCount(x, true)" class="modal-bottom_arrow"
                                              src="assets/svg/plus.svg"></svg-icon>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="bg-secondary modal-bottom_card_footer py-2">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-12">
                                <a class="btn btn-block btn-white" (click)="onSendFleet()">Adicionar</a>
                            </div>
                            <!--<div class="col"><span class="modal-bottom_total">Total de veículos da frota</span></div>
                            <div class="col-auto"><span class="modal-bottom_total-count">{{countFleet}}</span></div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-crop-image [cropId]="'modalCropPhoto'"
                [cropAspectRatio]="12/6"
                [maintainAspectRatio]="false"
                [cropResizeToWidth]="1024"
                [cropResizeToHeight]="576"
                (croppedImageResult)="setCroppedImage($event)"></app-crop-image>

<!--Modal Registro Tipo-->
<div class="modal-custom modal fade" id="resaleType" data-keyboard="false"
     data-backdrop="static" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content modal_comment">
            <div class="row">
                <div class="col-12 col-lg-4">
                    <div class="modal_col-primary">
                        <a class="ml-auto d-lg-none" href="javascript:void(0)" (click)="this.closeModal('resaleType');"
                           *ngIf="!isNullOrUndefined(searchResaleResult.type)">
                            <svg-icon class="modal-bottom_close" src="assets/svg/close.svg"></svg-icon>
                        </a>
                        <a class="ml-auto d-lg-none" href="javascript:void(0)"
                           (click)="closeModal('resaleType'); router.navigate(['/'])"
                           *ngIf="isNullOrUndefined(searchResaleResult.type)">
                            <svg-icon class="modal-bottom_close" src="assets/svg/close.svg"></svg-icon>
                        </a>
                        <div class="d-flex flex-column align-items-center pt-4">
                            <img src="assets/images/logocopagaz.png" style="width: 279px;" alt="logo">
                            <h2 class="modal_title mt-3">Revenda <br>participante</h2>
                            <p class="modal_subtitle" *ngIf="isNullOrUndefined(searchResaleResult.type)">
                                Olá
                                <span>{{user.name}}</span>, {{user.resaleType === 'Novo Negócio' ? 'por favor insira suas informações e fotos.' :
                                'insira suas informações e fotos e lembre de incluir tudo que tiver a antiga marca Liquigás.' }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-8">
                    <div class="container py-4">
                        <div class="d-none d-lg-flex justify-content-end w-100"
                             *ngIf="!isNullOrUndefined(searchResaleResult.type)">
                            <a href="javascript:void(0)" (click)="this.closeModal('resaleType');">
                                <svg-icon class="modal-bottom_close" src="assets/svg/close.svg"></svg-icon>
                            </a>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <p class="modal_question mb-4">
                                    Qual o tipo<br>de sua <span>revenda?</span>
                                </p>
                            </div>
                        </div>
                        <div class="row justify-content-center mb-3">
                            <div class="col-5">
                                <div class="d-flex flex-column align-items-center">
                                    <div (click)="userResale.type = 'glp'" class="modal_select"
                                         [ngClass]="{'active' : userResale.type === 'glp'}">
                                        <svg-icon src="assets/svg/glp.svg"></svg-icon>
                                    </div>
                                    <p class="modal_text">
                                        Revenda com <span>fachada</span>.
                                        (muros, portão ou outros)
                                    </p>
                                </div>
                            </div>
                            <div class="col-5">
                                <div class="d-flex flex-column align-items-center">
                                    <div (click)="userResale.type = 'gas'" class="modal_select"
                                         [ngClass]="{'active' : userResale.type === 'gas'}">
                                        <svg-icon src="assets/svg/gaiola.svg"></svg-icon>
                                    </div>
                                    <p class="modal_text">
                                        Revenda que usa somente
                                        <span>gaiola</span> ou <span>placas ANP.</span>
                                        (Posto de combustível, supermercado ou outros)
                                    </p>
                                </div>
                            </div>
                        </div>

                        <ng-container *ngIf="!isNullOrUndefined(searchResaleResult.type)">
                            <div class="row" style="row-gap: 29px">
                                <div class="col-12">
                                    <a class="btn btn-block btn-white"
                                       (click)="actionUpdateResaleType()">Salvar</a>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="isNullOrUndefined(searchResaleResult.type)">
                            <div class="row" style="row-gap: 29px">
                                <div class="col-12">
                                    <!--<p class="modal_lbl">Responda a seguinte pergunta:</p>-->
                                    <p class="modal_question mb-4">
                                        A revenda<br>
                                        possui <span>Frota?</span>
                                    </p>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-6">
                                    <a class="btn btn-block btn-outline-secondary text-white"
                                       (click)="actionSaveResaleType(false)">Não</a>
                                </div>
                                <div class="col-6">
                                    <a class="btn btn-block btn-white"
                                       (click)="actionSaveResaleType(true)">Sim</a>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-custom modal fade" id="responseFleet" data-keyboard="false"
     data-backdrop="static" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-bottom_card">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <h6 class="modal-bottom_title title_big text-uppercase">Quem será responsável pela
                                padronização?</h6>
                        </div>
                        <div class="col-auto">
                            <a href="javascript:void(0)" (click)="closeModal('responseFleet')">
                                <svg-icon class="modal-bottom_close" src="assets/svg/close.svg"></svg-icon>
                            </a>
                        </div>
                    </div>
                    <div class="modal-bottom_card_radio radio-register_custom radio-items mb-4">
                        <div class="form-check d-flex align-items-center">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                                   [(ngModel)]="modelResponsible"
                                   [checked]="searchResaleResult.responsibleStandardization === 'provider'"
                                   value="provider">
                            <label class="form-check-label" for="inlineRadio1"></label>
                            <span class="text-white ml-3">Copa Energia</span>
                        </div>
                        <div class="form-check d-flex">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                                   [(ngModel)]="modelResponsible"
                                   [checked]="searchResaleResult.responsibleStandardization === 'resale'"
                                   value="resale">
                            <label class="form-check-label" for="inlineRadio2"></label>
                            <span class="text-white ml-3">Revenda</span>
                        </div>
                        <div class="form-check d-flex">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3"
                                   [(ngModel)]="modelResponsible"
                                   [checked]="searchResaleResult.responsibleStandardization === 'dealing'"
                                   value="dealing">
                            <label class="form-check-label" for="inlineRadio3"></label>
                            <span class="text-white ml-3">Em negociação</span>
                        </div>
                    </div>
                    <a class="btn btn-block mb-3 btn-white" (click)="onUpdateResponsible()">Salvar</a>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Modal Cadastro-->
<div class="modal-custom modal fade" id="modalCadastroNumero" data-keyboard="false"
     data-backdrop="static" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content modal_comment">
            <div class="row">

                <div class="col-12 col-lg-6" style="padding: 0;">
                    <div class="modal_col-primary px-5">
                        <a data-dismiss="modal" class="modal_close ml-auto d-lg-none" aria-label="Close">
                            <svg-icon [svgStyle]="{'fill':'#006F4D'}"
                                      src="assets/svg/close-circle.svg"></svg-icon>
                        </a>
                        <img src="assets/images/logocopagaz.png" class="modal_logo" alt="logo">
                        <h2 class="text-white mb-3 pr-5"><b class="text-quartenary">Pronto!</b></h2>

                        <p class="modalSubtitle pr-5">Agora é só aguardar a avaliação dos nossos auditores.</p>

                        <!--<p class="modalSubtitle pr-5">Você é o nosso <b class="text-quartenary">{{user?.position}}º
                            Cadastrado</b>
                            <ng-container *ngIf="user?.type === 'glp'">e esta participando do sorteio do <b
                                    class="text-quartenary">{{user?.lot?.id}}</b></ng-container>
                            .
                        </p>-->
                        <p class="modalLittleText">Fique atento ao seu e-mail, pois você receberá o resultado da
                            avaliação.</p>
                        <b></b>
                        <a class="btn btn-primary btn-block  mb-4" href="javascript:void(0);"
                           (click)="closeModal('modalCadastroNumero')"> <span class="text-white">Fechar</span></a>
                    </div>
                </div>

                <div class="col-12 col-lg-6 img_modal_banner" style="padding: 0;">
                    <img *ngIf="user?.resaleType === 'Novo Negócio'" style="max-height: 550px;"
                         src="assets/images/banneraguarde-copagaz-new.png" alt="">
                    <img *ngIf="user?.resaleType !== 'Novo Negócio'" style="max-height: 550px;"
                         src="assets/images/{{user?.branding === 'Liquigás' ? 'banneraguarde' : 'banneraguarde-copagaz' }}.png"
                         alt="">
                </div>
            </div>
        </div>
    </div>
</div>

<!--Modal Cadastro Padronizacao-->
<div class="modal-custom modal fade" id="modalAudit" data-keyboard="false"
     data-backdrop="static" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content modal_comment">
            <div class="row">

                <div class="col-12 col-lg-6" style="padding: 0;">
                    <div class="modal_col-primary">
                        <a data-dismiss="modal" class="modal_close ml-auto d-lg-none" aria-label="Close">
                            <svg-icon [svgStyle]="{'fill':'#006F4D'}"
                                      src="assets/svg/close-circle.svg"></svg-icon>
                        </a>
                        <img src="assets/images/logocopagaz.png" style="width: 279px;" alt="logo">
                        <h2 class="modal_title text-white mb-3">AGUARDE,<br></h2>
                        <p class="modalSubtitle pr-5">
                            Padronização enviada, aguarde a avaliação dos nossos auditores. Em breve, você receberá o resultado da avaliação por e-mail e poderá consultar no site.
                        </p>
                        <a class="btn btn-primary btn-block  mb-4" href="javascript:void(0);"
                           (click)="closeModal('modalAudit')"> <span class="text-white">Fechar</span></a>
                    </div>
                </div>

                <div class="col-12 col-lg-6 img_modal_banner text-center" style="padding: 0;">
                    <img *ngIf="user?.resaleType === 'Novo Negócio'" style="max-height: 550px;"
                         src="assets/images/banneraguarde-copagaz-new.png" alt="">
                    <img *ngIf="user?.resaleType !== 'Novo Negócio'" style="max-height: 550px;"
                         src="assets/images/{{user?.branding === 'Liquigás' ? 'banneraguarde' : 'banneraguarde-copagaz' }}.png"
                         alt="">
                </div>
            </div>
        </div>
    </div>
</div>
