<!--Modal Primeiro Acesso-->
<div class="modal-custom modal fade" id="modalFirstAccess" data-keyboard="false"
     data-backdrop="static" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-body flex-column">
                <div class="row">
                    <div class="col">
                        <div class="row">
                            <div class="col">
                                <h2 class="modal_title">CADASTRO </h2>
                            </div>
                            <div [ngClass]="{' col' : user.isActivated, ' col-lg-8' : !user.isActivated}">
                                <p class="modal_description">Preencha as suas informações <br><span> e participe!</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <!--*ngIf="user.isActivated"-->
                    <div class="col flex-grow-0">
                        <a href="javascript:void('');" data-dismiss="modal" *ngIf="user.isActivated">
                            <svg-icon src="assets/svg/close-circle.svg"></svg-icon>
                        </a>
                        <a href="javascript:void('');" (click)="onLogout()" *ngIf="!user.isActivated">
                            <svg-icon src="assets/svg/close-circle.svg"></svg-icon>
                        </a>
                    </div>
                </div>

                <div class="modal_card">
                    <div class="row">
                        <div class="col-lg">
                            <div class="input-material">
                                <input id="cnpj" class="form-control" name="name" type="text"
                                       [readOnly]="true"
                                       [(ngModel)]="user.cnpj"
                                       required/>
                                <label class="disabled" for="cnpj">CNPJ</label>
                                <svg-icon class="right" src="assets/svg/padlock.svg"></svg-icon>
                            </div>
                        </div>
                        <div class="col-lg">
                            <div class="input-material">
                                <input id="corporate" class="form-control" name="name" type="text"
                                       [readOnly]="true"
                                       [(ngModel)]="user.companyName"
                                       required/>
                                <label class="disabled" for="corporate">Razão Social</label>
                                <svg-icon class="right" src="assets/svg/padlock.svg"></svg-icon>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="input-material">
                                <input id="range" class="form-control" name="name" type="text"
                                       [readOnly]="true"
                                       [(ngModel)]="user.range"
                                       required/>
                                <label class="disabled" for="range">Classe</label>
                                <svg-icon class="right" src="assets/svg/padlock.svg"></svg-icon>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="input-material">
                                <input id="cep" class="form-control" name="name" type="text"
                                       [(ngModel)]="user.cep"
                                       [disabled]="true"
                                       [mask]="'00000-000'"
                                       required/>
                                <label class="disabled" for="cep">CEP*</label>
                                <svg-icon class="right" src="assets/svg/padlock.svg"></svg-icon>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="input-material">
                                <input id="street" class="form-control" name="name" type="text"
                                       [(ngModel)]="user.address"
                                       [disabled]="true"
                                       required/>
                                <label class="disabled" for="street">Rua*</label>
                                <svg-icon class="right" src="assets/svg/padlock.svg"></svg-icon>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="input-material">
                                <input id="number" class="form-control" name="name" type="text"
                                       [(ngModel)]="user.addressNumber"
                                       [disabled]="true"
                                       required/>
                                <label class="disabled" for="number">Número*</label>
                                <svg-icon class="right" src="assets/svg/padlock.svg"></svg-icon>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="input-material">
                                <input id="district" class="form-control" name="name" type="text"
                                       [(ngModel)]="user.district"
                                       [disabled]="true"
                                       required/>
                                <label class="disabled" for="district">Bairro*</label>
                                <svg-icon class="right" src="assets/svg/padlock.svg"></svg-icon>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="input-material">
                                <input id="city" class="form-control" name="name" type="text"
                                       [(ngModel)]="user.city"
                                       [disabled]="true"
                                       required/>
                                <label class="disabled" for="city">Cidade*</label>
                                <svg-icon class="right" src="assets/svg/padlock.svg"></svg-icon>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="input-material">
                                <input id="uf" class="form-control" name="name" type="text"
                                       [(ngModel)]="user.uf"
                                       [disabled]="true"
                                       required/>
                                <label class="disabled" for="uf">UF*</label>
                                <svg-icon class="right" src="assets/svg/padlock.svg"></svg-icon>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="input-material">
                                <input id="complement" class="form-control" name="name" type="text"
                                       [(ngModel)]="user.complement"
                                       [disabled]="true"
                                       required/>
                                <label class="disabled" for="complement">Complemento</label>
                                <svg-icon class="right" src="assets/svg/padlock.svg"></svg-icon>
                            </div>
                        </div>
                        <div class="col-12">
                            <small class="size-sm text-white"><i>Endereço conforme cadastro da ANP, caso tenha alteração, por favor enviar e-mail para sac@juntosnogas.com.br ou whatsapp (11) 91782-4900</i></small>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center mt-3 mb-2" style="gap: 10px">
                    <svg-icon src="assets/svg/user-circle.svg"></svg-icon>
                    <h2 class="modal_title secondary">Meus dados</h2>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <div class="input-material">
                            <input id="cpf" class="form-control" name="name" type="text"
                                   [mask]="'000.000.000-00'"
                                   [dropSpecialCharacters]="false"
                                   [clearIfNotMatch]="true"
                                   (blur)="onClearUser($event, 'cpf')"
                                   [(ngModel)]="user.cpf"
                                   required/>
                            <label for="cpf">CPF*</label>
                        </div>
                    </div>
                    <div class="col-lg">
                        <div class="input-material">
                            <input id="name" class="form-control" name="name" type="text"
                                   [(ngModel)]="user.name"
                                   required/>
                            <label for="name">Nome Completo*</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <div class="input-material select">
                            <!--<input id="office" class="form-control" name="name" type="text"
                                   [(ngModel)]="user.resaleJobRole"
                                   required/>-->
                            <select id="office" class="form-control" required
                                   [(ngModel)]="user.resaleJobRole">
                                <option [ngValue]="undefined" disabled value hidden selected></option>
                                <option [ngValue]="'Administrativo'">Administrativo</option>
                                <option [ngValue]="'Gerente'">Gerente</option>
                                <option [ngValue]="'Proprietário/Sócio'">Proprietário/Sócio</option>
                                <option [ngValue]="'Supervisor'">Supervisor</option>
                                <option [ngValue]="'Outros'">Outros</option>
                            </select>
                            <label for="office">Cargo*</label>
                        </div>
                    </div>
                    <div class="col-md">
                        <div class="input-material">
                            <input id="phone" class="form-control" name="name" type="text"
                                   [dropSpecialCharacters]="false"
                                   [clearIfNotMatch]="true"
                                   (blur)="onClearUser($event, 'phone')"
                                   [(ngModel)]="user.phone"
                                   mask="(00) 0000-0000"
                                   required/>
                            <label for="phone">DDD + Telefone fixo</label>
                        </div>
                    </div>
                    <div class="col-md">
                        <div class="input-material">
                            <input id="cellPhone" class="form-control" name="name" type="text"
                                   [dropSpecialCharacters]="false"
                                   [clearIfNotMatch]="true"
                                   (blur)="onClearUser($event, 'cellphone')"
                                   [(ngModel)]="user.cellphone"
                                   mask="(00) 0000-0000||(00) 0 0000-0000"
                                   required/>
                            <label for="cellPhone">DDD + Telefone celular*</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div [ngClass]="user.isActivated?'col-lg-9':'col-lg-6'">
                        <div class="input-material">
                            <input id="email" class="form-control" name="name" type="text"
                                   [(ngModel)]="user.email"
                                   required/>
                            <label for="email">E-mail*</label>
                        </div>
                    </div>
                    <div class="col-lg-6" *ngIf="!user.isActivated">
                        <div class="input-material">
                            <input id="emailConfirm" class="form-control" name="name" type="text"
                                   [(ngModel)]="userConfirmEmail"
                                   required/>
                            <label for="emailConfirm">Confirmar e-mail*</label>
                        </div>
                    </div>
                    <div class="col-lg-3" *ngIf="user.isActivated">
                        <a class="btn btn-block btn-white modal_btn" (click)="onModalNewPassword()">
                            Editar senha</a>
                    </div>
                </div>

                <div class="row" style="row-gap: 20px">
                    <div class="col-lg-8">
                        <div class="row">
                            <div class="col-6">
                                <label class="input_check">
                                    <input type="checkbox"
                                           [value]="true"
                                           [disabled]="user.isActivated"
                                           [checked]="user.acceptRegulation === true"
                                           [(ngModel)]="user.acceptRegulation">
                                    <span class="checkmark">
                                        <svg-icon src="assets/svg/check.svg"></svg-icon>
                                    </span>
                                    <a href="javascript:$('#modalTypeText').modal('show'); void('')"
                                       (click)="modalTypeText = 'regulation'"
                                       class="modal_text check">Aceito o <span>regulamento</span></a>
                                </label>
                            </div>
                            <div class="col-6">
                                <label class="input_check">
                                    <input type="checkbox"
                                           [value]="true"
                                           [disabled]="user.isActivated"
                                           [checked]="user.acceptCookies === true"
                                           [(ngModel)]="user.acceptCookies">
                                    <span class="checkmark">
                                        <svg-icon src="assets/svg/check.svg"></svg-icon>
                                    </span>
                                    <a href="javascript:$('#modalTypeText').modal('show'); void('')"
                                       (click)="modalTypeText = 'terms'"
                                       class="modal_text check"><span>Politicas de Cookies</span></a>
                                </label>
                            </div>
                            <div class="col-6">
                                <label class="input_check">
                                    <input type="checkbox"
                                           [value]="true"
                                           [disabled]="user.isActivated"
                                           [checked]="user.acceptLgpd === true"
                                           [(ngModel)]="user.acceptLgpd">
                                    <span class="checkmark">
                                        <svg-icon src="assets/svg/check.svg"></svg-icon>
                                    </span>
                                    <a href="javascript:$('#modalTypeText').modal('show'); void('')"
                                       (click)="modalTypeText = 'lgpd'"
                                       class="modal_text check"><span>LGPD</span></a>
                                </label>
                            </div>

                        </div>
                    </div>
                    <div class="col-lg-4">
                        <a class="btn btn-block btn-white modal_btn" (click)="actionSaveUser()">Próximo</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Modal Nova Senha-->
<div class="modal-custom modal fade" id="modalNewPassword" data-keyboard="false"
     data-backdrop="static" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content modal_comment">
            <div class="row">
                <div class="col-12 col-lg-4">
                    <div class="modal_col-primary">
                        <a data-dismiss="modal" class="modal_close ml-auto d-lg-none" aria-label="Close">
                            <svg-icon [svgStyle]="{'fill':'#006F4D'}"
                                      src="assets/svg/close-circle.svg"></svg-icon>
                        </a>
                        <img src="assets/images/logo.png" style="width: 279px;" alt="logo">
                        <h2 class="modal_title text-white mb-3">Editar Senha</h2>
                    </div>
                </div>
                <div class="col-12 col-lg-8">
                    <div class="d-flex flex-column justify-content-between h-100 p-4">
                        <a data-dismiss="modal" class="modal_close d-none d-lg-flex justify-content-end"
                           aria-label="Close">
                            <svg-icon src="assets/svg/close-circle.svg"></svg-icon>
                        </a>
                        <div class="px-lg-5">
                            <div class="input-material light">
                                <input [(ngModel)]="changePasswordInput.password" id="password" name="name"
                                       type="password" required
                                       class="form-control ng-dirty ng-valid ng-touched">
                                <a href="javascript:void(0)" class="btn p-0 icon"
                                   (click)="handlerViewPassword('password')"
                                   style="position: absolute;top: 8px; right: 6px;">
                                    <svg-icon [svgStyle]="{'fill':'#00BB7F'}"
                                            [src]="!handlerViewPasswordIcon('password') ? 'assets/svg/password-open.svg' : 'assets/svg/password-closed.svg'"></svg-icon>
                                </a>
                                <label for="password">Senha atual</label>
                            </div>
                            <div class="input-material light">
                                <input [(ngModel)]="changePasswordInput.newPassword" id="newPassword" name="name"
                                       type="password" required
                                       class="form-control ng-dirty ng-valid ng-touched">
                                <a href="javascript:void(0)" class="btn p-0 icon"
                                   (click)="handlerViewPassword('newPassword')"
                                   style="position: absolute;top: 8px; right: 6px;">
                                    <svg-icon [svgStyle]="{'fill':'#00BB7F'}"
                                            [src]="!handlerViewPasswordIcon('newPassword') ? 'assets/svg/password-open.svg' : 'assets/svg/password-closed.svg'"></svg-icon>
                                </a>
                                <label for="newPassword">Nova Senha</label>
                            </div>
                            <div class="input-material light">
                                <input [(ngModel)]="confirmPass" id="passwordConfirm" name="name" type="password"
                                       required
                                       class="form-control ng-dirty ng-valid ng-touched">
                                <a href="javascript:void(0)" class="btn p-0 icon"
                                   (click)="handlerViewPassword('passwordConfirm')"
                                   style="position: absolute;top: 8px; right: 6px;">
                                    <svg-icon [svgStyle]="{'fill':'#00BB7F'}"
                                            [src]="!handlerViewPasswordIcon('passwordConfirm') ? 'assets/svg/password-open.svg' : 'assets/svg/password-closed.svg'"></svg-icon>
                                </a>
                                <label for="passwordConfirm">Confirmar Senha</label>
                            </div>
                        </div>
                        <div class="px-lg-5">
                            <a class="btn btn-block btn-white mb-4" (click)="actionUpdatePassword()"
                               href="javascript:void(0);">Alterar</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<!--Modal Regulamento | Terms | Lgpd-->
<div class="modal-custom-secondary modal fade" id="modalTypeText" data-keyboard="false"
     data-backdrop="static" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content bg-white">
            <a data-dismiss="modal" class="modal_close ml-auto" aria-label="Close">
                <svg-icon [svgStyle]="{'fill':'#006F4D'}"
                          src="assets/svg/close-circle.svg"></svg-icon>
            </a>
            <div class="modal-box-text">
                <div class="regulation">
                    <div class="regulation_content">
                        <app-regulation-text *ngIf="modalTypeText === 'regulation'"></app-regulation-text>
                        <app-terms-text *ngIf="modalTypeText === 'terms'"></app-terms-text>
                        <app-lgpd-text *ngIf="modalTypeText === 'lgpd'"></app-lgpd-text>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

