<div class="accordion" *ngIf="false">
    <div class="accordion-item  mb-5">
        <a href="#questao_1" data-toggle="collapse"
           class="faq_question  collapsed d-flex justify-content-between ">
            <span class="number">1</span>
            <div class="faq_question-content"><span
                    class="p-3">Como participar?</span>
                <div id="questao_1" class="collapse faq_anwser p-3">
                    <p>
                        Preencha seus dados no campo “CADASTRE-SE”, envie fotos da sua revenda
                        antes da padronização, faça a aplicação,
                        comprove através de fotos e pronto!
                    </p>
                </div>
            </div>

            <span class="icon">
                                      <svg-icon src="assets/svg/drop.svg"></svg-icon>
                                    </span>
        </a>

    </div>
    <div class="accordion-item  mb-5">
        <a href="#questao_2" data-toggle="collapse"
           class="faq_question  collapsed d-flex justify-content-between ">
            <span class="number">2</span>
            <div class="faq_question-content"><span
                    class="p-3"> Qual o período da campanha?</span>
                <div id="questao_2" class="collapse faq_anwser p-3">
                    <p>
                        A campanha será iniciada no dia 20 de março de 2023 e encerrada em 31 de
                        dezembro de 2023.
                    </p>
                </div>
            </div>

            <span class="icon">
                                      <svg-icon src="assets/svg/drop.svg"></svg-icon>
                                    </span>
        </a>

    </div>
    <div class="accordion-item  mb-5">
        <a href="#questao_3" data-toggle="collapse"
           class="faq_question  collapsed d-flex justify-content-between ">
            <span class="number">3</span>
            <div class="faq_question-content"><span
                    class="p-3"> Quais os prêmios que posso ganhar?</span>
                <div id="questao_3" class="collapse faq_anwser p-3">
                    <p>
                        Além de receber os brindes acima, concorrerão aos prêmios, através de
                        sorteio
                        correspondentes à Classe ANP para a qual foram designados
                    </p>


                </div>
            </div>

            <span class="icon">
                                      <svg-icon src="assets/svg/drop.svg"></svg-icon>
                                    </span>
        </a>

    </div>
    <div class="accordion-item  mb-5">
        <a href="#questao_4" data-toggle="collapse"
           class="faq_question  collapsed d-flex justify-content-between ">
            <span class="number">4</span>
            <div class="faq_question-content"><span
                    class="p-3"> Como padronizar minha revenda?</span>
                <div id="questao_4" class="collapse faq_anwser p-3">
                    <p>
                        Basta realizar as pinturas e instalação de placas conforme o guia que
                        está no site juntosnogas.com.br.
                    </p>
                </div>
            </div>

            <span class="icon">
                                      <svg-icon src="assets/svg/drop.svg"></svg-icon>
                                    </span>
        </a>

    </div>
    <div class="accordion-item  mb-5">
        <a href="#questao_5" data-toggle="collapse"
           class="faq_question  collapsed d-flex justify-content-between ">
            <span class="number">5</span>
            <div class="faq_question-content"><span
                    class="p-3">Qual o prazo para que devo comprovar a padronização? </span>
                <div id="questao_5" class="collapse faq_anwser p-3">
                    <p>
                        Para a premiação, o prazo final é comprovar até dia 31/07/23.

                    </p>
                </div>
            </div>

            <span class="icon">
                                      <svg-icon src="assets/svg/drop.svg"></svg-icon>
                                    </span>
        </a>

    </div>
    <div class="accordion-item  mb-5">
        <a href="#questao_6" data-toggle="collapse"
           class="faq_question  collapsed d-flex justify-content-between ">
            <span class="number">6</span>
            <div class="faq_question-content"><span
                    class="p-3">Como comprovar a padronização das frotas?</span>
                <div id="questao_6" class="collapse faq_anwser p-3">

                    <p> • Motos e triciclos: duas laterais e placa do veículo visível conforme o
                        padrão do Manual de
                        Padronização.</p>
                    <p> • Demais veículos leves (que atendem ao consumidor final, como HR,
                        pick-ups e afins): frente
                        e traseira com placa do veículo visível e suas duas laterais.</p>
                    <p> • Veículos pesados (caminhões e afins que fazem transporte do produto
                        entre revenda e
                        distribuidora, sem atender ao consumidor final): em acordo com o Manual
                        de Padronização ou
                        descaracterização / retirada da antiga marca Liquigás.</p>
                    <p> • Veículos com marca própria da revenda só serão aceitos se estiverem de
                        acordo com o Manual
                        de Padronização.</p>

                </div>
            </div>
            <span class="icon">
                                      <svg-icon src="assets/svg/drop.svg"></svg-icon>
                                    </span>
        </a>

    </div>

    <div class="accordion-item  mb-5">
        <a href="#questao_7" data-toggle="collapse"
           class="faq_question  collapsed d-flex justify-content-between ">
            <span class="number">7</span>
            <div class="faq_question-content"><span
                    class="p-3">Eu tenho uma revenda que já está com o novo padrão, como fica neste caso?</span>
                <div id="questao_7" class="collapse faq_anwser p-3">
                    <p>
                        Sem problemas. A nossa auditoria vai avaliar, e neste caso existem duas
                        possibilidades:
                        <br>
                        1ª se houver necessidade de algum ajuste, você receberá a oritentação.
                        Daí é só comprovar
                        com
                        novas fotos e concorrer aos prêmios.
                        <br>
                        2ª Se as fotos iniciais comprovarem que está tudo correto na aplicação
                        do novo padrão, a auditoria fará a aprovação e você já está concorrendo
                        aos prêmios.

                    </p>
                </div>
            </div>
            <span class="icon">
                                      <svg-icon src="assets/svg/drop.svg"></svg-icon>
                                    </span>
        </a>

    </div>
    <div class="accordion-item  mb-5">
        <a href="#questao_8" data-toggle="collapse"
           class="faq_question  collapsed d-flex justify-content-between ">
            <span class="number">8</span>
            <div class="faq_question-content"><span
                    class="p-3">Se tiver dúvidas de como utilizar os materiais da padronização, com quem posso falar?</span>
                <div id="questao_8" class="collapse faq_anwser p-3">
                    <p>
                        Deixamos disponível no site um manual de como e onde aplicar as tintas e
                        testeira que vamos enviar. Se ainda restar dúvida, pode mandar um e-mail
                        para o sac da campanha. sac@juntosnogas.com.br

                    </p>
                </div>
            </div>
            <span class="icon">
                                      <svg-icon src="assets/svg/drop.svg"></svg-icon>
                                    </span>
        </a>

    </div>
    <div class="accordion-item  mb-5">
        <a href="#questao_9" data-toggle="collapse"
           class="faq_question  collapsed d-flex justify-content-between ">
            <span class="number">9</span>
            <div class="faq_question-content"><span
                    class="p-3">A minha revenda é toda OU boa parte dela em gradil, como fica a pintura interna? </span>
                <div id="questao_9" class="collapse faq_anwser p-3">
                    <p>
                        As revendas que têm a parte de dentro exposta, receberá material para
                        pintura interna e do gradil. Por isso, inclua as medidas no site caso
                        tenha essa condição na sua revenda.

                    </p>
                </div>
            </div>
            <span class="icon">
                                      <svg-icon src="assets/svg/drop.svg"></svg-icon>
                                    </span>
        </a>

    </div>
    <div class="accordion-item  mb-5">
        <a href="#questao_10" data-toggle="collapse"
           class="faq_question  collapsed d-flex justify-content-between ">
            <span class="number">10</span>
            <div class="faq_question-content"><span
                    class="p-3">Como será feito o sorteio das 6 viagens para a Copa?</span>
                <div id="questao_10" class="collapse faq_anwser p-3">
                    <p>
                        O sorteio será por cupom. Os cupons passam a ser distribuídos a partir
                        da aprovação pela auditoria da padronização da revenda. Padronizou ganha
                        cupom.
                    </p>
                </div>
            </div>
            <span class="icon">
                                      <svg-icon src="assets/svg/drop.svg"></svg-icon>
                                    </span>
        </a>

    </div>
</div>

<ng-container *ngIf="user.resaleType === 'Novo Negócio' && user.branding === 'Liquigás'"> <!--Novos Negocios Liquigas-->
    <div class="accordion">
        <div class="accordion-item mb-4" *ngFor="let x of arrNewLiq; let i = index;">
            <a href="#nl_{{i}}" data-toggle="collapse"
               class="faq_question  collapsed d-flex justify-content-between ">
                <span class="number">{{x.number}}</span>
                <div class="faq_question-content">
                    <span class="faq_question-title p-3">{{x.title}}</span>
                    <div id="nl_{{i}}" class="collapse faq_anwser p-3" [innerHTML]="x.message">
                    </div>
                </div>
                <span class="icon">
                  <svg-icon src="assets/svg/drop.svg"></svg-icon>
                </span>
            </a>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="user.resaleType === 'Novo Negócio' && user.branding !== 'Liquigás'"> <!--Novos Negocios Copagaz-->
    <div class="accordion">
        <div class="accordion-item mb-4" *ngFor="let x of arrNewCop; let i = index;">
            <a href="#nc_{{i}}" data-toggle="collapse"
               class="faq_question  collapsed d-flex justify-content-between ">
                <span class="number">{{x.number}}</span>
                <div class="faq_question-content">
                    <span class="faq_question-title p-3">{{x.title}}</span>
                    <div id="nc_{{i}}" class="collapse faq_anwser p-3" [innerHTML]="x.message">
                    </div>
                </div>
                <span class="icon">
                  <svg-icon src="assets/svg/drop.svg"></svg-icon>
                </span>
            </a>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="user.resaleType !== 'Novo Negócio'"> <!--Debrand-->
    <div class="accordion">
        <div class="accordion-item mb-4" *ngFor="let x of arrDebrand; let i = index;">
            <a href="#db_{{i}}" data-toggle="collapse"
               class="faq_question  collapsed d-flex justify-content-between ">
                <span class="number">{{x.number}}</span>
                <div class="faq_question-content">
                    <span class="faq_question-title p-3">{{x.title}}</span>
                    <div id="db_{{i}}" class="collapse faq_anwser p-3" [innerHTML]="x.message">
                    </div>
                </div>
                <span class="icon">
                  <svg-icon src="assets/svg/drop.svg"></svg-icon>
                </span>
            </a>
        </div>
    </div>
</ng-container>
