import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {
    ActivateUserGQL,
    ActivateUserInput,
    ChangePasswordGQL, ChangePasswordInput, User
} from "../../../generated/graphql";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "../../service/user.service";
import {NgxSpinnerService} from "ngx-spinner";

declare var $: any;

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent extends BaseComponent implements OnInit, AfterViewInit {

    user: User = new User();
    userOrig: User = new User();
    userConfirmEmail = '';
    changePasswordInput: ChangePasswordInput = new ChangePasswordInput();
    confirmPass: string;
    modalTypeText = null;

    constructor(public router: Router,
                public translate: TranslateService,
                public loading: NgxSpinnerService,
                public userService: UserService,
                public changePasswordGQL: ChangePasswordGQL,
                private activateUserGQL: ActivateUserGQL) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = Object.assign({}, data as User);
                this.userOrig = Object.assign({}, data as User);
            }, error: err => super.onError(err)
        });
        $(() => {
            $(document).on('hidden.bs.modal', function () {
                if($('.modal.show').length) {
                    $('body').addClass('modal-open');
                }
            });
        })
    }

    ngAfterViewInit(): void {
        $('#modalFirstAccess').on('show.bs.modal', () => {
            this.user = Object.assign({}, this.userOrig as User);
        });
    }

    actionSaveUser(): void {
        let arrValidateFields = [
            {value: this.user.cpf, text: `CPF <br>`},
            {value: this.user.name, text: `Nome completo <br>`},
            {value: this.user.resaleJobRole, text: `Cargo <br>`},
            /*{value: this.user.phone, text: `Telefone fixo <br>`},*/
            {value: this.user.cellphone, text: `Telefone celular <br>`},
            {value: this.user.email, text: `E-mail <br>`},
            {value: this.user.cep, text: `CEP <br>`},
            {value: this.user.address, text: `Endereço <br>`},
            {value: this.user.addressNumber, text: `Número <br>`},
            {value: this.user.district, text: `Bairro <br>`},
            {value: this.user.city, text: `Cidade <br>`},
            {value: this.user.uf, text: `Estado <br>`},
        ];

        if (!this.user.isActivated) {
            arrValidateFields.push({value: this.userConfirmEmail, text: `Confirmar e-mail <br>`});
        }

        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção',
                `Preencha os campos obrigatórios corretamente: <br> ${stringError}`);
            return;
        }
        if (!this.isCPFValid(this.user.cpf)) {
            return;
        }
        if (!this.isEMailValid(this.user.email)) {
            return;
        }
        if (!this.user.isActivated) {
            if (this.user.email !== this.userConfirmEmail) {
                this.showMessage('Atenção',
                    `Os e-mails não são iguais, preencha corretamente para prosseguir.`);
                return;
            }
        }
        if (!this.user.isActivated && (
            !this.user.acceptRegulation ||
            !this.user.acceptCookies ||
            !this.user.acceptLgpd)
        ) {
            this.showMessage('Atenção',
                `É necessário aceitar os termos para prosseguir.`);
            return;
        }
        this.onSaveUser();
    }

    onSaveUser(): void {
        this.loading.show();
        const a: ActivateUserInput = new ActivateUserInput();
        a.cpf = this.user.cpf;
        a.name = this.user.name;
        a.resaleJobRole = this.user.resaleJobRole;
        a.phone = this.user.phone;
        a.cellphone = this.user.cellphone;
        a.email = this.user.email;
        a.acceptCookies = this.user.acceptCookies;
        a.acceptRegulation = this.user.acceptRegulation;
        a.acceptLgpd = this.user.acceptLgpd;
        a.cep = this.user.cep;
        a.address = this.user.address;
        a.addressNumber = this.user.addressNumber;
        a.district = this.user.district;
        a.city = this.user.city;
        a.uf = this.user.uf;
        a.complement = this.user.complement;
        this.activateUserGQL.mutate({input: a}).subscribe(
            (data) => {
                $('html, body').animate({ scrollTop: 0 }, 500);
                this.closeModal('modalFirstAccess');
                this.loading.hide();
                this.showMessage('Sucesso!',
                    `Suas informações foram atualizadas com sucesso.`, 'success');
            }, err => super.onError(err)
        );
    }

    onClearUser(event: FocusEvent, str: string) {
        setTimeout(() => {
            const a = $(event.target).val();
            if (super.isNullOrUndefined(a)) {
                this.user[str] = '';
            }
        });
    }

    actionUpdatePassword() {
        const arrValidateFields = [
            {value: this.changePasswordInput.password, text: `Senha atual<br>`},
            {value: this.changePasswordInput.newPassword, text: `Nova senha <br>`},
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção',
                `Preencha os campos obrigatórios corretamente: <br> ${stringError}`);
            return;
        }
        if (this.changePasswordInput.newPassword.length < 4) {
            this.showMessage('Atenção', `Senha deve possuir ao menos 4 carácter`);
            return;
        }
        if (this.changePasswordInput.newPassword != this.confirmPass) {
            this.showMessage('Atenção', `Senhas não conferem`);
            return;
        }
        this.loading.show();
        this.changePasswordGQL.mutate({input: this.changePasswordInput}).subscribe(({data}) => {
            this.closeModal('modalNewPassword');
            this.loading.hide();
            this.showMessage('Sucesso!', data.changePassword.message, 'success');
        }, error => super.onError(error));
    }

    onModalNewPassword() {
        this.showModal('modalNewPassword');
        this.changePasswordInput = new ChangePasswordInput();
        this.confirmPass = undefined;
    }
}
