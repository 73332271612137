<div #testDiv></div>
<div class="footer">
    <div class="row align-items-center">
        <div class="col-lg-auto">
            <div class="logo logo-primary">
                <img style="min-width: 171px;" src="assets/images/{{(user?.profile === 'EC' || user?.profile === 'GVE') ? 'logo-copaenergia' : user?.branding === 'Liquigás' ? 'logo2' : 'logo2-copagaz'}}.png" alt="Juntos no Gás" />
            </div>
        </div>
        <div class="col">
            <div class="d-flex">
                <nav class="nav">
                    <ul class="nav-list footer_nav">
                        <li class="nav-item">
                            <a [routerLink]="'/'" (click)="routerNavigateHomePage('home')"
                               class="nav-link">Home</a>
                        </li>
                        <li class="nav-item">
                            <a (click)="routerNavigateHomePage('comoparticipar')" href="javascript:void('');"
                               class="nav-link">Como Participar</a>
                        </li>
                        <li class="nav-item">
                            <a (click)="routerNavigateHomePage('ganhadores')" href="javascript:void('');"
                               class="nav-link">Ganhadores</a>
                        </li>
                        <li class="nav-item" *ngIf="(user.profile !== 'EC' && user.profile !== 'GVE' && user.profile !== 'MA') && user?.resaleType !== 'Novo Negócio'">
                            <a [routerLink]="'/coupon'" href="javascript:void('');" class="nav-link">Cupons</a>
                        </li>
                        <li class="nav-item" *ngIf="true">
                            <a (click)="routerNavigateHomePage('faq')" href="javascript:void('');"
                               class="nav-link">FAQ</a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void('');" (click)="onModalContact()"
                               class="nav-link">Contato</a>
                        </li>
                        <li class="nav-item ">
                            <a href="#" [routerLink]="handlerRoute(user?.profile)" href="javascript:void('');"
                               class="nav-link participate">Participe do Programa</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="divider"></div>
            <div class="row">
                <nav class="nav">
                    <ul class="nav-list footer_nav">
                        <li class="nav-item">
                            <a [routerLink]="'/regulation'" href="#" target="_blank"
                               class="nav-link"><b>Regulamento</b></a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="'/lgpd'" href="#" target="_blank"
                               class="nav-link"><b>LGPD</b></a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="'/terms'" href="#" target="_blank"
                               class="nav-link"><b>Política de Cookies</b></a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void('');" class="nav-link" (click)="showModal('modalDownload')"><b>Download</b></a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        <div class="col-lg-auto">
            <div class="logo logo-secondary">
                <img src="assets/images/logocopagaz.png" style=" max-width: 205px;" alt="">
            </div>
        </div>
    </div>
    <div class="comment" [ngClass]="isTestDivScrolledIntoView?'absolute':'fixed'"
         *ngIf="flagShowChat">
        <div class="comment_header">
            <svg-icon src="assets/svg/logo2.svg"></svg-icon>
            <p class="comment_title">Comentários do Auditor</p>
            <svg-icon class="comment_close d-none d-lg-block" src="assets/svg/arrow-down.svg" [svgStyle]="{ 'width.px': 10 }" (click)="onCloseComment()"></svg-icon>
        </div>
        <ng-container *ngIf="isNullOrUndefined(standartMessage)">
            <div class="d-flex px-2 comment_default" style="gap: 23px">
                <svg-icon src="assets/svg/comment.svg"></svg-icon>
                <p class="comment_description">
                    Olá, aqui o auditor informará se
                    há ajustes para serem realizados em sua <span>adesão ou
                    padronização</span>, fique atento.
                </p>
            </div>
        </ng-container>
        <ng-container *ngIf="!isNullOrUndefined(standartMessage)">
            <div class="comment_scroll">
                <ng-container *ngFor="let m of standartMessage, let i = index">
                    <div *ngIf="i == 0" class="d-flex justify-content-between align-items-center mb-2"
                         style="gap: 20px">
                        <div class="d-flex align-items-center" style="gap: 12px">
                            <svg-icon [svgStyle]="{'width': '27px'}" src="assets/svg/logo2.svg"></svg-icon>
                            <span class="comment_name">{{m.createdBy.name}}</span>
                        </div>
                    </div>
                    <div class="comment_card mb-3">
                        <div style="padding: 1rem 1.5rem 0.5rem;">
                            <p class="comment_card_date mb-0 text-right">{{m.createdAt |date:'dd/MM/yyyy'}}</p>
                            <p class="comment_card_subtitle mb-2">{{m.title}}</p>
                            <p class="comment_card_description">{{m.message}}</p>
                            <ng-container *ngIf="isNullOrUndefined(m.reply)">
                                <div style="background-color: #d6d6d6;width: 100%;height: 1px;margin: 0.7rem 0;">&nbsp;
                                </div>
                                <p (click)="handlerResponse(m)"
                                   class="comment_card_response">Responder</p>
                            </ng-container>

                        </div>

                        <div *ngIf="!isNullOrUndefined(m.reply)" class="bg-secondary rounded-bottom px-4 py-2">
                            <p class="response_title">Resposta</p>
                            <p class="response_text">{{m.reply}}</p>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>


<div class="modal-custom modal fade" id="modalComments" data-keyboard="false"
     data-backdrop="static" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content modal_comment">
            <div class="row">

                <div class="col-12 col-lg-4">
                    <div class="modal_col-primary">
                        <a data-dismiss="modal" class="modal_close ml-auto d-lg-none" aria-label="Close">
                            <svg-icon [svgStyle]="{'fill':'#006F4D'}" class="svg-close mt-2"
                                      src="assets/svg/close-circle.svg"></svg-icon>
                        </a>
                        <img src="assets/images/logo.png" style="width: 279px;" alt="logo">
                        <h2 class="modal_title text-white mb-3">Mural Liquigás</h2>
                    </div>
                </div>

                <div class="col-12 col-lg-8">
                    <div class="p-4 pr-lg-5">
                        <div style="gap: 10px"
                             class="d-flex flex-column-reverse flex-lg-row justify-content-between align-items-lg-center">
                            <div class="d-flex align-items-center" style="gap: 20px">
                                <svg-icon src="assets/svg/logo2-white.svg"></svg-icon>
                                <p class="modal_comment_title">{{modalMessage.createdBy?.name}}</p>
                            </div>
                            <div class="d-flex align-items-center justify-content-between" style="gap: 20px">
                                <p class="modal_comment_date">{{modalMessage.createdAt|date:'dd / MM / yyyy'}}
                                    às {{modalMessage.createdAt|date:'HH:mm'}}</p>
                                <a data-dismiss="modal" class="modal_close ml-auto d-none d-lg-flex" aria-label="Close">
                                    <svg-icon class="svg-close mt-2" src="assets/svg/close-circle.svg"></svg-icon>
                                </a>
                            </div>
                        </div>
                        <p class="modal_comment_quest">{{getItemNameByType(modalMessage.type)}}</p>
                        <p class="modal_comment_descriptions">{{modalMessage.message}}</p>
                        <div class="input-material"
                             style="padding-top: 26px;">
                            <textarea id="field1" [(ngModel)]="textResponse" style="resize: none;"></textarea>
                            <label for="field1">Escreva aqui sua mensagem</label>
                        </div>
                        <div>
                            <a (click)="actionResponse()" class="btn btn-block btn-white mb-4"
                               href="javascript:void(0);">Enviar</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


