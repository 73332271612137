import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import {CreateContactInput, User} from '../../../generated/graphql';

declare var $: any;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit {

    user: User = new User();
    createContactInput: CreateContactInput = new CreateContactInput();

    constructor(public router: Router,
                public translate: TranslateService,
                public loading: NgxSpinnerService,
                public userService: UserService
    ) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data?.id)) {
                    this.user = data as User;
                }
            }, error: err => super.onError(err)
        });
        $(() => {
            $('#modalContact').on('shown.bs.modal', () => {
                this.createContactInput = new CreateContactInput();
                this.createContactInput.cnpj = this.user.cnpj;
                this.createContactInput.name = this.user.name;
                this.createContactInput.email = this.user.email;
                this.createContactInput.phone = this.user.phone;
                this.createContactInput.userId = this.user.id;
            });
        });
    }

    actionNavToggle(): void {
        $('body').toggleClass('nav-open');
        $('.hamburger').toggleClass('open');
    }

    actionNavRemove(): void {
        $('body').removeClass('nav-open');
        $('.hamburger').removeClass('open');
    }

    onModalContact() {
        this.showModal('modalContact');
    }
}
