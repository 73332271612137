import {Component, Input, OnInit} from '@angular/core';
import {CreateContactGQL, CreateContactInput, ForgotPasswordGQL, LoginGQL} from "../../../generated/graphql";
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {NgxSpinnerService} from "ngx-spinner";
import {UserService} from "../../service/user.service";

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent extends BaseComponent implements OnInit {
    @Input()
    createContactInput: CreateContactInput;


    constructor(public router: Router,
                public translate: TranslateService,
                public loading: NgxSpinnerService,
                public service: UserService,
                private createContactGQL: CreateContactGQL) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
    }

    actionContact() {
        const arrValidateFields = [
            {value: this.createContactInput.name, text: `Nome Completo <br>`},
            {value: this.createContactInput.cnpj, text: `CNPJ <br>`},
            {value: this.createContactInput.email, text: `E-mail <br>`},
            {value: this.createContactInput.phone, text: `Telefone Whatsapp <br>`},
            {value: this.createContactInput.subject, text: `Assunto <br>`},
            {value: this.createContactInput.message, text: `Mensagem <br>`},
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção',
                `Preencha os campos obrigatórios corretamente: <br> ${stringError}`);
            return;
        }
        this.loading.show()
        this.createContactGQL.mutate({input: this.createContactInput}).subscribe(({data}) => {
            this.closeModal('modalContact');
            this.loading.hide();
            this.showMessage('Sucesso!', data.createContact.message, 'success');
        }, error => super.onError(error));
    }
}
