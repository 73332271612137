<div class="container padding_header">
    <div class="regulation">
        <div class="regulation_top">
            <div class="regulation_title">REGULAMENTO</div>
            <a href="assets/files/RG_Copa_Energia_Revendas_31.03.2_Final.pdf" target="_blank">
                <div class="regulation_download">
                    <div class="regulation_btn">
                        <span>Regulamento em PDF</span>

                        <b>Download</b>
                    </div>
                    <svg-icon src="assets/svg/download.svg"></svg-icon>
                </div>
            </a>

        </div>
        <div class="regulation_content">
            <app-regulation-text></app-regulation-text>
        </div>

    </div>
</div>

