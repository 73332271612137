<div class="container padding_header">
    <div class="regulation">
        <div class="regulation_top">
            <div class="regulation_title">TERMOS</div>
            <div class="regulation_download">
                <div class="regulation_btn">
                    <span>Regulamento em PDF</span>

                    <b>Download</b>
                </div>
                <svg-icon src="assets/svg/download.svg"></svg-icon>
            </div>
        </div>
        <div class="regulation_content">
            <app-terms-text></app-terms-text>
        </div>
    </div>
</div>

