<h2>Política de Privacidade <br>
</h2>
<p>A presente Política tem por finalidade demonstrar o compromisso da</p>
<div class="bg-quaternary">
    <p><strong>COPA ENERGIA  DISTRIBUIDORA DE GÁS S.A.<br>CNPJ: 03.237.583/0001-67</strong><br>
    <strong>Endereço: </strong>Avenida das Nações  Unidas, 14.171, Torre  C, 29º andar, conjuntos 2901, 2902,  2903 e 2904, Condomínio Rochaverá  Corporate Towers, CEP 04794-000 - São Paulo - SP.</p>
</div>

<p>Esta Política  de Privacidade (&ldquo;Política&rdquo;) é o documento  que demonstra o compromisso da Copa  Energia com a privacidade e segurança das informações quando  tratamos dados pessoais  ao prestar regularmente nossos serviços, inclusive on-line.</p>
<p>Nesta Política,  descrevemos como os seus dados  pessoais são coletados, utilizados, compar  tilhados e armazenados.</p>
<p><strong>NOTA ESPECIAL PARA CRIANÇAS  E ADOLESCENTES COM IDADE INFERIOR  A 18 ANOS</strong></p>
<p>  Por favor,  não se registre ou faça uso dos formulários e/ou serviços que exijam fornecimento de dados pessoais na  Plataforma caso tenha menos de 18 anos.</p>
<p><strong>NOTA ESPECIAL PARA OS REPRESENTANTES LEGAIS</strong></p>
<p>  Os representantes legais deverão supervisionar e representar as crianças e adolescentes com idade inferior a 16 (dezesseis) anos e  assistir os adolescentes em idade superior a 16 (dezesseis) anos e inferior a  18 (dezoito) anos, sempre que aplicável.</p>
<p>Como  condição para acesso e uso das funcionalidades exclusivas de nossas  Plataformas, Você declara que fez a leitura completa  e atenta desta Política, estando  plenamente ciente de sua integralidade e conferindo, assim,  sua livre e expressa concordância com os termos aqui estipulados, incluindo  a coleta dos Dados aqui mencionados, bem como sua utilização para os  fins abaixo especificados.<br>
    Caso Você não esteja de acordo com as disposições desta Política, Você deverá descontinuar o seu acesso ou uso da(s)  Plataforma(s).</p>
<h2 align="left">GLOSSÁRIO </h2>
<p><strong>Anonimização</strong>: Utilização de meios técnicos razoáveis e disponíveis no momento do  Tratamento, por meio dos quais um dado perde a possibilidade de associação,  direta ou indireta, a um indivíduo.<br>
    <strong><em>Cookies</em></strong>: Pequenos arquivos enviados  pelos sites, salvos  no seu dispositivo, que armazenam as preferências e outras  pequenas informações, com a  finalidade de personalizar sua navegação de acordo com o seu perfil.<br>
    <strong>Dado  Pessoal</strong>: É toda informação relacionada a uma pessoa  natural identificada ou identificável. Por  exemplo: nome, endereço, e-mail,  endereço IP, geolocalização, número do protocolo, número da conta, etc.<br>
    <strong>Encarregado</strong>: Pessoa indicada por nós para atuar como canal de comunicação entre a <strong>Copa  Energia</strong>, você e a Autoridade Nacional de Proteção de Dados (ANPD).<br>
    <strong>Endereço IP</strong>: Abreviatura  de <em>Internet Protocol</em>. É o conjunto alfanumérico que identifica os  dispositivos dos Usuários na Internet.<br>
    <strong>LGPD</strong>: <a href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/L13709compilado.htm">Lei Geral de  Proteção de Dados Pessoais – Lei n°. 13.709/18</a>, que dispõe sobre o  tratamento de dados pessoais em meios digitais ou não.<br>
    <strong>Site: </strong>é o endereço <a href="https://www.copaenergia.com.br/Â ">https://www.copaenergia.com.br/</a> e seus subdomínios.<br>
    <strong>Titular dos dados pessoais</strong>: É a  pessoa física a quem os dados pessoais se referem, no caso desta Política, o titular é você.<br>
    <strong>Tratamento</strong>: Toda operação realizada com dados pessoais,  como a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão,  distribuição, processam ento, arquivamento, armazenamento, eliminação, avaliação  ou controle da informação, modifi cação, comunicação, transferência,  difusão ou extração.</p>
<h2>SOBRE OS DADOS PESSOAIS  QUE COLETAMOS </h2>
<p>Os Dados poderão ser coletados quando  você interage em nosso Site.  Nós podemos coletar os seguintes Dados Pessoais:</p>
<table border="1" cellspacing="0" cellpadding="0">
    <tr>
        <td valign="top"><p align="center"><strong>Processo</strong><strong> </strong></p></td>
        <td valign="top"><p align="center"><strong>Finalidade</strong><strong> </strong></p></td>
        <td valign="top"><p><strong>Dados Pessoais Coletados</strong><strong> </strong></p></td>
    </tr>
    <tr>
        <td valign="top"><p>Fale conosco </p></td>
        <td valign="top"><p>Permitir    que você entre em contato conosco e responder suas mensagens e solicitações. </p></td>
        <td valign="top"><p>Nome completo Endereço CPF/CNPJ <br>
            E-mail Telefone </p></td>
    </tr>
    <tr>
        <td valign="top"><p>Seja Revendedor </p></td>
        <td valign="top"><p>Permitir que você entre em contato conosco e responder sua    solicitação para se tornar um revendedor </p></td>
        <td valign="top"><p>Nome completo E-mail <br>
            Celular </p></td>
    </tr>
    <tr>
        <td valign="top"><p>Ouvidoria </p></td>
        <td valign="top"><p>Permitir que você entre    em contato conosco e    responder suas solicitações </p></td>
        <td valign="top"><p>Nome completo CPF/CNPJ <br>
            E-mail Endereço Telefone <br>
            Código do Cliente </p></td>
    </tr>
    <tr>
        <td valign="top"><p align="center">Portal MI </p></td>
        <td valign="top"><p>Permitir    que você acesse o sistema de Medição individualizada </p></td>
        <td valign="top"><p>CPF <br>
            E-mail </p></td>
    </tr>
    <tr>
        <td valign="top"><p>Identificação Digital </p></td>
        <td valign="top"><p>Além de identificar e autenticar você, usamos estes dados para    cumprir com obrigações legais de manutenção de registros estabelecidas pelo Marco Civil da Internet  (Lei nº12.965/2014), bem como com as nossas obrigações legais e regulatórias. </p>
            Também utilizamos estes dados para  aprimorar nossas Plataformas.</td>
        <td valign="top"><p>Endereço IP e Porta    Lógica de Origem <br>
            Dispositivo Geolocalização <br>
            Registros de data e horário  <br>
            ID da sessão<br>
            Cookies </p></td>
    </tr>
</table>
<p><strong>Atualização e veracidade dos Dados. </strong>Você é o único responsável pela precisão, veracidade ou atualização dos Dados  que nos fornece. Nós não somos obrigados  a tratar os seus Dados se  houver razões para crer que tal Tratamento possa nos imputar infração de  qualquer lei aplicável, ou se você estiver utilizando nossos Sites para  quaisquer fins ilegais ou ilícitos.<br>
    <strong>Base  de Dados. </strong>A  base de dados formada por meio da coleta de Dados é de nossa propriedade e está  sob nossa responsabilidade, sendo que seu uso, acesso e compartilhamento,  quando necessários, serão feitos dentro dos limites e propósitos descritos  nesta Política.<br>
    <strong>Redes  Sociais. </strong>A <strong>Copa Energia </strong>possui páginas hospedadas em  redes sociais como <a href="https://www.linkedin.com/company/copa-energia/">LinkedIn</a> e <a href="https://www.youtube.com/channel/UC4FBV6L-I-zL93od5k7KGAw">YouTube</a> para a divulgação da empresa, produtos, eventos  e para interagir com o Usuário. Tais redes sociais  possuem suas próprias Políticas de Privacidade. Assim, cabe ao Usuário consultar esses  documentos no site de cada uma dessas redes sociais.<br>
    A <strong>Copa Energia </strong>não é responsável pelas Políticas  de Privacidade de terceiros e nem pelo conteúdo de quaisquer sites ou serviços  ligados à ambientes que não os da <strong>Copa Energia</strong>.</p>
<h2>COMO  UTILIZAMOS COOKIES </h2>
<p><em>Cookies </em>são pequenos  arquivos digitais em formato de texto que são armazenados no seu dispositivo  (computador, <em>smartphone</em>, <em>tablet </em>etc.)  pelo navegador de internet e que guardam informações relacionadas às suas  preferências, como idioma preferido, localização, recorrência das suas sessões  e outras variáveis para tornar a sua experiência muito mais eficiente.</p>
<p>Os <em>Cookies </em>servem para aprimorar a sua experiência, tanto em termos  de performance, como em termos de usabilidade, uma vez  que os conteúdos disponibilizados  serão direcionados às suas necessidades e expectativas.</p>
<p>Eles também podem ser utilizados para realizar estatísticas  anônimas e agregadas que permitem entender como o usuário utiliza o site, bem  como para aprimorar suas estruturas e conteúdo. Por serem estatísticas anônimas, não é possível identificá-lo pessoalmente por meio desses dados.</p>
<p><strong>Cookies Necessários</strong>: Permitem a navegação e utilização das aplicações, bem como acessar a áreas seguras do site. Sem estes <em>Cookies</em>, o site não funciona  corretamente.<br>
    <strong>Cookies  Analíticos</strong>: Coletam dados  estatísticos anônimos com a finalidade de analisar a utilização do site e seu  respectivo desempenho.<br>
    <strong>Cookies de Funcionalidade</strong>: São utilizados para assegurar a disponibilização de funcionalida  des adicionais do site ou para  guardar as preferências definidas pelo Usuário ao navegar na internet, sempre  que utilizar o mesmo dispositivo.<br>
    <strong>Cookies  de Publicidade</strong>: Direcionam a  publicidade em função dos interesses do Usuário, ajudando a medir o sucesso de  aplicações e a eficácia da publicidade de parceiros.</p>
<p>A maioria dos navegadores de Internet é configurada para aceitar automaticamente os <em>Cookies</em>, mas você pode alterar as  configurações para bloquear o uso de <em>Cookies. </em>Os navegadores que possuem navegação  anônima coletam apenas cookies estritamente necessários e de funcionalidade.</p>
<p>Existem várias formas de gerenciar <em>Cookies. </em>Consulte as instruções de ou a seção de ajuda, <em>tools </em>ou <em>edit </em>do seu navegador. Disponibilizamos abaixo alguns <em>links </em>para os navegadores mais  conhecidos<em>:</em></p>
<p>- Para mais  informações sobre gestão de <em>Cookies </em>no <strong>Firefox</strong>, <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">clique aqui</a> Para mais informações sobre gestão de <em>Cookies </em>no <strong>Chrome</strong>, <a href="https://support.google.com/chrome/answer/95647?hl=pt-BR&co=GENIE.Platform%3DDesktop">clique aqui.</a> <br>
    - Para mais informações sobre gestão de <em>Cookies </em>no <strong>Internet Explorer</strong>, <a href="https://support.microsoft.com/pt-pt/help/17442/windows-internet-explorer-delete-manage-cookies">clique aqui</a>. Para  mais informações sobre gestão de <em>Cookies </em>no <strong>Safari</strong>, <a href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac">clique aqui</a>. <br>
    - Para mais informações sobre gestão de <em>Cookies </em>no <strong>Opera</strong>, <a href="https://help.opera.com/en/latest/security-and-privacy/#privateWindow">clique aqui</a>.<br>
    - Para mais informações sobre  gestão de <em>Cookies </em>no <strong>Microsoft Edge</strong>, <a href="https://support.microsoft.com/pt-br/help/4026200/microsoft-edge-browse-inprivate">clique aqui</a>. </p>
<h2 align="left">COMPARTILHAMENTO DE DADOS PESSOAIS </h2>
<p>Os Dados Pessoais e os registros de atividades poderão ser compartilhados:</p>
<p>- Com a ANP (Agência  Nacional do Petróleo,  Gás Natural e Biocombustíveis), para cumprimento de obrigações legais e  regulatórias;<br>
    - Internamente,   com    nossas   áreas   de    negócio,   para    promover    e    desenvolver  nossos produtos ou processar sua candidatura a uma vaga de trabalho, por  exemplo; Com terceiros, para o fornecimento de produtos ou serviços essenciais à nossa produção e comercialização;<br>
    - Com autoridades  judiciais, administrativas ou governamentais competentes, sempre que houver determinação legal, requerimento, requisição ou ordem judicial  para tanto; e<br>
    - De forma automática, em caso de movimentações societárias, como fusão, aquisição  e incorporação da <strong>Copa Energia</strong>.</p>
<p>A menos que a <strong>Copa Energia </strong>receba uma determinação legal  ou judicial, os Dados Pessoais nunca serão transferidos a  terceiros ou usados para finalidades diferentes daquelas para as quais foram  coletadas e informadas nesta Política.</p>
<p>Caso o Usuário  tenha qualquer dúvida sobre com quem seus Dados Pessoais  são compartilhados, poderá  entrar em contato por meio dos Canais de Atendimento disponibiliza dos nesta  Política.</p>
<h2 align="left">ARMAZENAMENTO DE DADOS PESSOAIS </h2>
<h3>Local de armazenamento </h3>
<p>Os Dados Pessoais coletados e os registros de atividades  são armazenados em ambiente seguro e controlado, podendo estar em nossos servidores localizados no Brasil,  bem como em ambiente de uso de recursos ou  servidores na nuvem (<em>cloud computing</em>),  o que poderá exigir transferência e/ou processamento dos seus Dados Pessoais  fora do Brasil.<br>
    Essas transferências envolvem apenas empresas que  demonstram estar em conformidade com as legislações aplicáveis, mantendo um  nível de conformidade semelhante ou mais rigoroso que o previsto na legislação  brasileira.</p>
<h3>Prazo  de armazenamento </h3>
<p>Nós armazenamos os Dados Pessoais somente pelo tempo que  for necessário para cumprir com as finalidades para as quais foram coletados ou  para cumprimento de quaisquer obrigações legais, regulatórias ou para preservação de direitos. Para isso, nós contamos com uma Política de Retenção e Descarte  Seguro de Informações.</p>
<h3>Descarte dos Dados Pessoais </h3>
<p>Ao final do prazo de manutenção, os Dados Pessoais  serão excluídos com uso de métodos de descarte seguro ou utilizados de forma  anonimizada para fins estatísticos.</p>
<h2>DIREITOS DO USUÁRIO </h2>
<p>Os Dados Pessoais são do Usuário e a Legislação  Aplicável garante uma série de  direitos, que poderão ser exercidos pelo Usuário por meio de requisição pelo Canal de Atendimento disponibilizado ao final desta  Política.</p>
<p>- <strong>Confirmação e acesso</strong>: O Usuário poderá  solicitar a confirmação sobre a existência de Tratamento e o acesso a seus Dados Pessoais, inclusive por  meio da solicitação de cópias de registros que temos sobre ele.<br>
    - <strong>Correção</strong>: O Usuário poderá solicitar a correção de seus Dados Pessoais  que estejam incompletos,  inexatos ou desatualizados.<br>
    - <strong>Anonimização, bloqueio ou eliminação</strong>: O Usuário poderá solicitar a anonimização, o  bloqueio ou a eliminação dos Dados Pessoais.<br>
    - <strong>Informação sobre compartilhamento</strong>: O Usuário poderá solicitar  informações sobre terceiros com os quais compartilhamentos os Dados Pessoais, limitando essa divulgação  a informações que não violem nossa propriedade intelectual ou segredo de negócio.<br>
    - <strong>Revogação do consentimento</strong>: Quando aplicável, o Usuário poderá  optar por retirar  o consentimento dado para uma finalidade específica. Essa revogação não afetará a legalidade de qualquer Tratamento  realizado anteriormente. Se o Usuário retirar seu consentimento para  finalidades fundamentais ao regular funcionamento dos Nossos Ambientes, eles  poderão ficar indisponíveis.<br>
    - <strong>Oposição</strong>: O Usuário  poderá se opor ao Tratamento dos Dados Pessoais, caso entenda  que o Tratamento seja ilegal. </p>
<h3 align="left">Requisição </h3>
<p>Para podermos prestar  todos os esclarecimentos e viabilizar o exercício dos direitos, conforme o caso, o Usuário deve: (i) identificar-se apropriadamente e (ii) informar o direito que gostaria de  exercer.</p>
<h3>Não atendimento de requisições </h3>
<p>Nós poderemos deixar de atender alguma solicitação de  exercício de direitos, caso o atendimento viole nossa propriedade intelectual  ou segredo de negócios, bem como quando houver obrigação legal ou regulatória  para retenção de Dados Pessoais.<br>
    Além  disso,  podemos  deixar  de  atender à requisição  se  precisarmos  reter  os  Dados Pessoais para possibilitar nossa defesa ou de  terceiros em disputas de qualquer natureza.</p>
<h2>Respostas às requisições </h2>
<p>Nós nos comprometemos a responder todas as solicitações em  um prazo razoável e sempre em conformidade com a Legislação Aplicável.</p>
<h2>SEGURANÇA DA INFORMAÇÃO </h2>
<h3 align="left">Segurança </h3>
<p>Todos os Dados  Pessoais do Usuário  serão guardados de acordo com padrões rígidos  de segurança e confidencialidade.</p>
<h3>Práticas de Segurança e Governança </h3>
<p>Para resguarda a privacidade e proteger os Dados Pessoais do Usuário, a <strong>Copa Energia </strong>conta com um programa  de governança que contém regras  de boas práticas, políticas e procedimentos internos, os quais estabelecem  condições de organização, treinamentos, ações educativas e mecanismos de  supervisão e mitigação de riscos relacionados ao Tratamento de Dados Pessoais.</p>
<h3>Acesso, proporcionalidade e relevância </h3>
<p>Internamente, os Dados Pessoais  são acessados somente  por profissionais devidamente autorizados, respeitando os princípios de proporcionalidade, necessidade e relevância, além do compromisso de  confidencialidade e preservação da privacidade do Usuário, nos termos desta Política.</p>
<h2>INFORMAÇÕES ADICIONAIS </h2>
<p>A <strong>Copa Energia </strong>poderá alterar o teor desta Política a qualquer momento, conforme a  finalidade ou sua necessidade.<br>
    Caso  ocorram alterações substanciais no presente documento, os Usuários serão  devidamente informados da seguinte forma: a) ao público em geral, mediante  anúncio com destaque em nossa página principal; e b) aos usuários cadastrados,  mediante comunicação eletrônica.<br>
    Caso algum ponto desta Política seja considerado  inaplicável pela Autoridade Nacional de Proteção de Dados ou qualquer  outra autoridade competente, inclusive judicial, as demais condições  permanecerão em pleno vigor e efeito.<br>
    Essa Política será interpretada segundo a legislação  brasileira, no idioma português, sendo eleito o foro de domicílio do Usuário  para dirimir qualquer controvérsia que envolva este documento, salvo ressalva  específica de competência pessoal, territorial ou funcional pela legislação  aplicável.<br>
    Caso o Usuário não possua  domicílio no Brasil, este se submete  à legislação brasileira, concordando, portanto, que, em havendo  litígio a ser solucionado, a ação deverá  ser proposta no Foro da  Capital do Estado de São Paulo.</p>
<h2>CANAIS  DE ATENDIMENTO </h2>
<p>Em caso de qualquer dúvida com relação às disposições  constantes desta Política, inclusive para exercício dos seus direitos,  o Usuário poderá entrar em contato com nosso Encarregado, que está à disposição nos  seguintes endereços:</p>
<p>- Encarregado: Sérgio Monteiro<br>
    - Endereço para  correspondências: Avenida das Nações  Unidas, 14.171, Torre C, 29º andar, conjuntos  2901, 2902, 2903 e 2904, Condomínio Rochaverá Corporate Towers, CEP 04794-000 - São Paulo - SP.<br>
    - E-mail para contato: <a href="mailto:privacidade@copaenergia.com.br">privacidade@copaenergia.com.br</a></p>

