import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {TranslateService} from "@ngx-translate/core";
import {User, UserCouponDetail, UserCouponGQL, UserCouponObject} from "../../../generated/graphql";
import moment from 'moment';
import {UserService} from "../../service/user.service";

@Component({
    selector: 'app-coupon',
    templateUrl: './coupon.component.html',
    styleUrls: ['./coupon.component.scss']
})
export class CouponComponent extends BaseComponent implements OnInit {
    user: User = new User();
    couponDetail: UserCouponDetail = new UserCouponDetail();
    coupon: UserCouponDetail = new UserCouponDetail();

    typeGreen: number;
    countGoal = 0;

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                private userCouponGQL: UserCouponGQL,
                public userService: UserService,
                public translate: TranslateService) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data?.id)) {
                    this.user = data as User;
                    const a = []
                    if (a.includes(this.user.profile)) {
                        this.router.navigate(['/']);
                        return;
                    }
                }
            }, error: err => super.onError(err)
        });
        this.userCouponGQL.watch().valueChanges.subscribe(({data}) => {
            this.couponDetail = data.userCoupon;
            this.coupon = data.userCoupon as UserCouponDetail;
            this.typeGreen = this.coupon.coupons.findIndex((e) => e.type == 'type-green');
            data.userCoupon.coupons.forEach((e) => {
                if (e.type === 'goal') {
                    this.countGoal += 1;
                }
            });
        }, error => super.onError(error))
    }

    handlerDate(i) {
        return moment(i).add(1, 'day');
    }
}
