import {BrowserModule} from '@angular/platform-browser';
import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from './views/main/main.component';
import {HeaderComponent} from './views/header/header.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {registerLocaleData} from '@angular/common';
import localePt from '@angular/common/locales/pt';
import {LogoutComponent} from './views/logout/logout.component';
import {AppDataService} from './service/app_data.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxPaginationModule} from 'ngx-pagination';
import { HomeComponent } from './views/home/home.component';
import { NotificationComponent } from './views/notification/notification.component';
import {HeaderHttpInterceptor} from './base/header-interceptor';
// NOVAS VIEWS
import { LoginComponent } from './views/login/login.component';
import {GraphQLModule} from './graphql.module';
import {CURRENCY_MASK_CONFIG} from 'ng2-currency-mask';
import {RegisterComponent} from './views/register/register.component';
import {UserComponent} from "./views/user/user.component";
import {NgxMaskModule} from "ngx-mask";
import {CropImageComponent} from "./views/crop-image/crop-image.component";
import {ImageCropperModule} from 'ngx-image-cropper';
import { FooterComponent } from './views/footer/footer.component';
import { RegulationComponent } from './views/regulation/regulation.component';
import { LgpdComponent } from './views/lgpd/lgpd.component';
import { TermsComponent } from './views/terms/terms.component';
import { CouponComponent } from './views/coupon/coupon.component';
import { RecoveryComponent } from './views/recovery/recovery.component';
import {NgxSpinnerModule} from "ngx-spinner";
import {OnlyNumberDirective} from './directive/only-number.directive';
import { ContactComponent } from './views/contact/contact.component';
import {TermsTextComponent} from "./views/terms/terms-text/terms-text.component";
import {LgpdTextComponent} from "./views/lgpd/lgpd-text/lgpd-text.component";
import {RegulationTextComponent} from "./views/regulation/regulation-text/regulation-text.component";
import { BranchesComponent } from './views/branches/branches.component';
import {FaqComponent} from "./views/faq/faq.component";
import {MaintenanceComponent} from "./views/maintenance/maintenance.component";

registerLocaleData(localePt, 'pt-BR');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HeaderComponent,
    LogoutComponent,
    HomeComponent,
    NotificationComponent,
    RegisterComponent,
    UserComponent,
    CropImageComponent,
    LoginComponent,
    FooterComponent,
    RegulationComponent,
    LgpdComponent,
    TermsComponent,
    CouponComponent,
    RecoveryComponent,
    OnlyNumberDirective,
    ContactComponent,
    TermsTextComponent,
    LgpdTextComponent,
    RegulationTextComponent,
    BranchesComponent,
    FaqComponent,
    MaintenanceComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    HttpClientModule,
    NgxMaskModule.forRoot(),
    AngularSvgIconModule.forRoot(),
    SlickCarouselModule,
    FormsModule,
    GraphQLModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ImageCropperModule,
    NgxPaginationModule,
    NgxSpinnerModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderHttpInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR'
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL'
    },
    {
      provide: CURRENCY_MASK_CONFIG,
      useValue: {   // BRL
        align: 'left',
        allowNegative: true,
        decimal: ',',
        precision: 2,
        prefix: 'R$ ',
        suffix: '',
        thousands: '.'
      }
    },
    TranslateService,
    AppDataService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
