<div class="branches">
    <div class="branches_bg-secondary padding_header pb-3">
        <div class="container" style="padding-top: 78px;">
            <ng-container *ngIf="!actionSearch">
                <div class="row align-items-center">
                    <div class="col">
                        <h5 class="branches_title">Lista de Revendas</h5>
                    </div>
                    <div class="col-auto">
                        <a href="javascript:void(0)" class="btn btn-outline-white text-white"
                           *ngIf="isSearchFilter()" (click)="onSearchClean()">
                            Limpar
                        </a>
                    </div>
                    <div class="col-auto">
                        <a href="javascript:void(0)" (click)="this.showModal('modalFilter')">
                            <svg-icon class="branches_icon fill-white" src="assets/svg/filter.svg"></svg-icon>
                        </a>
                    </div>
                    <div class="col-auto">
                        <a href="javascript:void(0)" (click)="actionSearch = true">
                            <svg-icon class="branches_icon fill-white" src="assets/svg/search.svg"></svg-icon>
                        </a>
                    </div>
                </div>
                <!--<div class="branches_overflow">
                    <div class="branches_badge" [ngClass]="{'active':x.active}" *ngFor="let x of badge">
                        <span>{{x.name}}</span>
                    </div>
                </div>-->
            </ng-container>
            <form autocomplete="off" class="row align-items-center" *ngIf="actionSearch">
                <div class="col">
                    <label class="branches_search">
                        <svg-icon src="assets/svg/search.svg"></svg-icon>
                        <input (keyup.enter)="onFilter()" type="text" name="filterKeyword"
                               [(ngModel)]="consultListInput.keyword" required/>
                    </label>
                </div>
                <div class="col-auto">
                    <a href="javascript:void(0)" (click)="actionSearch = false" class="branches_close">
                        <svg-icon src="assets/svg/close.svg"></svg-icon>
                    </a>
                </div>
            </form>
        </div>
    </div>
    <div class="container">
        <div class="py-3">
            <div class="row" style="row-gap: 30px">
                <div class="col-12" *ngIf="consultList.length === 0">
                    <p class="py-5 text-center">Nenhum registro encontrado.</p>
                </div>
                <div class="col-12 col-lg-4" *ngFor="let x of consultList  | paginate:{itemsPerPage: 48, currentPage: page}; let i = index;">
                    <div class="branches_card" >
                        <div class="px-4 pb-3">
                            <div class="row">
                                <div class="col">
                                    <dl class="d-flex flex-wrap mb-0 py-3" style="gap: 13px">
                                        <dt class="branches_card_lbl">CNPJ:</dt>
                                        <dd class="branches_card_value mb-0">{{x.cnpj}}</dd>
                                    </dl>
                                </div>
                                <div class="col-auto d-flex" style="gap: 7px">
                                    <p class="branches_card_lbl lbl-sm py-3 mb-0">{{getResponsibleStandardization(x.responsibleStandardization)}}</p>
                                    <div class="branches_card_badge">
                                        <svg-icon src="assets/svg/brush.svg"></svg-icon>
                                    </div>
                                </div>
                            </div>
                            <h5 class="branches_card_title mb-0">{{x.companyName}}</h5>
                            <span class="branches_card_description">{{x.gme}}</span>
                        </div>

                        <ng-container *ngIf="true">
                            <div class="row">
                                <div class="col-12">
                                    <h2 class="branches_card_title px-4 mb-1 mt-3">Adesão</h2>
                                    <div class="d-flex flex-column flex-md-row">

                                        <div class="branches_status pending" (click)="onConfirmCreateAdhesionConsult(x)"
                                             *ngIf="isNullOrUndefined(x.frontStatusAdhesion)">
                                            <div class="row align-items-center">
                                                <span class="col text-center name_bold">Fachada</span>
                                                <div class="col-auto">
                                                    <div class="branches_status_icon">
                                                        <svg-icon src="assets/svg/branches-pending.svg"></svg-icon>
                                                    </div>
                                                </div>
                                                <span class="col text-center">Pendente</span>
                                            </div>
                                        </div><!-- / PENDING-->

                                        <div class="branches_status {{getStatusBarColors(x.frontStatusAdhesion)}}" [routerLink]="'/cadastro/'+ x.id"
                                        *ngIf="!isNullOrUndefined(x.frontStatusAdhesion)">
                                            <div class="row align-items-center">
                                                <span class="col text-center name_bold">Fachada</span>
                                                <div class="col-auto">
                                                    <div class="branches_status_icon">
                                                        <svg-icon src="assets/svg/branches-{{getStatusBarColors(x.frontStatusAdhesion)}}.svg"></svg-icon>
                                                    </div>
                                                </div>
                                                <span class="col text-center no-wrap">{{x.frontStatusAdhesion | translate}}</span>
                                            </div>
                                        </div>
                                        <div class="mx-1" *ngIf="x.isFleet">&nbsp;</div>
                                        <div class="branches_status {{getStatusBarColors(x.fleetStatusAdhesion)}}" [routerLink]="'/cadastro/'+ x.id"
                                            *ngIf="x.isFleet && !isNullOrUndefined(x.fleetStatusAdhesion)">
                                            <div class="row align-items-center">
                                                <span class="col text-center name_bold">Frota</span>
                                                <div class="col-auto">
                                                    <div class="branches_status_icon">
                                                        <svg-icon src="assets/svg/branches-{{getStatusBarColors(x.fleetStatusAdhesion)}}.svg"></svg-icon>
                                                    </div>
                                                </div>
                                                <span class="col text-center no-wrap">{{x.fleetStatusAdhesion | translate}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <h2 class="branches_card_title px-4 mb-1 mt-3">Padronização</h2>

                                    <div class="d-flex">
                                        <div class="branches_status {{getStatusBarColors(x.frontStatusStandardization)}}"
                                             *ngIf="isNullOrUndefined(x.frontStatusStandardization)">
                                            <div class="row align-items-center">
                                                <span class="col text-center name_bold"><small>Não iniciada</small></span>
                                            </div>
                                        </div>

                                        <div class="branches_status {{getStatusBarColors(x.frontStatusStandardization)}}" [routerLink]="'/cadastro/'+ x.id"
                                             *ngIf="!isNullOrUndefined(x.frontStatusStandardization)">
                                            <div class="row align-items-center">
                                                <span class="col text-center name_bold">Fachada</span>
                                                <div class="col-auto">
                                                    <div class="branches_status_icon">
                                                        <svg-icon src="assets/svg/branches-{{getStatusBarColors(x.frontStatusStandardization)}}.svg"></svg-icon>
                                                    </div>
                                                </div>
                                                <span class="col text-center no-wrap">{{x.frontStatusStandardization | translate}}</span>
                                            </div>
                                        </div>
                                        <div class="mx-1" *ngIf="x.isFleet && !isNullOrUndefined(x.fleetStatusStandardization)">&nbsp;</div>
                                        <div class="branches_status {{getStatusBarColors(x.fleetStatusStandardization)}}" [routerLink]="'/cadastro/'+ x.id"
                                            *ngIf="x.isFleet && !isNullOrUndefined(x.fleetStatusStandardization)">
                                            <div class="row align-items-center">
                                                <span class="col text-center name_bold">Frota</span>
                                                <div class="col-auto">
                                                    <div class="branches_status_icon">
                                                        <svg-icon src="assets/svg/branches-{{getStatusBarColors(x.fleetStatusStandardization)}}.svg"></svg-icon>
                                                    </div>
                                                </div>
                                                <span class="col text-center no-wrap">{{x.fleetStatusStandardization | translate}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="false">
                            <!--INICIAR-->
                            <div class="branches_status pending" (click)="onConfirmCreateAdhesionConsult(x)"
                                 *ngIf="isNullOrUndefined(x.statusAdhesion) && isNullOrUndefined(x.statusStandardization)">
                                <!--<div class="row align-items-center">
                                    <span class="col text-center name_bold">Iniciar Adesão</span>
                                </div>-->
                                <div class="row align-items-center">
                                    <span class="col text-center name_bold">Pendente</span>
                                    <div class="col-auto">
                                        <div class="branches_status_icon">
                                            <svg-icon src="assets/svg/branches-pending.svg"></svg-icon>
                                        </div>
                                    </div>
                                    <span class="col text-center">Adesão</span>
                                </div>
                            </div>

                            <!--PENDENTE-->
                            <div class="branches_status {{(!isNullOrUndefined(x.statusStandardization) ? x.statusStandardization : x.statusAdhesion)}}" [routerLink]="'/cadastro/'+ x.id"
                                 *ngIf=" (x.processStep === 'standardization' && (x.statusStandardization === 'pending' || x.statusStandardization === 'review')) || (x.processStep === 'adhesion' && (x.statusAdhesion === 'pending' || x.statusAdhesion === 'review'))">
                                <div class="row align-items-center">
                                    <span class="col text-center name_bold">{{(!isNullOrUndefined(x.statusStandardization) ? x.statusStandardization : x.statusAdhesion) | translate}}</span>
                                    <div class="col-auto">
                                        <div class="branches_status_icon">
                                            <svg-icon src="assets/svg/branches-{{(!isNullOrUndefined(x.statusStandardization) ? x.statusStandardization : x.statusAdhesion)}}.svg"></svg-icon>
                                        </div>
                                    </div>
                                    <span class="col text-center">{{x.processStep === 'adhesion' ? 'Adesão' : 'Padronização'}}</span>
                                </div>
                            </div>

                            <!--ANALISE-->
                            <div class="branches_status analysis" [routerLink]="'/cadastro/'+ x.id"
                                 *ngIf=" (x.processStep === 'standardization' && x.statusStandardization === 'received') || (x.processStep === 'adhesion' && x.statusAdhesion === 'received')">
                                <div class="row align-items-center">
                                    <span class="col text-center name_bold no-wrap">Em Análise</span>
                                    <div class="col-auto">
                                        <div class="branches_status_icon">
                                            <svg-icon src="assets/svg/branches-analysis.svg"></svg-icon>
                                        </div>
                                    </div>
                                    <span class="col text-center">{{x.processStep === 'adhesion' ? 'Adesão' : 'Padronização'}}</span>
                                </div>
                            </div>

                            <!--APROVADO-->
                            <div class="branches_status approved" [routerLink]="'/cadastro/'+ x.id"
                                 *ngIf=" (x.processStep === 'standardization' && x.statusStandardization === 'approved') || (x.processStep === 'adhesion' && x.statusAdhesion === 'approved')">
                                <div class="row align-items-center">
                                    <span class="col text-center name_bold">Aprovado</span>
                                    <div class="col-auto">
                                        <div class="branches_status_icon">
                                            <svg-icon src="assets/svg/branches-approved.svg"></svg-icon>
                                        </div>
                                    </div>
                                    <span class="col text-center">{{x.processStep === 'adhesion' ? 'Adesão' : 'Padronização'}}</span>
                                </div>
                            </div>

                            <div class="branches_status review" [routerLink]="'/cadastro/'+ x.id"
                                 *ngIf=" (x.processStep === 'standardization' && x.statusStandardization === 'reproved') || (x.processStep === 'adhesion' && x.statusAdhesion === 'reproved')">
                                <div class="row align-items-center">
                                    <span class="col text-center name_bold">Reprovado</span>
                                    <div class="col-auto">
                                        <div class="branches_status_icon">
                                            <svg-icon src="assets/svg/branches-review.svg"></svg-icon>
                                        </div>
                                    </div>
                                    <span class="col text-center">{{x.processStep === 'adhesion' ? 'Adesão' : 'Padronização'}}</span>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>


                <ng-container *ngIf="false">
                    <div class="col-12 col-lg-4" *ngFor="let x of cards">
                        <div class="branches_card">
                            <div class="px-4 pb-3">
                                <div class="row">
                                    <div class="col">
                                        <dl class="d-flex flex-wrap mb-0 py-3" style="gap: 13px">
                                            <dt class="branches_card_lbl">Cod Pai:</dt>
                                            <dd class="branches_card_value mb-0">123458</dd>
                                        </dl>
                                    </div>
                                    <div class="col-auto d-flex" style="gap: 7px">
                                        <p class="branches_card_lbl lbl-sm py-3 mb-0">Fornecedor</p>
                                        <div class="branches_card_badge">
                                            <svg-icon src="assets/svg/brush.svg"></svg-icon>
                                        </div>
                                    </div>
                                </div>
                                <h5 class="branches_card_title mb-0">MARISTELA M.DE FONTES ARAUJO</h5>
                                <span class="branches_card_description">GMR SUL / Pelotas</span>
                            </div>
                            <div class="branches_status status-small rounded-0 approved" *ngIf="x.accession">
                                <div class="row align-items-center">
                                    <span class="col text-center name_bold">Aprovado</span>
                                    <span class="col text-center">Adessão</span>
                                </div>
                            </div>
                            <div class="branches_status" *ngIf="x.status == ''">
                                <div class="row align-items-center">
                                    <span class="col text-center name_bold">Iniciar Adessão</span>
                                </div>
                            </div>
                            <div class="branches_status" *ngIf="x.status != ''" [ngClass]="x.status">
                                <div class="row align-items-center">
                                    <span class="col text-center name_bold">Revisar</span>
                                    <div class="col-auto">
                                        <div class="branches_status_icon">
                                            <svg-icon src="assets/svg/branches-{{x.status}}.svg"></svg-icon>
                                        </div>
                                    </div>
                                    <span class="col text-center">Adessão</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>

            <div class="row my-5">
                <div class="col-12">
                    <pagination-controls [previousLabel]="labels.previousLabel" [nextLabel]="labels.nextLabel"
                                         [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                                         [screenReaderPageLabel]="labels.screenReaderPageLabel"
                                         [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                                         (pageChange)="page=$event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-custom modal fade" id="modalFilter" data-keyboard="false"
     data-backdrop="static" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-bottom_card">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <h6 class="modal-bottom_title mb-4">Filtros</h6>
                        </div>
                        <div class="col-auto">
                            <a href="javascript:void(0)" (click)="this.closeModal('modalFilter')">
                                <svg-icon class="modal-bottom_close" src="assets/svg/close.svg"></svg-icon>
                            </a>
                        </div>
                    </div>
                    <div class="input-material select">
                        <!--<select id="office" class="form-control" required>
                            <option [ngValue]="undefined" disabled value hidden selected></option>
                            <option [ngValue]="'Administrativo'">Administrativo</option>
                            <option [ngValue]="'Gerente'">Gerente</option>
                            <option [ngValue]="'Proprietário'">Proprietário</option>
                            <option [ngValue]="'Supervisor'">Supervisor</option>
                            <option [ngValue]="'Outros'">Outros</option>
                        </select>-->
                        <input (keyup.enter)="onFilter()" type="text" id="filterDetailKeyword"
                               name="filterDetailKeyword" [(ngModel)]="consultListInput.keyword" required/>
                        <label for="filterDetailKeyword">Buscar</label>
                    </div>
                    <div class="mb-3">
                        <p class="modal-bottom_lbl">Etapas</p>

                        <div class="modal-bottom_card_radio">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox01" value="adhesion"
                                       (change)="onFilterChangeStep($event)">
                                <label class="form-check-label" for="inlineCheckbox01">
                                    <span class="form-check-label-text">Adesão</span>
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox02" value="project"
                                       (change)="onFilterChangeStep($event)">
                                <label class="form-check-label" for="inlineCheckbox02">
                                    <span class="form-check-label-text">Projeto</span>
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox03"
                                       value="standardization"
                                       (change)="onFilterChangeStep($event)">
                                <label class="form-check-label" for="inlineCheckbox03">
                                    <span class="form-check-label-text">Padronização</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <p class="modal-bottom_lbl">Status</p>
                        <div class="modal-bottom_card_check">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="received"
                                       (change)="onFilterChangeStatus($event)">
                                <label class="form-check-label" for="inlineCheckbox1">
                            <span class="form-check-label-icon">
                                <svg-icon src="assets/svg/hourglass-filter.svg"></svg-icon>
                            </span>
                                    <span class="form-check-label-text">Em análise</span>
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="pending"
                                       (change)="onFilterChangeStatus($event)">
                                <label class="form-check-label" for="inlineCheckbox2">
                            <span class="form-check-label-icon">
                                <svg-icon src="assets/svg/pending-filter.svg"></svg-icon>
                            </span>
                                    <span class="form-check-label-text">Pendente</span>
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox3" value="review"
                                       (change)="onFilterChangeStatus($event)">
                                <label class="form-check-label" for="inlineCheckbox3">
                            <span class="form-check-label-icon">
                                <svg-icon style="margin-right: 2px;" src="assets/svg/history-filter.svg"></svg-icon>
                            </span>
                                    <span class="form-check-label-text">Revisar</span>
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox4" value="approved"
                                       (change)="onFilterChangeStatus($event)">
                                <label class="form-check-label" for="inlineCheckbox4">
                            <span class="form-check-label-icon">
                                <svg-icon src="assets/svg/check-filter.svg"></svg-icon>
                            </span>
                                    <span class="form-check-label-text">Finalizada</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            <a class="btn btn-block btn-outline-white text-white">Limpar</a>
                        </div>
                        <div class="col">
                            <a href="javascript:void(0)" (click)="onFilter()"
                               class="btn btn-block btn-white">Salvar</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal-custom modal fade" id="createAdhesionResale" data-keyboard="false"
     data-backdrop="static" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content modal_comment">
            <div class="row">
                <div class="col-12 col-lg-4">
                    <div class="modal_col-primary">
                        <a class="ml-auto d-lg-none" href="javascript:void(0)" (click)="this.closeModal('createAdhesionResale');">
                            <svg-icon class="modal-bottom_close" src="assets/svg/close.svg"></svg-icon>
                        </a>
                        <div class="d-flex flex-column align-items-center pt-4">
                            <img src="assets/images/logocopagaz.png" style="width: 279px;" alt="logo">
                            <h2 class="modal_title mt-3">Revenda <br>participante</h2>
                            <p class="modal_subtitle w-100">
                                <span>{{model.cnpj}}</span><br>
                                <span>{{model.companyName}}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-8">
                    <div class="container py-4">
                        <div class="d-none d-lg-flex justify-content-end w-100">
                            <a href="javascript:void(0)" (click)="this.closeModal('createAdhesionResale');">
                                <svg-icon class="modal-bottom_close" src="assets/svg/close.svg"></svg-icon>
                            </a>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <p class="modal_question mb-4">
                                    Qual o tipo<br>da <span>revenda?</span>
                                </p>
                            </div>
                        </div>
                        <div class="row justify-content-center mb-3">
                            <div class="col-5">
                                <div class="d-flex flex-column align-items-center">
                                    <div (click)="userResale.type = 'glp'" class="modal_select"
                                         [ngClass]="{'active' : userResale.type === 'glp'}">
                                        <svg-icon src="assets/svg/glp.svg"></svg-icon>
                                    </div>
                                    <p class="modal_text">
                                        Revenda com <span>fachada</span>.
                                        (muros, portão ou outros)
                                    </p>
                                </div>
                            </div>
                            <div class="col-5">
                                <div class="d-flex flex-column align-items-center">
                                    <div (click)="userResale.type = 'gas'" class="modal_select"
                                         [ngClass]="{'active' : userResale.type === 'gas'}">
                                        <svg-icon src="assets/svg/gaiola.svg"></svg-icon>
                                    </div>
                                    <p class="modal_text">
                                        Revenda que usa somente
                                        <span>gaiola</span> ou <span>placas ANP.</span>
                                        (Posto de combustível, supermercado ou outros)
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="row" style="row-gap: 29px">
                            <div class="col-12">
                                <p class="modal_question mb-4">
                                    A revenda<br>
                                    possui <span>Frota?</span>
                                </p>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-6">
                                <a class="btn btn-block btn-outline-secondary text-white"
                                   (click)="actionSaveResaleType(false)">Não</a>
                            </div>
                            <div class="col-6">
                                <a class="btn btn-block btn-white"
                                   (click)="actionSaveResaleType(true)">Sim</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
