<div class="container padding_header">
    <div class="regulation">
        <div class="regulation_top">
            <div class="regulation_title">LGPD</div>
        </div>
        <div class="regulation_content">
            <app-lgpd-text></app-lgpd-text>
        </div>

    </div>
</div>

