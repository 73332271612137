<h2>Introdução</h2>
<p>  Nosso intuito é proporcionar uma experiência ainda  mais transparente ao Usuário que acessa e utiliza nossa Plataforma, explicando  o conceito de cookies, como os utilizamos e, ainda, as possibilidades de  customização do seu uso. </p>
<p>Caso não concorde com o uso de cookies da forma  apresentada, o Usuário poderá ajustar as configurações de seu navegador de  internet para não permitir o uso de cookies. </p>
<p>Importante mencionar que a desabilitação do uso de  cookies poderá impactar sua experiência ao utilizar a nossa Plataforma. </p>
<p>Recomendamos também a leitura da nossa <strong>Política de Privacidade</strong>,  na qual trazemos mais informações sobre nosso compromisso com a privacidade do  Usuário e a proteção dos seus dados pessoais. </p>
<p>Para quaisquer questões ou dúvidas sobre esta  Política, o Usuário poderá entrar em contato pelo canal <strong><a href="mailto:privacidade@copaeneriga.com.br">privacidade@copaeneriga.com.br</a>.</strong></p>
<h2>O que são <em>Cookies</em></h2>
<p>  Cookies  são pequenos arquivos digitais em formato de texto que são armazenados no  dispositivo eletrônico do Usuário quando ele acessa nossa Plataforma. Os  cookies guardam informações relacionadas às suas preferências, como idioma  preferido, localização, recorrência das suas sessões e outras variáveis que os  desenvolvedores da Plataforma consideram relevantes para tornar a sua  experiência muito mais eficiente. </p>
<h2>Para que servem os <em>Cookies</em></h2>
<p>  Os <em>cookies</em> servem para aprimorar a experiência do Usuário, tanto em termos  de performance, como em termos de usabilidade, uma vez que os conteúdos  disponibilizados serão direcionados às suas necessidades e expectativas. </p>
<p>Eles  também podem ser utilizados para realizar <u>estatísticas anônimas</u> que  permitem entender como os Usuários utilizam a Plataforma, bem como para  aprimorar suas estruturas e conteúdo. <u>Por serem estatísticas anônimas, não é  possível identificá-lo pessoalmente por meio desses dados.</u><u> </u></p>
<p>Nós utilizamos dois tipos de cookies na nossa  Plataforma: </p>
<ul>
    <li><strong><em><u>Cookies</u></em></strong><strong><u> de Sessão</u></strong>: são os cookies  temporários que permanecem arquivados até que o Usuário saia da Plataforma ou  encerre o navegador. </li>
    <li><strong><em><u>Cookies</u></em></strong><strong><u> Persistentes</u></strong>: são os cookies que ficam armazenados no  dispositivo do Usuário até que sejam excluídos (o tempo que o Cookie  permanecerá no dispositivo depende de sua &quot;validade&quot; e das  configurações do seu navegador de internet). São  esses cookies que  são utilizados no acesso à Plataforma, mostrando os conteúdos mais relevantes e  personalizados de acordo com os interesses do Usuário. </li>
</ul>
<p>Os <em>cookies</em> (de  Sessão ou Persistentes) podem ser categorizados de acordo com sua função:</p>
<ul>
    <li><strong><em>Cookies</em></strong><strong> Estritamente Necessários</strong>: permitem a navegação e  utilização das aplicações, bem como acessar a áreas seguras da Plataforma. Sem  estes <em>cookies</em>, a Plataforma não  funciona corretamente.</li>
    <li><strong><em>Cookies</em></strong><strong> Analíticos</strong>: coletam dados  estatísticos anônimos com a finalidade de analisar a utilização da Plataforma e  seu respectivo desempenho. Esse tipo de <em>cookies</em> é essencial para mantermos uma performance  positiva, como: entender quais são as páginas mais populares e verificar o  motivo dos erros apresentados nas páginas. Esses <em>cookies</em> não coletam nenhum dado pessoal. </li>
</ul>
<p>A maioria dos navegadores de Internet é configurada  para aceitar automaticamente os&nbsp;<em>Cookies</em>, mas você pode alterar as  configurações para bloquear o uso de&nbsp;<em>Cookies.</em>&nbsp;Os navegadores  que possuem navegação anônima coletam apenas cookies estritamente necessários e  de funcionalidade.&nbsp;</p>
<p>Existem várias formas de gerenciar&nbsp;<em>Cookies. </em>Consulte as instruções de ou a seção de ajuda, <em>tools</em> ou <em>edit</em> do seu navegador. Disponibilizamos abaixo alguns <em>links</em> para os navegadores  mais conhecidos<em>:</em>&nbsp;</p>
<ul>
    <li>Para mais  informações sobre gestão de&nbsp;<em>Cookies</em>&nbsp;no&nbsp;<strong>Firefox</strong>,&nbsp;<a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" target="_blank">clique  aqui</a>&nbsp; </li>
    <li>Para mais  informações sobre gestão de&nbsp;<em>Cookies</em>&nbsp;no&nbsp;<strong>Chrome</strong>,&nbsp;<a href="https://support.google.com/chrome/answer/95647?hl=pt-BR&co=GENIE.Platform%3DDesktop" target="_blank">clique  aqui.</a>&nbsp; </li>
    <li>Para mais  informações sobre gestão de&nbsp;<em>Cookies</em>&nbsp;no&nbsp;<strong>Internet  Explorer</strong>,&nbsp;<a href="https://support.microsoft.com/pt-pt/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">clique  aqui</a>.&nbsp; </li>
    <li>Para mais  informações sobre gestão de&nbsp;<em>Cookies</em>&nbsp;no&nbsp;<strong>Safari</strong>,&nbsp;<a href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac" target="_blank">clique  aqui</a>. </li>
    <li>Para mais  informações sobre gestão de&nbsp;<em>Cookies</em>&nbsp;no&nbsp;<strong>Opera</strong>,&nbsp;<a href="https://help.opera.com/en/latest/security-and-privacy/#privateWindow" target="_blank">clique  aqui</a>. </li>
    <li>Para mais  informações sobre gestão de&nbsp;<em>Cookies</em>&nbsp;no&nbsp;<strong>Microsoft</strong>&nbsp;<strong>Edge</strong>,&nbsp;<a href="https://support.microsoft.com/pt-br/help/4026200/microsoft-edge-browse-inprivate" target="_blank">clique  aqui</a>. </li>
</ul>
<h2>CANAIS&nbsp;DE  ATENDIMENTO&nbsp;</h2>
<p>  Em caso de qualquer dúvida com relação às  disposições constantes desta Política, inclusive para exercício dos seus  direitos,&nbsp;o Usuário&nbsp;poderá entrar em contato com nosso Encarregado,  que está à disposição nos seguintes endereços:&nbsp; </p>
<ul>
    <li>Endereço para correspondências:&nbsp;Rua Guararapes  n°. 1855 – 1° Andar – Brooklin Novo Paulista – São Paulo/SP – CEP.  04561-004&nbsp;</li>
    <li>E-mail para  contato:&nbsp;privacidade@copaenergia.com.br</li>
</ul>
<p>&nbsp;</p>

