<div class="login">
    <div class="login_col secondary">
        <div class="d-flex justify-content-center">
            <h3 style="z-index: 100" class="text-white">Em manutenção</h3>
        </div>

        <img class="login_img login-brand primary" src="assets/images/img-login-2.png" alt="img">
        <img class="login_img login-brand secondary" src="assets/images/img-login.png" alt="img">
        <img class="login_img login-brand quaternary" src="assets/images/logo.png" alt="img">
    </div>
</div>


<!--modal esqueci senha-->

