<header class="header bg-gradient-primary-secondary">
    <div class="hamburger" (click)="actionNavToggle()">
        <span class="bar"></span>
    </div>
    <img src="assets/images/logo-campanha-copa.png" alt="Juntos No Gás" class="header-brand"/>
    <div class="header-content">
        <div class="header-primary">
            <nav class="nav">
                <ul class="nav-list">
                    <li class="nav-item">
                        <a href="javascript:void('');" (click)="routerNavigateHomePage('home')"
                           class="nav-link">Home</a>
                    </li>
                    <li class="nav-item is-cursor">
                        <a (click)="routerNavigateHomePage('comoparticipar')" class="nav-link">Como participar</a>
                    </li>
                    <li class="nav-item is-cursor">
                        <a (click)="routerNavigateHomePage('premios')" class="nav-link">Prêmios</a>
                    </li>
                    <li class="nav-item is-cursor">
                        <a (click)="routerNavigateHomePage('ganhadores')" class="nav-link">Ganhadores</a>
                    </li>
                    <li class="nav-item is-cursor" *ngIf="(user.profile !== 'EC' && user.profile !== 'GVE' || user?.profile === 'MA') && user?.resaleType !== 'Novo Negócio'">
                        <a href="javascript:void('');" (click)="actionNavRemove()" routerLink="/coupon"
                           class="nav-link">Cupons</a>
                    </li>
                    <li class="nav-item is-cursor" *ngIf="true">
                        <a (click)="routerNavigateHomePage('faq')" href="javascript:void('');" class="nav-link">FAQ</a>
                    </li>
                    <li class="nav-item is-cursor">
                        <a (click)="onModalContact()" href="javascript:void('');" class="nav-link">Contato</a>
                    </li>
                    <li class="nav-item is-cursor">
                        <a href="javascript:void('');" [routerLink]="handlerRoute(user?.profile)"
                           (click)="actionNavRemove()" class="nav-link highlight">Participe do Programa</a>
                    </li>
                </ul>
            </nav>
            <img src="assets/images/{{(user?.profile === 'EC' || user?.profile === 'GVE' || user?.profile === 'MA') ? 'logo-copaenergia-white' : user?.branding === 'Liquigás' ? 'logo-liquigas-white' : 'logo-copagaz-white' }}.png" alt="Liquigás" class="header-brand-secondary" />
        </div>
        <div class="header-secondary">
            <div class="header-user" style="padding-left: 220px">
                <span class="header-user-name">Olá, <span>{{user?.name}}!</span></span>
                <div class="text-secondary d-none d-xl-flex">|</div>
                <div class="header-user-info"><span>GLP - </span> {{user?.range}}</div>
                <div class="text-secondary  d-none d-xl-flex" *ngIf="user.profile !== 'EC' && user.profile !== 'GVE' && user.profile !== 'MA'">|</div>
                <div class="header-user-profile" *ngIf="user.profile !== 'EC' && user.profile !== 'GVE' && user.profile !== 'MA'">
                    <a href="javascript:void('');" class="btn btn-quaternary d-flex py-1 px-2" style="gap: 10px"
                       (click)="showModal('modalFirstAccess')">
                        <div class="svg-profile">
                            <svg-icon src="assets/svg/user.svg"></svg-icon>
                        </div>
                        Editar Perfil
                    </a>
                </div>
            </div>
            <div class="d-flex align-items-center">
                <div class="header-user-info mr-2">
                    <a class="text-primary" [routerLink]="'/regulation'" target="_blank" href="javascript:void('');"
                       style="gap: 10px">
                        <b class="text-primary">Veja o Regulamento</b>
                    </a>
                </div>
                <div class="header-exit" (click)="onLogout()">
                    <a href="javascript:void('');" class="header-exit-link">
                        <svg-icon class="header_icon-white" src="assets/svg/exit.svg"></svg-icon>
                        <span class="text-white ml-2">Sair</span>
                    </a>
                </div>
            </div>

        </div>
    </div>
</header>

<app-contact [createContactInput]="createContactInput"></app-contact>

<!--
<header class="header d-flex justify-content-between align-items-center" *ngIf="false">
  <span class="d-flex d-lg-none d-flex justify-content-center align-items-center" (click)="actionNavToggle()">
    <div class="hamburger d-flex d-lg-none">
      <span class="bar"></span>
    </div>
  </span>
    <svg-icon class="aux-logo" src="assets/svg/auxlogo.svg"></svg-icon>
    <svg-icon src="assets/svg/logo-eurofarma.svg"></svg-icon>
    <div class="d-none d-lg-flex">
        <a href="#" class="d-none d-lg-block header__user">
            <span>{{'headerSalutation' | translate}}, <strong>{{user.name}}</strong></span>
            <img src="{{!user?.photo ? 'https://medicina.ucpel.edu.br/wp-content/uploads/2020/07/apacucpel_ucpel_image_319-1024x960.jpeg' : user?.photo}}"
                 class="profile_photo"/>
        </a>
        <div class="header__btn">
            <app-notification></app-notification>
        </div>
        <div class="header__btn pr-0">
            <font class="header_font">{{'idioma' | translate}}:</font>
            <a class="header_font" href="javascript:void('');" [ngClass]="{'active' : translate.currentLang === 'pt'}"
               (click)="switchLanguage('pt')">PT</a>
            <span class="separator">|</span>
            <a class="header_font" href="javascript:void('');" [ngClass]="{'active' : translate.currentLang === 'es'}"
               (click)="switchLanguage('es')">ES</a>
        </div>
        <div class="header__btn">
            <a href="javascript:void('sair');" [routerLink]="['/logout']" class="d-none d-lg-flex header__user__outbtn">
                <strong class="header__user__outbtn__text">{{'sair' | translate}}</strong>
            </a>
        </div>
    </div>
</header>
-->
