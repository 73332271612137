import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from './views/main/main.component';
import {LogoutComponent} from './views/logout/logout.component';
import {HomeComponent} from './views/home/home.component';
import {AuthGuard} from './service/auth-guard.service';
import {LoginComponent} from './views/login/login.component';
import {RegisterComponent} from './views/register/register.component';
import {RegulationComponent} from "./views/regulation/regulation.component";
import {TermsComponent} from "./views/terms/terms.component";
import {LgpdComponent} from "./views/lgpd/lgpd.component";
import {CouponComponent} from "./views/coupon/coupon.component";
import {RecoveryComponent} from "./views/recovery/recovery.component";
import {BranchesComponent} from "./views/branches/branches.component";
import {MaintenanceComponent} from "./views/maintenance/maintenance.component";

const routes: Routes = [
    /*{
        path: '', component: MaintenanceComponent
    },
    {
        path: ':id', component: MaintenanceComponent
    },*/
    {
        path: 'login', component: LoginComponent
    },
    {
        path: 'logout', component: LogoutComponent
    },
    {path: 'recuperacao/:id', component: RecoveryComponent},
    {
        path: '', component: MainComponent,
        children: [
            // {path: 'dashboard', component: DashboardComponent},
            {path: 'home', component: HomeComponent},
            {path: 'revendas', component: BranchesComponent},
            {path: 'cadastro', component: RegisterComponent},
            {path: 'cadastro/:id', component: RegisterComponent},
            {path: 'regulation', component: RegulationComponent},
            {path: 'lgpd', component: LgpdComponent},
            {path: 'terms', component: TermsComponent},
            {path: 'coupon', component: CouponComponent},
            {path: 'download', component: HomeComponent},
            {path: '', component: HomeComponent},
        ],
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes,
        {
            relativeLinkResolution: 'legacy',
            scrollOffset: [0, 0],
            scrollPositionRestoration: 'top'
        })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
