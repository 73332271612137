<section class="home padding_header" id="home">
    <div class="step step_intro bg-orange">
        <div class="container step_container" style="padding-top: 1rem;">
            <div class="slick-primary mt-4" *ngIf="user.profile === 'GVE' || user.profile === 'EC'">
                <div class="px-4">
                    <img src="assets/images/banner/Banner-GVR.jpg" alt="Liquigas"
                         class="img-fluid bannerPhoto"/>
                </div>
            </div>
            <div class="slick-primary mt-4" *ngIf="user.profile !== 'GVE' && user.profile !== 'EC'">
                <div *ngFor="let a of this.banners">
                    <a href="{{a.buttonLink}}" *ngIf="!isNullOrUndefined(a.buttonLink)">
                        <img src="{{a.url}}" alt="Liquigas" class="img-fluid bannerPhoto"/>
                    </a>
                    <ng-container *ngIf="isNullOrUndefined(a.buttonLink)">
                        <img src="{{a.url}}" alt="Liquigas" class="img-fluid bannerPhoto"/>
                    </ng-container>
                </div>
                <ng-container *ngIf="this.banners.length === 0">
                    <div class="px-4">
                        <img src="assets/images/banner/banner-default.png" alt="Liquigas"
                             class="img-fluid bannerPhoto"/>
                    </div>
                </ng-container>
            </div>
        </div>
        <!--<svg-icon style=" display: flex;justify-content: end;width: 100%;margin-right: 50px"
                  src="assets/svg/copagaz.svg"></svg-icon>-->
    </div>
    <article class="step step_participate" id="comoparticipar">
        <div class="container step_container">
            <div class="row">
                <div class="col-md-4 step_container--info">
                    <div class="d-flex align-items-center" style="gap: 23px">
                        <svg-icon src="assets/svg/icon-title.svg"></svg-icon>
                        <h2 class="step_title_2"><span>Como</span><br> Participar </h2>
                    </div>
                </div>
                <div class="col-md-8">
                    <img src="assets/images/{{user?.branding === 'Liquigás' ? 'img-coupon2' : 'img-coupon2-copagaz' }}.png"
                         alt="img" class="step_participate--thumb"/>
                </div>
                <div class="col-12 row mt-5 mt-sm-4">
                    <div class="col-md-3">
                        <div class="step_line"></div>
                        <div class="d-flex align-items-center">
                            <div class="step_item">1º</div>
                            <h3 class="step-title">Passo</h3>
                        </div>
                        <p class="step_text item">
                            <strong>ENVIE FOTOS DA FACHADA E FROTA</strong><br/><br/>
                            Realize a adesão de sua revenda enviando as fotos da fachada e frota.
                        </p>
                    </div>
                    <div class="col-md-3">
                        <div class="step_line"></div>
                        <div class="d-flex align-items-center">
                            <div class="step_item">2º</div>
                            <h3 class="step-title ">Passo</h3>
                        </div>
                        <p class="step_text item">
                            <strong>PADRONIZE SUA FROTA</strong><br/><br/>
                            Após aprovação do auditor, você deverá padronizar sua frota de acordo com o
                            <a class="padro_link" target="_blank"
                               href="assets/files/{{user?.branding === 'Liquigás' ? 'Copa_manual_revendas_liquigas' : 'Copa_manual_revendas_copagaz'}}.pdf">
                                manual de padronização da
                                marca.</a>
                        </p>

                    </div>
                    <div class="col-md-3">
                        <div class="step_line"></div>
                        <div class="d-flex align-items-center">
                            <div class="step_item">3º</div>
                            <h3 class="step-title ">Passo</h3>
                        </div>
                        <p class="step_text item">
                            <strong>COMPROVE SUA FROTA PADRONIZADA</strong><br/><br/>
                            Depois de realizar a padronização frota corretamente, envie suas novas fotos até dia
                            31/07/2023 para comprovar e concorrer aos prêmios.

                        </p>
                    </div>
                    <div class="col-md-3">
                        <div class="d-flex align-items-center">
                            <!--<div class="d-flex align-items-center">
                                <div class="step_item">4º</div>
                                <h3 class="step-title ">Passo</h3>
                            </div>
                            <svg class="position-relative d-none d-lg-block" style="bottom: -18px;"
                                 xmlns="http://www.w3.org/2000/svg"
                                 width="28.347" height="42.089"
                                 viewBox="0 0 28.347 42.089">
                                <path id="Caminho_1384" data-name="Caminho 1384" d="M2519,3807.5h27.847v41.589"
                                      transform="translate(-2519 -3807)" fill="none" stroke="#00bb7f" stroke-width="1"/>
                            </svg>-->
                        </div>
                        <a href="javascript:void('');" class="btn btn-block btn-tertiary text-white mt-3 mb-3"
                           [routerLink]="handlerRoute(user?.profile)">Participe</a>
                    </div>
                </div>
            </div>
        </div>
    </article>
    <article class="step step_award" id="premios">
        <div class="container step_container">
            <div class="row align-items-center">
                <div class="col-12">
                    <div class="d-flex align-items-center mb-5">
                        <svg-icon src="assets/svg/icon-title.svg"></svg-icon>
                        <h2 class="step_title text-primary">Prêmios</h2>
                    </div>

                </div>
                <div class="col-12 mt-2">
                    <div class="d-flex flex-wrap">
                        <h2 class="step_title mr-3">Padronizou a frota,</h2>
                        <h2 class="step_title text-primary">Ganhou</h2>
                    </div>

                </div>
                <div class="col-12 mt-5">

                    <div class="step_text2">
                        <p>
                            <b> KIT UNIFORME E ÍMÃS</b><br>
                            Ao comprovar a padronização de 100% da frota seguindo o manual de padronização, a revenda
                            ganhará:
                        </p>

                    </div>
                </div>

                <div class="col-12" *ngIf="user.resaleType === 'Novo Negócio'">
                    <div class="prize_row pr-row">
                        <div class="row w-100">
                            <div class="col-12 col-md-7">
                                <table class="prize-table">
                                    <tr>
                                        <th>CLASSE ANP</th>
                                        <th class="text-center">ÍMÃS</th>
                                        <th class="text-center">KIT UNIFORMES</th>
                                    </tr>
                                    <tr>
                                        <td>Classe Especial</td>
                                        <td class="text-center">5.000</td>
                                        <td class="text-center"> 12</td>
                                    </tr>
                                    <tr>
                                        <td>Classe VII</td>
                                        <td class="text-center">5.000</td>
                                        <td class="text-center"> 12</td>
                                    </tr>
                                    <tr>
                                        <td>Classe VI</td>
                                        <td class="text-center">5.000</td>
                                        <td class="text-center"> 10</td>
                                    </tr>
                                    <tr>
                                        <td>Classe V</td>
                                        <td class="text-center">3.000</td>
                                        <td class="text-center"> 8</td>
                                    </tr>
                                    <tr>
                                        <td>Classe IV</td>
                                        <td class="text-center">3.000</td>
                                        <td class="text-center"> 8</td>
                                    </tr>
                                    <tr>
                                        <td>Classe III</td>
                                        <td class="text-center">3.000</td>
                                        <td class="text-center"> 6</td>
                                    </tr>
                                    <tr>
                                        <td>Classe II</td>
                                        <td class="text-center">1.000</td>
                                        <td class="text-center"> 4</td>
                                    </tr>
                                    <tr>
                                        <td>Classe I</td>
                                        <td class="text-center">1.000</td>
                                        <td class="text-center"> 2</td>
                                    </tr>
                                </table>
                            </div>
                            <div class="col-12 col-md-5 award_prizes-shirt">
                                <img style="height: 282px;"
                                     src="assets/images/{{user?.branding === 'Liquigás' ? 'prizes5' : 'prizes5-copagaz' }}.png"
                                     alt="img">
                            </div>
                        </div>
                    </div>
                    <p class="text-white mt-3">
                        <b>Kit uniforme composto por:</b><br>
                        1 calça, 1 camisa manga curta e 1 boné.<br><br>
                        <b>Ímãs personalizados com:</b><br>
                        Nome e até 2 telefones da revenda.
                    </p>
                </div>

                <div class="col-12" *ngIf="user.resaleType !== 'Novo Negócio'">
                    <div class="prize_row pr-row">
                        <div class="row w-100">
                            <div class="col-12 col-md-7">
                                <table class="prize-table">
                                    <tr>
                                        <th>CLASSE ANP</th>
                                        <th class="text-center">ÍMÃS</th>
                                        <th class="text-center">KIT UNIFORMES</th>
                                    </tr>
                                    <tr>
                                        <td>Classe Especial</td>
                                        <td class="text-center">12.000</td>
                                        <td class="text-center"> 30</td>
                                    </tr>
                                    <tr>
                                        <td>Classe VII</td>
                                        <td class="text-center">10.000</td>
                                        <td class="text-center"> 20</td>
                                    </tr>
                                    <tr>
                                        <td>Classe VI</td>
                                        <td class="text-center">10.000</td>
                                        <td class="text-center"> 20</td>
                                    </tr>
                                    <tr>
                                        <td>Classe V</td>
                                        <td class="text-center">8.000</td>
                                        <td class="text-center"> 16</td>
                                    </tr>
                                    <tr>
                                        <td>Classe IV</td>
                                        <td class="text-center">5.000</td>
                                        <td class="text-center"> 10</td>
                                    </tr>
                                    <tr>
                                        <td>Classe III</td>
                                        <td class="text-center">4.000</td>
                                        <td class="text-center"> 6</td>
                                    </tr>
                                    <tr>
                                        <td>Classe II</td>
                                        <td class="text-center">1.000</td>
                                        <td class="text-center"> 2</td>
                                    </tr>
                                    <tr>
                                        <td>Classe I</td>
                                        <td class="text-center">500</td>
                                        <td class="text-center"> 2</td>
                                    </tr>
                                </table>
                            </div>
                            <div class="col-12 col-md-5 award_prizes-shirt">
                                <img style="height: 282px;"
                                     src="assets/images/{{user?.branding === 'Liquigás' ? 'prizes5' : 'prizes5-copagaz' }}.png"
                                     alt="img">
                            </div>
                        </div>
                    </div>
                    <p class="text-white mt-3">
                        <b>Kit uniforme composto por:</b><br>
                        1 calça, 1 camisa manga curta e 1 boné.<br><br>
                        <b>Ímãs personalizados com:</b><br>
                        Nome e até 2 telefones da revenda.
                    </p>
                </div>

                <ng-container *ngIf="user?.resaleType !== 'Novo Negócio'">
                    <div class="col-12 mt-5">
                        <div class="d-flex flex-wrap">
                            <h2 class="step_title mr-3">Grande</h2>
                            <h2 class="step_title text-primary">Sorteio</h2>
                        </div>

                    </div>
                    <div class="col-12 mt-3">
                        <div class="step_text2 flex-column">
                            <p>Você pode ganhar até R$ 24.000 conforme sua classe, são mais de R$ 1 milhão em premiação
                                e muitas chances de ganhar:</p>
                            <p>Comprovando a padronização de 100% de frota corretamente até o dia 30/06/2023 a revenda
                                receberá 6 cupons para concorrer ao sorteio final, caso a comprovação ocorra entre o dia
                                01/07/2023 e 31/07/2023, a revenda receberá 3 cupons. E mais, comprovando a padronização
                                completa da frota em verde o número de cupons será dobrado.</p>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="prize_row pr-row">
                            <div class="row w-100">
                                <div class="col-12 col-md-7">
                                    <table class="prize-table">
                                        <tr>
                                            <th>CLASSE ANP</th>
                                            <th class="text-center">Revendas Sorteadas</th>
                                            <th class="text-center">Valor em R$</th>
                                        </tr>
                                        <tr>
                                            <td>Classe Especial</td>
                                            <td class="text-center"> 5</td>
                                            <td class="text-center"> 24.000</td>
                                        </tr>
                                        <tr>
                                            <td>Classe VII</td>
                                            <td class="text-center">5</td>
                                            <td class="text-center"> 18.000</td>
                                        </tr>
                                        <tr>
                                            <td>Classe VI</td>
                                            <td class="text-center">5</td>
                                            <td class="text-center"> 18.000</td>
                                        </tr>
                                        <tr>
                                            <td>Classe V</td>
                                            <td class="text-center">20</td>
                                            <td class="text-center"> 12.000</td>
                                        </tr>
                                        <tr>
                                            <td>Classe IV</td>
                                            <td class="text-center">20</td>
                                            <td class="text-center"> 8.000</td>
                                        </tr>
                                        <tr>
                                            <td>Classe III</td>
                                            <td class="text-center">80</td>
                                            <td class="text-center"> 4.000</td>
                                        </tr>
                                        <tr>
                                            <td>Classe II</td>
                                            <td class="text-center">80</td>
                                            <td class="text-center"> 2.000</td>
                                        </tr>
                                        <tr>
                                            <td>Classe I</td>
                                            <td class="text-center">80</td>
                                            <td class="text-center"> 2.000</td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-12 col-md-5 award_prizes-card">
                                    <img src="assets/images/{{user?.branding === 'Liquigás' ? 'prizes8' : 'prizes8-copagaz' }}.png"
                                         alt="img">
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

            </div>
        </div>
        <img class="happy_face gas-1" src="assets/images/happy2.png" alt="">
        <img class="happy_face gas-2" src="assets/images/happy.png" alt="">
        <img class="happy_face gas-3" src="assets/images/happy2.png" alt="">
        <img class="happy_face gas-4" src="assets/images/happy.png" alt="">
    </article>
    <article class="step step_winners" id="ganhadores">
        <div class="container step_container">
            <div class="row">
                <div class="col-12">
                    <div class="d-flex align-items-center" style="gap: 23px">
                        <svg-icon src="assets/svg/icon-title.svg"></svg-icon>
                        <h2 class="step_title">Ganhadores</h2>
                    </div>
                </div>
                <div class="col-md-12" *ngIf="false">
                    <div class="winner-wait ">
                        <b class="step_text" style="margin-top: 51px">
                            Em breve
                        </b>
                    </div>
                </div>
                <div class="col-12 mt-5">
                    <ul class="nav nav-tabs flex-nowrap align-items-end winners" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="lote1-tab" data-toggle="tab" href="#lote1" role="tab"
                               aria-controls="lote1" aria-selected="true">Padronizou a frota, Ganhou</a>
                        </li>
                        <li class="nav-item" *ngIf="user?.resaleType !== 'Novo Negócio'">
                            <a class="nav-link" id="lote2-tab" data-toggle="tab" href="#lote2" role="tab"
                               aria-controls="lote2" aria-selected="false">Sorteio</a>
                        </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="lote1" role="tabpanel" aria-labelledby="home-tab">
                            <div class="bg-primary p-4" style="border-radius: 0 0 10px 10px;">
                                <ng-container *ngIf="false">
                                    <h4 class="text-white text-center py-5 my-5">
                                        Em breve serão
                                        divulgados os ganhadores.
                                    </h4>
                                </ng-container>
                                <ng-container *ngIf="true">
                                    <form class="row align-items-end">
                                        <div class="col-md-5">
                                            <h3 class="text-tertiary text-uppercase"><b>Padronizou, Ganhou!</b></h3>
                                            <p class="text-white">
                                                Todas as revendas que foram aprovadas dentro do prazo ganharam ímãs
                                                e
                                                uniformes conforme sua class ANP.
                                            </p>
                                        </div>
                                        <div class="col-md-5" *ngIf="user.resaleType !== 'Novo Negócio'">
                                            <label class="step_input search">
                                                <input placeholder="Buscar" (keyup)="actionSearch()"
                                                       type="text" class="js-filter-winners">
                                            </label>
                                        </div>
                                    </form>
                                    <div class="row justify-content-center">
                                        <div class="col-md">
                                            <div class="mt-3 winners_scroll">
                                                <p *ngIf="user.resaleType === 'Novo Negócio'" class="p-5 my-5 text-center text-white"><strong>Em breve serão divulgados os ganhadores.</strong></p>
                                                <div class="table-responsive" *ngIf="user.resaleType !== 'Novo Negócio'">
                                                    <table class="table table-striped winners js-table-winners"
                                                           cellspacing="0" cellpadding="0">
                                                        <thead>
                                                        <tr>
                                                            <th class="w-20">CNPJ</th>
                                                            <th class="w-40">RAZÃO SOCIAL</th>
                                                            <th class="w-20" >CLASSE ANP</th>
                                                            <th>CIDADE/ESTADO</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr *ngFor="let item of tableData">
                                                            <td>{{ item['CNPJ'] }}</td>
                                                            <td>{{ item['RAZAOSOCIAL'] }}</td>
                                                            <td>{{ item['CLASSEANP'] }}</td>
                                                            <td>{{ item['CIDADE/UF'] }}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    <table class="table table-striped winners" *ngIf="false">
                                                        <thead>
                                                        <tr>
                                                            <th>CÓDIGO</th>
                                                            <th>RAZÃO SOCIAL</th>
                                                            <th>CIDADE/ESTADO</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr *ngFor="let c of modelPaginator?.winners |
                                                        paginate: {itemsPerPage: modelPaginator?.PageSize,
                                                        currentPage: modelPaginator?.currentPage,
                                                        totalItems: modelPaginator?.totalCount }">
                                                            <td>{{c?.user?.fatherCode}}</td>
                                                            <td>{{c?.user?.name}}</td>
                                                            <td>
                                                                SÃO PAULO/SP
                                                                <!--{{c?.user?.city}}/{{c?.user?.uf}}--></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="text-right" *ngIf="false">
                                                    <pagination-controls
                                                            [previousLabel]="labels.previousLabel"
                                                            [nextLabel]="labels.nextLabel"
                                                            [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                                                            [screenReaderPageLabel]="labels.screenReaderPageLabel"
                                                            [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                                                            (pageChange)="pageChanged($event)"></pagination-controls>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-2 col-md-3 position-relative" *ngIf="user.resaleType !== 'Novo Negócio'">
                                            <img class="img_prize"
                                                 src="assets/images/{{user?.branding === 'Liquigás' ? 'winners-1' : 'winners-1-copagaz' }}.png"
                                                 alt="winners-1">
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="lote2" role="tabpanel" aria-labelledby="profile-tab">
                            <div class="bg-primary p-4" style="border-radius: 0 0 10px 10px">
                                <ng-container *ngIf="false">
                                    <h4 class="text-white text-center py-5 my-5">
                                        Em breve serão
                                        divulgados os ganhadores.
                                    </h4>
                                </ng-container>
                                <ng-container *ngIf="true">
                                    <form class="row align-items-end">
                                        <div class="col-5">
                                            <h3 class="text-tertiary text-uppercase"><b>SORTEIO</b></h3>
                                            <br>
                                            <p class="text-white">Realizado em 15/08/2023</p>
                                        </div>
                                        <div class="col-5">
                                            <label class="step_input search">
                                                <input placeholder="Buscar" (keyup)="actionSearch2()"
                                                       type="text" class="js-filter-sorteio">
                                            </label>
                                        </div>
                                    </form>
                                    <div class="row justify-content-center">
                                        <div class="col-md">
                                            <div class="mt-3 winners_scroll">
                                                <p *ngIf="user.resaleType === 'Novo Negócio'" class="p-5 my-5 text-center text-white"><strong>Em breve serão divulgados os ganhadores.</strong></p>
                                                <div class="table-responsive" *ngIf="user.resaleType !== 'Novo Negócio'">
                                                    <table class="table table-striped winners js-table-sorteio"
                                                           cellspacing="0" cellpadding="0">
                                                        <thead>
                                                        <tr>
                                                            <th class="w-20">CNPJ</th>
                                                            <th class="w-40">RAZÃO SOCIAL</th>
                                                            <th class="w-20">CLASSE ANP</th>
                                                            <th>CIDADE/ESTADO</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr *ngFor="let item of tableData2">
                                                            <td>{{ item['CNPJ'] }}</td>
                                                            <td>{{ item['RAZAOSOCIAL'] }}</td>
                                                            <td>{{ item['CLASSEANP'] }}</td>
                                                            <td>{{ item['CIDADE/UF'] }}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img class="winners_gas gas-1"
             src="assets/images/{{user?.branding === 'Liquigás' ? 'gas' : 'gas-copagaz' }}.png" alt="gas">
        <img class="winners_gas gas-2"
             src="assets/images/{{user?.branding === 'Liquigás' ? 'gas' : 'gas-copagaz' }}.png" alt="gas">
        <img class="winners_gas gas-3"
             src="assets/images/{{user?.branding === 'Liquigás' ? 'gas' : 'gas-copagaz' }}.png" alt="gas">
        <img class="winners_gas gas-4"
             src="assets/images/{{user?.branding === 'Liquigás' ? 'gas' : 'gas-copagaz' }}.png" alt="gas">
        <img class="winners_gas gas-5"
             src="assets/images/{{user?.branding === 'Liquigás' ? 'gas' : 'gas-copagaz' }}.png" alt="gas">
    </article>
    <article class="step step_faq" id="faq" *ngIf="true">
        <div class="container step_container">
            <div class="row">
                <div class="col-12">
                    <div class="d-flex align-items-center mb-4" style="gap: 23px">
                        <svg-icon src="assets/svg/icon-title.svg"></svg-icon>
                        <h2 class="step_title">FAQ</h2>
                    </div>
                    <div class="js-accordion">
                        <app-faq></app-faq>
                    </div>
                </div>
            </div>
        </div>
    </article>
</section>

<div class="modal-custom modal fade" id="modalPrazoEncerrado" data-keyboard="false"
     data-backdrop="static" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content modal_comment">
            <div class="row">
                <div class="col-12" style="padding: 0;">
                    <div class="modal_col-primary">
                        <a data-dismiss="modal" class="modal_close ml-auto d-lg-none" aria-label="Close">
                            <svg-icon [svgStyle]="{'fill':'#006F4D'}" src="assets/svg/close-circle.svg"></svg-icon>
                        </a>
                        <img src="assets/images/logocopagaz.png" style="width: 279px;" alt="logo" class="mt-4"/>
                        <h2 class="modal_title text-white text-center my-5">
                            O prazo de comprovação da Padronização foi encerrado.
                        </h2>
                        <a class="btn btn-primary btn-block  mb-4" href="javascript:void(0);"
                           (click)="closeModal('modalPrazoEncerrado')"> <span class="text-white">Fechar</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

