<app-header></app-header>

<div class="app-body">

    <div class="main">
        <router-outlet></router-outlet>
    </div>

</div>

<app-user></app-user>

<app-footer></app-footer>
<!--Modal Video-->
<div class="modal-custom modal fade" id="modalVideo" data-keyboard="false"
     data-backdrop="static" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content modal_video">
            <div class="modal_top">
                <img class="video_logo" src="assets/images/logocopagaz.png" alt="">
                <svg-icon (click)="closeModal('modalVideo')" class="svg-close mt-2"
                          src="assets/svg/close-circle.svg"></svg-icon>
            </div>
            <div class="row justify-content-center video_section">
                <div class="col-12 rounded-left d-flex flex-column  align-items-center justify-content-center">
                    <!--<iframe class="video" src="https://www.youtube.com/embed/hFCCK3sqMQw" title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>-->
                    <div class="js-media-video w-100">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Modal Download-->
<div class="modal-custom modal fade" id="modalDownload" data-keyboard="false"
     data-backdrop="static" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content modal_comment">
            <div class="row">

                <div class="col-12 col-lg-4">
                    <div class="modal_col-primary">
                        <a data-dismiss="modal" class="modal_close ml-auto d-lg-none" aria-label="Close">
                            <svg-icon [svgStyle]="{'fill':'#006F4D'}"
                                      src="assets/svg/close-circle.svg"></svg-icon>
                        </a>
                        <img src="assets/images/logocopagaz.png" style="width: 279px;" alt="logo">
                        <h2 class="modal_title text-white mb-3">Download</h2>
                    </div>
                </div>

                <div class="col-12 col-lg-8">
                    <div class="d-flex flex-column justify-content-between h-100 p-4">
                        <a data-dismiss="modal" class="modal_close d-none d-lg-flex justify-content-end"
                           aria-label="Close">
                            <svg-icon src="assets/svg/close-circle.svg"></svg-icon>
                        </a>
                        <div class="row align-items-center">
                            <svg-icon class="modal_svg mr-2" src="assets/svg/documents.svg"></svg-icon>
                            <b class="text-quartenary">Documentos</b>
                        </div>
                        <ul class="download_list mb-4">
                            <li>
                                <div class="d-flex justify-content-between align-items-center w-100">
                                    <a class="d-flex justify-content-between align-items-center w-100 pb-3"
                                       href="assets/files/RG_Copa_Energia.pdf" target="_blank">
                                        <span class="text-white">Regulamento</span>
                                        <svg-icon src="assets/svg/downloadmodal.svg"></svg-icon>
                                    </a>
                                </div>
                            </li>
                            <li *ngIf="user.branding === 'Liquigás' || (user.profile === 'EC' || user.profile === 'GVE' || user.profile === 'MA')">
                                <div class="d-flex justify-content-between align-items-center w-100">
                                    <a class="d-flex justify-content-between align-items-center w-100 pb-3"
                                       href="assets/files/Copa_manual_revendas_liquigas.pdf" target="_blank">
                                        <span class="text-white">Manual de Padronização Liquigás</span>
                                        <svg-icon src="assets/svg/downloadmodal.svg"></svg-icon>
                                    </a>
                                </div>
                            </li>
                            <li>
                                <div class="d-flex justify-content-between align-items-center w-100">
                                    <a class="d-flex justify-content-between align-items-center w-100 pb-3"
                                       href="https://juntosnogas.s3.sa-east-1.amazonaws.com/video/Video_Envelopamento_Veiculos_Liquigas.mp4" target="_blank">
                                        <span class="text-white">Envelopamento de Veículos Liquigás</span>
                                        <svg-icon src="assets/svg/downloadmodal.svg"></svg-icon>
                                    </a>
                                </div>
                            </li>
                            <li *ngIf="user.branding !== 'Liquigás'">
                                <div class="d-flex justify-content-between align-items-center w-100">
                                    <a class="d-flex justify-content-between align-items-center w-100 pb-3"
                                       href="assets/files/Copa_manual_revendas_copagaz.pdf" target="_blank">
                                        <span class="text-white">Manual de Padronização Copagaz</span>
                                        <svg-icon src="assets/svg/downloadmodal.svg"></svg-icon>
                                    </a>
                                </div>
                            </li>
                            <li *ngIf="user.profile === 'EC' || user.profile === 'GVE' || user.profile === 'MA'">
                                <div class="d-flex justify-content-between align-items-center w-100">
                                    <a class="d-flex justify-content-between align-items-center w-100 pb-3"
                                       href="assets/files/Regulamento_Equipe_Comercial.pdf" target="_blank">
                                        <span class="text-white">Regulamento Equipe Comercial</span>
                                        <svg-icon src="assets/svg/downloadmodal.svg"></svg-icon>
                                    </a>
                                </div>
                            </li>

                            <li *ngIf="(user.profile === 'EC' || user.profile === 'GVE' || user.profile === 'MA') || user.resaleType === 'Novo Negócio'">
                                <div class="d-flex justify-content-between align-items-center w-100">
                                    <a class="d-flex justify-content-between align-items-center w-100 pb-3"
                                       href="https://juntosnogas.s3.sa-east-1.amazonaws.com/video/Novos_Negocios_Juntos_no_Gas_alt.mp4" target="_blank">
                                        <span class="text-white">Vídeo Lançamento Juntos no Gás</span>
                                        <svg-icon src="assets/svg/downloadmodal.svg"></svg-icon>
                                    </a>
                                </div>
                            </li>

                            <li *ngIf="(user.profile === 'EC' || user.profile === 'GVE' || user.profile === 'MA') || user.resaleType !== 'Novo Negócio'">
                                <div class="d-flex justify-content-between align-items-center w-100">
                                    <a class="d-flex justify-content-between align-items-center w-100 pb-3"
                                       href="https://juntosnogas.s3.sa-east-1.amazonaws.com/video/Debranding_Juntos_no_Gas_alt.mp4" target="_blank">
                                        <span class="text-white">Vídeo Lançamento Juntos no Gás</span>
                                        <svg-icon src="assets/svg/downloadmodal.svg"></svg-icon>
                                    </a>
                                </div>
                            </li>

                            <!--<li>
                                <div class="d-flex justify-content-between align-items-center w-100">
                                    <a class="d-flex justify-content-between align-items-center w-100 pb-3"
                                       href="assets/files/Como_Tirar_Fotos_da_Revenda.pdf" target="_blank">
                                        <span class="text-white">Como Tirar Fotos da Revenda</span>
                                        <svg-icon src="assets/svg/downloadmodal.svg"></svg-icon>
                                    </a>
                                </div>
                            </li>
                            <li>
                                <div class="d-flex justify-content-between align-items-center w-100">
                                    <a class="d-flex justify-content-between align-items-center w-100 pb-3"
                                       href=" https://juntosnogas.s3.sa-east-1.amazonaws.com/video/Campanha_Juntos_no_Gas_Padronizacao.mp4" target="_blank">

                                        <span class="text-white">Video de Apresentação</span>
                                        <svg-icon src="assets/svg/downloadmodal.svg"></svg-icon>
                                    </a>
                                </div>
                            </li>-->
                        </ul>
                        <!--<div class="d-flex mt-2 align-items-center">
                            <svg-icon class="modal_svg mr-2" src="assets/svg/video.svg"></svg-icon>
                            <b class="text-quartenary">Vídeos</b>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="videoModal">
                                    <div class="d-flex flex-column mt-2" *ngFor="let i of [1,2,3,4,5]">
                                        <div class="bg-secondary">
                                        </div>
                                        <p class="text-white">Titulo do Vídeo</p>
                                    </div>
                                </div>
                            </div>
                        </div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
