<div class="modal-custom modal fade" id="modalContact" data-keyboard="false"
     data-backdrop="static" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content modal_comment">
            <div class="row">

                <div class="col-12 col-lg-4">
                    <div class="modal_col-primary">
                        <a data-dismiss="modal" class="modal_close ml-auto d-lg-none" aria-label="Close">
                            <svg-icon [svgStyle]="{'fill':'#006F4D'}"
                                      src="assets/svg/close-circle.svg"></svg-icon>
                        </a>
                        <img src="assets/images/logocopagaz.png" style="width: 279px;" alt="logo">
                        <p class="text-white" style="width: 95%">
                            Entre em contato com o SAC através do e-mail ou whatsapp:
                            <br/><br/>
                            <a class="text-white d-flex align-items-center" target="_blank" href="mailto:sac@juntosnogas.com.br">
                                <!--<svg-icon [svgStyle]="{'fill':'#fff', 'width': '25px'}"
                                          src="assets/svg/globe.svg"></svg-icon>-->
                                <span class="px-2">sac@juntosnogas.com.br</span>
                            </a>
                            <br/>
                            <a class="text-white d-flex align-items-center" target="_blank" href="https://wa.me/5511917824900">
                                <svg-icon [svgStyle]="{'fill':'#fff', 'width': '25px'}"
                                          src="assets/svg/whatsapp.svg"></svg-icon>
                                <span class="px-2">(11) 91782-4900</span>
                            </a>
                        </p>
                        <h2 class="modal_title text-white my-3">Dúvidas de acesso</h2>
                    </div>
                </div>

                <div class="col-12 col-lg-8">
                    <div class="d-flex flex-column justify-content-between h-100 p-4 form-material-dark">
                        <a data-dismiss="modal" class="modal_close d-none d-lg-flex justify-content-end"
                           aria-label="Close">
                            <svg-icon src="assets/svg/close-circle.svg"></svg-icon>
                        </a>
                        <div class="px-lg-5">
                            <div class="input-material light">
                                <input [(ngModel)]="createContactInput.name" id="name" name="name" type="text" required
                                       class="form-control ng-dirty ng-valid ng-touched">
                                <label for="name">Nome Completo</label>
                            </div>
                            <div class="input-material light">
                                <input [(ngModel)]="createContactInput.cnpj" id="cnpj" name="name" type="text" required
                                       [mask]="'00.000.000/0000-00'"
                                       class="form-control ng-dirty ng-valid ng-touched">
                                <label for="cnpj">CNPJ</label>
                            </div>
                            <div class="input-material light">
                                <input [(ngModel)]="createContactInput.email" id="email" name="name" type="text"
                                       required
                                       class="form-control ng-dirty ng-valid ng-touched">
                                <label for="email">E-mail</label>
                            </div>
                            <div class="input-material light">
                                <input [(ngModel)]="createContactInput.phone"
                                       [mask]="'(00) 0000-0000||(00) 00000-0000'"
                                       id="phone"
                                       name="name"
                                       type="text" required
                                       class="form-control ng-dirty ng-valid ng-touched">
                                <label for="phone">Telefone Whatsapp</label>
                            </div>
                            <div class="input-material light">
                                <!--<input [(ngModel)]="createContactInput.subject" id="subject" name="name" type="text"
                                       required
                                       class="form-control ng-dirty ng-valid ng-touched">-->
                                <select name="subject" id="subject" class="form-control" required
                                        [(ngModel)]="createContactInput.subject">
                                    <option value="undefined" [selected]="true">Selecione</option>
                                    <option value="Dúvida">Dúvida</option>
                                    <option value="Elogio">Elogio</option>
                                    <option value="Reclamação">Reclamação</option>
                                    <option value="Solicitação">Solicitação</option>
                                    <option value="Sugestão">Sugestão</option>
                                </select>
                                <label for="subject" class="disabled">Assunto</label>
                            </div>
                            <div class="input-material" style="padding-top: 26px;">
                                <textarea [(ngModel)]="createContactInput.message" id="message" name="name" required
                                          style="resize: none;"></textarea>
                                <label for="message">Mensagem</label>
                            </div>
                        </div>
                        <div class="px-lg-5">
                            <a class="btn btn-block btn-white mb-4" href="javascript:void(0);"
                               (click)="actionContact()">Enviar</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
